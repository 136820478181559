import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'searchForm'
  ]
  static values = {
    route: String,
  }

  searchFormTargetConnected() {
    search.init(this.routeValue)
  }
}
