import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ["recordType", "referentType", "episodeDestroy"]
  static values = {
    catalogBaseObjectId: { type: String, default: null }
  }

  connect() {
    document.querySelector('#catalog_base_objects.edit') && catalog_base_objects.edit()
    catalog_base_objects.init_form($(this.element))
  }

  fetchBaseObjectExtras() {
    if (this.hasCatalogBaseObjectIdValue && this.hasRecordTypeTarget && this.hasReferentTypeTarget) {
      get(`/catalog_base_objects/${this.catalogBaseObjectIdValue}/base_object_extras?record_type_id=${this.recordTypeTarget.value}&referent_type_id=${this.referentTypeTarget.value}`, { responseKind: 'turbo-stream' })
    }
  }
}
