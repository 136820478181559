document.addEventListener("turbo:load", function() {
  
  general.common.time_track_categories = function() {
    if ($("#time_track_categories").length > 0) {
      time_track_categories.init()
    }
  }
  general.common.time_track_categories()
  
})

const time_track_categories = {
  init() {
    if ($("#time_track_category_color").length > 0) {
      $(".ui.dropdown.time-track-category-color-picker").dropdown({
        onChange(value, text, $selectedItem) {
          console.log(value)
          const color = value;
          $("#time_track_category_color").val(`#${color}`);
          $(".ui.dropdown.time-track-category-color-picker").css("background-color", `#${color}`);
        }
      });
    }
  }
}

window.time_track_categories = time_track_categories;
