document.addEventListener("turbo:load", function() {
  document.querySelector('#metadata_brands.index') && metadata_brands.index()
  //document.querySelector('#metadata_brands.edit') && metadata_brands.edit()
  document.querySelector('#metadata_brands.show') && metadata_brands.show()
});

var metadata_brands = {

  index() {
    calendar.datepicker(".modified_date_start", { position: 'top left', lastResort: 'top left' } )
    calendar.datepicker(".modified_date_end", { position: 'top right', lastResort: 'top right' } )

    calendar.datepicker(".creation_date_start", { position: 'top left', lastResort: 'top left' } )
    calendar.datepicker(".creation_date_end", { position: 'top right', lastResort: 'top right' } )
  },

  show() {
    $('#metadata_brands.show .menu .item').tab();
    metadata_brands.fetch_season_cards()
  },

  edit() {
    metadata_brands.init_dropdowns();
    metadata_brands.metadata_brand_cast();
    metadata_brands.search_metadata_brand_cast_image();
    metadata_brands.metadata_brand_comment();

    metadata_brands.export_metadata_modal()

    metadata.approval_steps()

    let sidebar_name = "metadata_brand_casts"
    general.element_sidebar(sidebar_name)
    metadata.update_sidebar(sidebar_name)

    metadata.casts(".element-sidebar-container." + sidebar_name, sidebar_name)

    $("#metadata_brands").off("fields_added.nested_form_fields")
    $("#metadata_brands").on("fields_added.nested_form_fields", function(event, param) {
      switch (param.object_class) {
        case "metadata_brand_season":
          $(event.target).find(".remote.metadata_season.card").data("model-id", param.additional_data.metadata_season.value)
          metadata_brands.fetch_season_cards()
          return
        case "metadata_brand_cast": 
          general.add_sidebar_item($(event.target).find(".nested-fields"), sidebar_name)

          metadata_brands.search_metadata_brand_cast_image();

          metadata_brands.init_dropdowns()
          $("#metadata_seasons #season_cast_crew_form .default-options.dropdown").not('.remote').dropdown({ placeholder: false, fullTextSearch: true });

          metadata.update_sidebar(sidebar_name)

          $(event.target).find(".dropdown.member-type").dropdown("set value", "Cast")
          $(event.target).find(".billing-top").checkbox("set checked")

          metadata.casts($(event.target), sidebar_name)
          return
        case "cast_code":
          metadata_brands.init_dropdowns()
          return
        case "metadata_brand_territory":
          metadata.territory_dropdown()
          return
        case "metadata_brand_language":
          metadata_brands.init_dropdowns()
          $("#metadata_brands #brand_languages_form .default-options.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true})
          return
        case "metadata_brand_comment":
          $("#metadata_brands #brand_comment_form .default-options.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
          metadata_brands.metadata_brand_comment();
          return
      default:
        return console.log(`Fields were successfully added, callback not handled for ${param.object_class}.`)
      }
    })

    $("#metadata_brands").off("fields_removed.nested_form_fields")
    $("#metadata_brands").on("fields_removed.nested_form_fields", function(event, param) {
      switch (param.object_class) {
        case "metadata_brand_cast": 
          general.remove_sidebar_item($(event.target).find(".nested-fields").attr("data-model-id"), sidebar_name)
          return
        case "metadata_image":
        case "metadata_brand_territory_image":
          const image_rows = $(event.target).parent('.image-rows')
          let images = image_rows.find('fieldset:visible')
          if (images.length <= 0) {
            image_rows.parent('.segment').find('.no-image.segment').show()
          }
          return
      default:
        return console.log(`Fields were successfully removed, callback not handled for ${param.object_class}.`)
      }
    })

    metadata_brands.fetch_season_cards()
    metadata_brands.season_autocompletion('#brand_season_form')
  },

  fetch_season_cards() {
    Array.from($('#brand_season_form').find('.remote.metadata_season.card')).map((card) => {
      general.fetch_view(card).then(function(_data) {
        general.quicklook_icon($(card).find(".quicklook"))
        $(card).find(".quicklook").off('click')
        $(card).find(".quicklook").on('click', function() {
          general.quicklook("metadata_seasons", $(this).data('model-id'));
        });
      })
    })
  },

  init_index_body() {
    general.index_quicklook("metadata_brands")
  },

  season_autocompletion(node) {
    let search_field = $(node).find(".search.metadata_brand_seasons")
    search_field.search("destroy")

    const url = '/metadata_brands/seasons?q={/query}';
    return search_field.search({
      cache: false,
      minCharacters: 2,
      apiSettings: {
        url,
        data: {
          c_ID: $('.ui.mb-c-ID.dropdown :selected').val()
        },
      },
      templates: {
        standard: metadata_brands.metadata_brand_season_search
      },
      onResultsAdd(_html) {
        setTimeout(function() {
          Array.from($(search_field).find('.remote.metadata_season.card')).map((card) => general.fetch_view(card))
        }, 100)
        return true
      },
      onSelect(result, _response) {
        search_field.find('.add_nested_fields_link').trigger('click', [{
          metadata_season: result
        }])

        if($(".placeholder.segment.relations").length > 0) {
          $(".placeholder.segment.relations").remove()
        }

        search_field.search("hide results")
        search_field.search("set value", "")
      }
    })
  },

  metadata_brand_season_search(response) {
    let html = '';
    $.each(response.results, function(index, option) {
      html += '<div class="result" style="padding: 0;"><div class="content">';
      html += `<div class="remote metadata_season card" data-model-name="metadata_seasons" data-model-id="${option.value}" data-type="list" data-fields="no-links" style="margin-top: 10px;"><div class="card list item" style="height: 62px; padding: 0.4em 1em;"><div class="ui placeholder"><div class="line"></div><div class="line"></div></div></div></div>`
      html += '</div></div>';
    });
    return html;
  },

  metadata_brand_cast() {
    $.each($('#brand_cast_crew_form .ui.metadata-brand-cast-search.dropdown'), function(_i, item) {
      $(item).dropdown({
        minCharacters: 2,
        apiSettings: {
          cache: false,
          url: '/metadata_brands/casts?q={/query}'
        },
        templates: {
          menu: dropdownTemplates.metadata_brand_cast_search
        },
        onChange(_value, text, choice) {
          $(item).parent().parent().parent().find(".ui.mbc-First-Name").val(choice.data("firstname"));
          $(item).parent().parent().parent().find(".ui.mbc-Middle-Name").val(choice.data("middlename"));
          $(item).parent().parent().parent().find(".ui.mbc-Last-Name").val(text);
        }
      }).dropdown('set selected', $(item).val())
    })
  },

  search_metadata_brand_cast_image() {
    $.each($('#brand_cast_crew_form .ui.metadata-brand-cast-image-search.dropdown'), function(_i, item) {
      $(item).dropdown({
        apiSettings: {
          cache: false,
          url: `/metadata_brands/${$('#metadata_brand_ID').val()}/cast_image`
        },
        templates: {
          menu: dropdownTemplates.search_menu
        },
        onChange(_value, _text, _choice) {}
      }).dropdown('set selected', $(item).val())
    })
  },

  metadata_brand_comment() {
    $.each($('#brand_comment_form .ui.metadata-brand-comment-mail'), function(_i, item) {
      $(item).dropdown({
        apiSettings: {
          cache: false,
          url: '/metadata_brands/comments?q={/query}',
          data: {
            c_ID: $('.ui.mb-c-ID.dropdown :selected').val()
          },
          beforeSend(settings) {
            settings.data.c_ID = $('.ui.mb-c-ID.dropdown :selected').val();
            return settings;
          }
        },
        templates: {
          menu: dropdownTemplates.metadata_brand_comment_search
        }
      }).dropdown('set selected', $(item).val())
    })
  },

  init_dropdowns() {
    $("#metadata_brands .default-options.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
    metadata.contact_dropdown()
    metadata.language_dropdown()
    metadata.territory_dropdown()
    dropdown.init_action_menu()
  },

  export_metadata_modal() {
    if($(".export_metadata").length > 0){
      $(".export_metadata.button").off("click")
      $(".export_metadata.button").on('click', function () {
        $.ajax({
          url: "/metadata_brands/" + $(".metadata_brand_id").val() + "/metadata_export_modal",
        }).done(function(data) {
          $("body").append(data);
          $('.export.metadata.brand.modal').modal({
            autofocus: false,
            onShow() {
              $(".export.metadata.modal").find(".ui.dropdown").dropdown()
              $(".export.metadata.modal").find(".button.export.imagion.template").on('click', function() {
                $('.export.metadata.brand.modal .content .message').remove()
                $.ajax({
                  url: "/metadata_brands/" + $(".metadata_brand_id").val() + "/metadata_export",
                  type: "POST",
                  data: {
                    tc_id: $(".export.metadata.modal").find(".export.territory").dropdown("get value"),
                    lc_id: $(".export.metadata.modal").find(".export.language").dropdown("get value"),
                    images: $(".export.metadata.modal").find(".export.images").prop('checked'),
                    email: $(".export.metadata.modal").find(".export.email").val(),
                  }
                }).done(_data => $('.export.metadata.brand.modal .content').prepend("<div class='ui visible info message'>Metadata export started.</div>")).fail(function(message) {
                  $('.export.metadata.brand.modal .content').prepend("<div class='ui visible error message'>" + message.responseJSON.error +"</div>")
                  console.log("error")
                  console.log(message)
                } );
              })
            }, 
            onHidden() {
              $('.export.metadata.brand.modal').remove();
            }
          }).modal("show");
  
        }).fail(() => console.log("error"));
      })
    }
  },
}

window.metadata_brands = metadata_brands;
