document.addEventListener("turbo:load", function() {
  document.querySelector('#content_catalog') && content_catalogs.edit()
});

var content_catalogs = {
  edit() {
    $(".ui.release.date.calendar").calendar({ 
      type: 'date',
      startMode: 'month',
      formatter: { 
        date(date, _settings) {
          if (!date) return ''
          const day = ('0' + date.getDate()).slice(-2)
          const month = ('0' + (date.getMonth()+1)).slice(-2)
          const year = date.getFullYear()
          return year + '-' + month + '-' + day
        }
      }
    })
    $('#content_catalog .dropdown').dropdown()
    $('#content_catalog .language-search').dropdown({
      apiSettings: {
        cache: false,
        url: '/languagecode?q={/query}'
      },
      templates: {
        menu: dropdownTemplates.language_search_menu
      }
    })
  }
}
