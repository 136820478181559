document.addEventListener("turbo:load", function() {
  if ($("#asset_transfer_accounts").length > 0) {
    asset_transfer_accounts.init_dropdowns()
  }
})

const asset_transfer_accounts = {
  init_dropdowns() {
    $("#asset_transfer_accounts .dropdown").not('.remote').dropdown({ placeholder: false, fullTextSearch: "exact" })
    $('#asset_transfer_account_contact_ids').dropdown({
      apiSettings: {
        url: '/contacts.json?q={/query}&search_input={/query}&client=true&active=true'
      },
      templates: {
        menu: dropdownTemplates.search_menu
      }
    })
  }
}

window.asset_transfer_accounts = asset_transfer_accounts;
