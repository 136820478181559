document.addEventListener("turbo:load", function() {
  if ($("#data_mover_robots").length > 0) {
    data_mover_robots.init();
  }
});

var data_mover_robots = {
  init() {
    if ($("#data_mover_robots").length > 0) {
      $("#data_mover_robots.ui.form .dropdown").dropdown()

      $('.data_mover_robots.overview .not.started.item').on('click', function() {
        $("#search_status").dropdown('set exactly', '1');
        $(".start-search .primary.button").trigger('click')
      });
  
      $('.data_mover_robots.overview .in.progress.item').on('click', function() {
        $("#search_status").dropdown('set exactly', '2');
        $(".start-search .primary.button").trigger('click')
      });

      calendar.datepicker(".search_created_date_start", { position: 'top left', lastResort: 'top left' } )
      calendar.datepicker(".search_created_date_end", { position: 'top right', lastResort: 'top right' } )

      calendar.datepicker(".search_start_date_start", { position: 'top left', lastResort: 'top left' } )
      calendar.datepicker(".search_start_date_end", { position: 'top right', lastResort: 'top right' } )

      calendar.datepicker(".search_finished_date_start", { position: 'top left', lastResort: 'top left' } )
      calendar.datepicker(".search_finished_date_end", { position: 'top right', lastResort: 'top right' } )
    }
  },
};

window.data_mover_robots = data_mover_robots;
