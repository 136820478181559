document.addEventListener("turbo:load", function() {
  if ($("#titles.index").length > 0) {
    titles.index()
  }
})

var titles = {
  index() {
    $('#titles .ui.button.quicklook').on('click', function() {
      return assets.quicklook_asset($(this).data('asset-id'));
    });
    $('#titles .with-tooltip').popup({ inline: true });
  },

  edit() {
    $('#titles .ui.button.quicklook').on('click', function() {
      return assets.quicklook_asset($(this).data('asset-id'));
    });
    $('#titles .with-tooltip').popup({ inline: true });
    $('#titles .dropdown').dropdown({ placeholder: false });
    $('.ui.pointing.secondary.menu .item').tab();

    $('.card .image').dimmer({on: 'hover'});

    $('.not-for-production').hide();

    $('#titles .not-for-production-toggle.checkbox').checkbox({
      onChecked() {
        $("#titles").find('.not-for-production').show();
      },
      onUnchecked() {
        $("#titles").find('.not-for-production').hide();
      }
    });

    $("#titles").off("fields_added.nested_form_fields")
    $("#titles").on("fields_added.nested_form_fields", function(event, param) {
      switch (param.object_class) {
        case "title_contact":
          $(event.target).find('.ui.dropdown').dropdown({ placeholder: false })
          return
        case "title_season":
          return
        default:
          return console.log(`Fields were successfully added, callback not handled for ${param.object_class}.`)
      }
    })

    $("#title_t_IsExplicitContent").change(function() {
      if ($(this).is(":checked")) {
        $('#explicit-content').addClass('has-warning warning-box');
      } else {
        $('#explicit-content').removeClass('has-warning warning-box');
      }
    });
    $("#title_t_IsExplicitContent").change();

    titles.add_shorttitle_dblclick($(".short-title"));
    titles.check_imdb_search();
    titles.check_eidr_search();

    $(".import.eidr.button").on('click', function() {
      $('.import.eidr.modal').modal({
        onApprove() {
          const dimmer = $(this).find('.dimmer')
          dimmer.addClass('active')
          setTimeout(function() {
            $(dimmer).find('.loader').removeClass('slow elastic')
            $(dimmer).find('.text').text('Fetching Data')
          }, 2200)
          setTimeout(function() {
            dimmer.removeClass('active')
          }, 3700)
          return false
        },
        onHide() {
          const dimmer = $(this).find('.dimmer')
          $(dimmer).find('.loader').addClass('slow elastic')
          $(dimmer).find('.text').text('Lookup Registriy')
        }
      }).modal('show')
    })

    $("#title_t_ImdbID").change(function() {
      titles.remove_characters();
      titles.check_existing_imdb();
    });

    $("#title_t_EidrID").change(function() {
      titles.check_eidr_search()
    });

    $("#title_t_OriginalTitle").off("keyup")
    $("#title_t_OriginalTitle").on("keyup", function(event) {
      titles.get_short_title();
    })

    $("#title_t_OriginalTitle").change(function() {
      titles.check_imdb_search();
      titles.check_existing_title("t_OriginalTitle", this);
      titles.get_short_title();
    });

    $("#title_t_EnglishTitle").change(function() {
      titles.check_existing_title("t_EnglishTitle", this);
    });

    $("#title_t_GermanTitle").change(function() {
      titles.check_existing_title("t_GermanTitle", this);
    });

    ["ger", "eng", "org"].map(function(prefix) {
      titles.set_image_upload(prefix)
      titles.set_cloned_image(prefix)
    })
    
    Array.from($('#titles.edit').find('.remote.catalog_base_object.card')).map((card) => general.fetch_view(card))

    titles.init_tabs()
  },

  set_cloned_image(prefix) {
    let cloned = $("#cloned-" + prefix +"-image")
    if (cloned.length > 0) {
      titles.getImgURL(cloned.data("path"), (imgBlob)=>{
        let fileName = 'image.jpg'
        let file = new File([imgBlob], fileName,{type:"image/jpeg", lastModified:new Date().getTime()}, 'utf-8');
        let container = new DataTransfer(); 
        container.items.add(file);
        document.querySelector('#' + prefix + '-file').files = container.files;

        $('#' + prefix + '-file').trigger("change")
      })
    }
  },

  getImgURL(url, callback){
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      callback(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  },

  set_image_upload(prefix) {
    $("."+ prefix + "-img-button").on('click', () => $("#" + prefix +"-file").trigger('click'))

    const org_preview = $("." + prefix + "-img");
    $("#" + prefix + "-file").change(function(event) {
      const input = $(event.currentTarget);
      const file = input[0].files[0];
      const reader = new FileReader();
      reader.onload = function(e) {
        const image_base64 = e.target.result;
        org_preview.attr("src", image_base64);
      };
      reader.readAsDataURL(file);
    });

    $(".remove-" + prefix + "-img").on('click', function() {
      $("#" + prefix + "-remove").trigger('click')
      $(".remove-" + prefix + "-img").hide()
      $("." + prefix + "-img").attr('src', $(".fallback-image-path").data("path"))
    });
  },

  check_imdb_search() {
    if (($("#title_t_OriginalTitle").val() !== "") && ($("#title_t_OriginalTitle").val() !== "tbc") && ($("#title_t_ImdbID").val() === "")) {
      $(".imdb-field").addClass("right action");
      $(".imdb-search").show();
      $(".imdb-search").attr("href", `http://www.imdb.com/find?q=${$("#title_t_OriginalTitle").val()}`).attr('target','_blank');
    }
  },

  check_eidr_search() {
    $('.import.eidr.button').hide();
    if (($("#title_t_OriginalTitle").val() !== "") && ($("#title_t_OriginalTitle").val() !== "tbc")) {
      if ($("#title_t_EidrID").val() === "") {
        $(".eidr-field").addClass("right action");
        $(".eidr-search").show();
        $(".eidr-search").attr("href", `https://ui.eidr.org/search`).attr('target','_blank');
      } else if ($("#title_t_EidrID").val() && $("#title_t_EidrID").val().length === 34) {
        $('.import.eidr.button').show();
      }
    } 
  },

  check_existing_title(title_field_name, title_field) {
    return $.ajax({
      url: "/titles/existing",
      type: "GET",
      dataType: "json",
      data: { // This goes to Controller in params hash, i.e. params[:file_name]
        title: $(title_field).val(),
        title_field_name
      },
      success(data, _textStatus, _xhr) {
        if (data !== null) {
          return $(title_field).parent().append(`<span class='ui basic tiny orange label title-warning'><a href='/titles/${data.t_ID}/edit' target='_blank'>${data.t_ID}</a></span>`);
        } else {
          return $(".title-warning").remove();
        }
      },
      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown);
      }
    });
  },

  remove_characters() {
    if ($("#title_t_ImdbID").val() !== "") {
      return $("#title_t_ImdbID").val($("#title_t_ImdbID").val().replace(/[a-zA-Z]+/g, ""));
    }
  },

  check_existing_imdb() {
    titles.check_imdb_search();
    if ($("#title_t_ImdbID").val() === "") {
      $(".imdb-warning").remove();
      return;
    }

    return $.ajax({
      url: "/titles/imdb",
      type: "GET",
      dataType: "json",
      data: { // This goes to Controller in params hash, i.e. params[:file_name]
        imdb_id: $("#title_t_ImdbID").val()
      },
      success(data, _textStatus, _xhr) {
        if (data !== null) {
          if ((`${data.t_ID}`) !== $("#title_id").val()) {
            return $("#title_t_ImdbID").parent().append(`<span class='ui basic tiny orange label imdb-warning'><a href='/titles/${data.t_ID}/edit' target='_blank'>${data.t_ID}</a></span>`);
          }
        } else {
          return $(".imdb-warning").remove();
        }
      },
      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown);
      }
    });
  },

  add_shorttitle_dblclick(item) {
    const divdbl = $(item);
    return divdbl.dblclick(function() {
      //console.log("click");
      titles.get_short_title();
    });
  },

  get_short_title() {
    if ($("#title_t_OriginalTitle").val() !== "") {

      return $.ajax({
        url: "/titles/gen_short_title",
        type: "GET",
        dataType: "json",
        data: { // This goes to Controller in params hash, i.e. params[:file_name]
          original_title: $("#title_t_OriginalTitle").val()
        },
        success(data, _textStatus, _xhr) {
          //console.log(data);
          return $("#title_t_OriginalShortTitle").val(data.t_OriginalShortTitle);
        },
        error(_jqXHR, _textStatus, errorThrown) {
          return console.log(errorThrown);
        }
      });
    }
  },

  init_tabs() {
    document.querySelectorAll('.remote.card').forEach((card) => general.fetch_view(card))
  }
};

window.titles = titles;
