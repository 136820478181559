import { Controller } from "@hotwired/stimulus"
import { get, post } from '@rails/request.js'

export default class extends Controller {

  connect() {
  }

  async copyAssetIds(e) {
    e.preventDefault()
    const wj_ids = index_select.selected_ids()

    let asset_ids = null
    try {
      navigator.clipboard.write([
        new ClipboardItem({
          'text/plain': new Promise(async (resolve) => { 
            const response = await post("/workflow_jobs/asset_ids.json", { body: JSON.stringify({ wj_ids }) })
            if (response.ok) {        
              const data = await response.json
              asset_ids = data.asset_ids.join(",")
              resolve(new Blob([asset_ids], { type: 'text/plain' }))
            } else {
              reject()
            }
          })
        })
      ])
    } catch (error) {
      console.error("Error asset fetching IDs:", error)
    }
  }
}
