document.addEventListener("turbo:load", function() {
  if ($("#magenta_tv_qcs").length > 0) {
    $('#magenta_tv_qcs .dropdown').dropdown({ placeholder: false, fullTextSearch: true });
  }

  if ($(".magentatv.click").length > 0) {
    $(".magentatv.click").on('click', function () {
      $(".magentatv.click").css("pointer-events", "none")
      $.ajax({
        url: `/magenta_tv_qcs/${$("#asset_a_ID").val()}/magenta_tv_qc_modal`,
        success(data, _textStatus, _xhr) {
          $("body").append(data);
          $('.ui.maganta_tv_qc.modal').modal({
            onShow() {
              $(".maganta_tv_qc.modal .primary.approve.button").on('click', function() {
                $("#assets .menu .item[data-tab=events]").trigger('click')
                $("#assets #events .menu .item[data-tab=content-marker]").trigger('click')
              })
              $(".magentatv.app.button").on('click', function () {
                console.log("Function removed.")
                return;
              }); 
            },
            onHidden() {
              $(".magentatv.click").css("pointer-events", "auto")
              $('.ui.maganta_tv_qc.modal').remove();
            }
          }).modal('show');
        },
        error(_jqXHR, _textStatus, errorThrown) {
          return console.log(errorThrown);
        }
      });
    });
  }
  
  $(".magentatv.list.item").on('click', function() {
    console.log("click")
  })
});
