import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    "startTc",
    "endTc",
    "destinationTc",
    "differenceLabel",
    "calculationBaseDropdown",
  ]

  static values = {
    paramsId: String,
    frameRate: String,
    timecodeFormat: String,
    durationTc: { type: String, default: null },
    activeContentDurationTc: { type: String, default: null },
    calculationBasis: { type: String, default: "file-duration"}
  }

  connect() {
    const c = this
    let target = $(c.element)
 
    c.init_asset_spec_dropdown()
    awaited_asset.init(target, c.paramsIdValue, { asset_type: 11 })
    $('.workflow_50008 .create.asset.checkbox').change(function() {
      if ($(this).checkbox("is checked")) {
        target.find('.awaited.asset .checkbox').checkbox('uncheck')
      }
    })

    c.check_framerate()
    c.recalculateDifference()
  }

  recalculateEndTc() {
    let start_tc = this.startTcTarget.value
    let end_tc = "23:59:59:00"

    switch(this.calculationBasisValue) {
      case "file-duration":
        if (this.hasDurationTcValue && this.durationTcValue != "") {
          end_tc = Timecode.add_timecodes(this.durationTcValue, start_tc, this.frameRateValue, this.timecodeFormatValue)
        }
        break
      case "active-content":
        if (this.hasActiveContentDurationTcValue && this.activeContentDurationTcValue != "") {
          end_tc = Timecode.add_timecodes(this.activeContentDurationTcValue, start_tc, this.frameRateValue, this.timecodeFormatValue)
        }
        break
      case "custom":
        return
        break
    }

    if (this.hasEndTcTarget) {
      this.endTcTarget.value = end_tc
    }
    this.recalculateDifference()
  }

  recalculateDifference() {
    if (this.hasStartTcTarget && this.hasDestinationTcTarget && this.hasDifferenceLabelTarget) {
      let difference_tc = Timecode.subtract_timecodes(this.destinationTcTarget.value, this.startTcTarget.value, this.frameRateValue, this.timecodeFormatValue)
      this.differenceLabelTarget.textContent = difference_tc
    }
  }

  check_framerate() {
    const target = $(this.element)
    var framerate = target.find('.framerate.config.selection').dropdown('get value')
    if (framerate == 9) {
      target.find('.tc.fields').removeClass("two").addClass("three")
      target.find('.timecode_format.field').show()
    } else {
      target.find('.tc.fields').addClass("two").removeClass("three")
      target.find('.timecode_format.field').hide()
    }
  }

  setOnChange() {
    const c = this
    const target = $(c.element)

    var itt_message = $(`
    <div class="ui timecode visible warning message">
      <i class="close icon"></i>
      <div class="header">
        Timecode deviation detected!
      </div>
      <p>The Timecode offset differs from the sync slave start timecode.<br>Please check whether this is intened for the selected specs with an <b>ITT</b> output format.</p>
    </div>
    `).hide()

    var specs = target.find('.asset-spec-type.remote.search.dropdown').dropdown('get value')

    $.ajax({
      url: '/asset_spec_types?q={/query}&search_input={/query}',
      contentType: "application/json",
      data: { could_be_itt: specs },
      dataType: 'json',
      success: function(response){
          if (response.result) {
            const sync_tc = target.find('.sync.group.tc').val()
            var current_tc = target.find('.params-input.tc.config').val()
            var plusminus = target.find('.params-input.tc.config').parent().find('.dropdown.button .params-input').val()
            
            $(target).find('.params-input.tc.config').closest('.nested-fields').find('.timecode.warning.message').hide('normal', function(){ $(this).remove() })

            if (sync_tc != current_tc || (sync_tc == current_tc && plusminus > 0)) {
              target.find('.params-input.tc.config').closest('.field').addClass('warning')
              target.find('.params-input.tc.config').closest('.nested-fields').prepend(itt_message)
              $('.timecode.warning.message .close').on('click', function() { $(this).closest('.message').transition('fade') })
              itt_message.show()
            } else {
              target.find('.params-input.tc.config').closest('.field').removeClass('warning')
              target.find('.params-input.tc.config').closest('.nested-fields').find('.timecode.warning.message').hide('normal', function(){ $(this).remove() })
            }
          } else {
            target.find('.params-input.tc.config').closest('.field').removeClass('warning')
            target.find('.params-input.tc.config').closest('.nested-fields').find('.timecode.warning.message').hide('normal', function(){ $(this).remove() })
          }
      }
    })

    c.check_framerate()

    workflow.update_whparams(target)
    awaited_asset.validate(target, c.paramsIdValue)
  }

  init_asset_spec_dropdown() {
    const c = this
    const target = $(c.element)
    const asset_spec_type_dropdown = target.find('.asset-spec-type.remote.search.dropdown')
    asset_spec_type_dropdown.dropdown({
      apiSettings: {
        cache: false,
        url: '/asset_spec_types?q={/query}&search_input={/query}',
        data: {
          search_distribution_channel_types: $(`#distribution_channel_types_${c.paramsIdValue}`).parent().dropdown('get value'),
          search_asset_types: 11,
          search_subtitle_config: true
        },
        beforeSend(settings) {
          settings.data.search_distribution_channel_types = $(`#distribution_channel_types_${c.paramsIdValue}`).parent().dropdown('get value')
          return settings
        }
      },
      onChange() {
        c.setOnChange()
      }
    })
  }

  changeEndTcCalculationBasis({ detail: { value } }) {
    this.calculationBasisValue = value
    this.recalculateEndTc()
  }

  setCustomCalculationBase() {
    this.calculationBasisValue = "custom"
    if (this.hasCalculationBaseDropdownTarget) {
      $(this.calculationBaseDropdownTarget).dropdown("set selected", "custom", true)
    }
  }
}