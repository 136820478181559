document.addEventListener("turbo:load", function() {
  if ($("#qc_requests.index").length > 0) {   
    qc_requests.init_index_body()
  }
})
    
var qc_requests = {

  init_index_body() {
    $('#qc_requests .quicklook').on("click", function() {
      assets.quicklook_asset($(this).data('asset-id'))
    })
  },

  set_status_modal(qc_request_ids) {
    $.ajax({
      url: `/qc_requests/set_status_modal`,
      data: {
        qc_request_ids: qc_request_ids
      },
    }).done(function(data) {
      $(data).appendTo('body')
      let set_status_modal = $('#qc_request_set_status_modal')
      set_status_modal.modal({
        observeChanges: true,
        autofocus: false,
        closable: false,
        onShow() {
          qc_requests.init_set_status_modal(set_status_modal)
        },
        onHidden() {
          set_status_modal.remove()
        }
      })
      set_status_modal.modal('show')
    }).fail(function(_xhr, _textStatus, errorThrown) {
      if (errorThrown != 'abort') { console.log(errorThrown) }
    })
  },

  init_set_status_modal(set_status_modal) {
    let change_status_dropdown = set_status_modal.find('.ui.change_status.dropdown').not('.remote').dropdown()
    let assign_to_dropdown = set_status_modal.find('.ui.assign_to.dropdown').dropdown({
      minCharacters: 1,
      delay: {search: 300},
      apiSettings: {
        cache: false,
        url: '/user_profile_webs.json?q={/query}&search_input={/query}'
      },
      templates: {
        menu: dropdownTemplates.search_menu
      }
    })
    calendar.datepicker(".due_date.field", { position: 'top left', lastResort: 'top left' } )

    let apply_button = set_status_modal.find('.apply.button')
    apply_button.on('click', function() {

      var data = {}
      let qc_request_ids = set_status_modal.find('.ui.form').data('qcRequestIds')
      let state = change_status_dropdown.dropdown('get value')
      let assign_to = assign_to_dropdown.dropdown('get value')
      let due_date = set_status_modal.find('#due_date').val()
  
      if (qc_request_ids?.length > 0) { data.qc_request_ids = qc_request_ids }
      if (state !== '') { data.status = state }
      if (assign_to !== '') { data.assign_to = assign_to }
      if (due_date !== '') { data.due_date = due_date }
      $.ajax({
        url: `/qc_requests/change_status`,
        type: "POST",
        dataType: "json",
        data: data,
        beforeSend() {
          apply_button.addClass('loading')
        }
      }).done(function(_data, _textStatus, _xhr) {
        set_status_modal.modal('hide')
        $('body').toast({
          title: "Status Change Success!",
          class: 'success',
          displayTime: 3000,
          message: "QC Requests / QC Elements successfuly updated!"
        })
        $(".start-search .primary.button").trigger('click')

      }).fail(function(_jqXHR, _textStatus, errorThrown) {
        let status_modal_error_message = set_status_modal.find('#status_modal_error_message')
        status_modal_error_message.append(`<p>An unknown error occured!</p>`)
        status_modal_error_message.append(`<p>Error: ${errorThrown}</p>`)
        status_modal_error_message.removeClass('hidden')
        status_modal_error_message.addClass('visible')
      }).always(() => apply_button.removeClass('loading'))
    })
  },

  destroy_selected(qc_request_ids) {
    if (confirm("Do you really want to destroy the selected qc requests?")) {
      $.ajax({
        url: `/qc_requests/destroy_selected`,
        type: "POST",
        dataType: "json",
        data: {
          qc_request_ids: qc_request_ids
        },
      }).done(function() {
        $('body').toast({
          title: "Success!",
          class: 'success',
          displayTime: 3000,
          message: "QC Requests successfuly destroyed!"
        })
        $(".start-search .primary.button").trigger('click')
      }).fail(function() {
        $('body').toast({
          title: "Could not destroy selected qc requests!",
          class: 'error',
          displayTime: 0,
          message: "Unassign of qc requests failed"
        })
      })
    }
  },

  unassign_qc_elements(qc_request_ids) {
    if (confirm("Do you really want to completely unassign the qc elements of these qc requests? All entered data and issues will be deleted.")) {
      $.ajax({
        url: `/qc_requests/unassign_qc_elements`,
        type: "POST",
        dataType: "json",
        data: {
          qc_request_ids: qc_request_ids
        },
      }).done(function() {
        $('body').toast({
          title: "QC Elements unassigned!",
          class: 'success',
          displayTime: 3000,
          message: "Unassign of qc elements succeeded"
        })
      }).fail(function() {
        $('body').toast({
          title: "Could not unassign!",
          class: 'error',
          displayTime: 3000,
          message: "Unassign of qc elements failed"
        })
      })
    }
  },

  context_menu() {
    $('.edit.qc_element.item').on('click',  function(_eventData) {
      var qce_id = $(this).data('qc-element-id')
      qc_requests.edit_qc_element(qce_id)
    }),

    $('.clone.qc_element.item').click ( function(_eventData) {
      var qce_id = $(this).data('qc-element-id')
      qc_requests.clone_qc_element(qce_id)
    }),

    $(".delete.qc_element.item").on('click', function() {
      var qce_id = $(this).data('qc-element-id')
      $.ajax({
        url: `/qc_elements/${qce_id}`,
        type: 'post',
        data: {_method: 'delete'},
      })
      .done(function (_data) { 
        console.log('deleted qc element')
        // $(`fieldset:has(div.qc-element.card[data-qc-element-id=${qce_id}]) .remove_nested_fields_link`).trigger('click')
        $(`fieldset:has(div.qc-element.card[data-qc-element-id=${qce_id}])`).remove()
      })
      .fail(function (_jqXHR, textStatus, errorThrown) { 
        console.log('error')
        console.log(errorThrown)
        console.log(textStatus)
      });
    })
  }

}

window.qc_requests = qc_requests
