import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static outlets = [
    'qc-request'
  ]

  static targets = [
    'modal',
    'submitButton',
  ]

  static values = {
  }

  connect() {
  }

  disableSubmitButton() {
    this.submitButtonTarget.classList.add('disabled')
  }

  enableSubmitButton() {
    this.submitButtonTarget.classList.remove('disabled')
  }

  async submit(event) {
    const c = this
    c.disableSubmitButton()
    const form = this.modalTarget
    const formData = new FormData(form)

    try {
      const response = await post(form.action, {
        body: formData,
        responseKind: "json"
      })

      if (response.ok) {
        const qc_element = await response.json
        if (c.hasQcRequestOutlet) { 
          c.qcRequestOutlet.addQcElement({ detail: { result: qc_element }})
        }
        c.close()
      } else {
        console.error("Request failed", response)
      } 
    } catch(error) {
      console.error(error)
    } finally {
      c.enableSubmitButton()
      c.dispatch("submit-end", { target: event.target })
    }
  }

  submitEnd(event) {
  }

  close() {
    $(this.element).modal('hide')
  }
}
