import Timecode from './timecode.js'
import '../video/play'

document.addEventListener("turbo:load", function() {

  general.common.qc_elements = function() {
    qc_elements.index_menu()

    if ($("#qc_overview").length > 0) {
      qc_elements.fill_qc_chart()
      qc_elements.fill_qc_elements()
    }

    if ($("#qc_elements.index").length > 0) {
      qc_elements.index()
    }
  }
  general.common.qc_elements()
})

var qc_elements = {

  index() {
    let search_promises = []
    if ($('#qc_elements.binge_marker_overview').length > 0) {
      search_promises.push(search.init("/binge_marker_overview"))

      $('.binge.marker.import.button').on('click', function() {
        qc_elements.load_binge_marker_import_modal()
      })
    } else {
      search_promises.push(search.init("/qc_elements"))
    }
    Promise.allSettled(search_promises).then(() => {
    })
  },

  dmm_export(data, toast, toast_message, progress_bar, redirect = true) {
    return new Promise(function (resolve, reject) {
      let toast_header = toast.find('.content .header')
      toast_message.html('Do not close this window.<br>Publishing in progress')
      assets.export_to_dmm_ajax(2, data.asset_id, true).done(function() {
        progress_bar.removeClass('sliding blue pink').addClass('pink')
        toast.removeClass('neutral').addClass('pink')
        toast_header.html('Successfully Published')
        toast_message.remove()
        toast.toast('show')
        setTimeout(() => {
          toast.toast('close')
          if (redirect) window.location.replace(data.redirection_url)
        }, 1500)
        resolve(data)
      }).fail(function(_jqXHR, _textStatus, errorThrown) {
        console.log(errorThrown)
        toast_message.html('Unable to publish.')
        toast.removeClass('neutral').addClass('red')
        progress_bar.removeClass('sliding blue').addClass('red')
        setTimeout(() => {
          toast.toast('close')
          if (redirect) window.location.replace(data.redirection_url)
        }, 5000)
        reject(errorThrown)
      })
    })
  },

  nested_fields() {
    $("#qc_elements").off("fields_added.nested_form_fields.nested_qc_element_qc_events")
    $("#qc_elements").on("fields_added.nested_form_fields.nested_qc_element_qc_events", function(event, param) {
      return qc_elements.set_qc_event_data(event.target, param.additional_data);
    });
  },

  load_binge_marker_import_modal(asset_ids = []) {
    $.ajax({
      url: `/qc_elements/binge_marker_import_modal`,
    }).done(function(data, _textStatus, _xhr) {
      const import_modal = $(data).appendTo('body')
      import_modal.modal({
        autofocus: false,
        duration: 200,
        onShow() {
          qc_elements.init_import_binge_marker_modal(import_modal).then(function(_t) {
            $.each(asset_ids, function( _index, value ) {
              import_modal.find('.binge-assets .add_nested_fields_link').trigger('click', [{ asset: { a_ID: value } }])
            })
          })
        },
        onHidden() {
          import_modal.remove()
        }
      }).modal('show')
    }).fail(function(_jqXHR, _textStatus, errorThrown) {
      return console.log(errorThrown)
    })
  },

  init_import_binge_marker_modal(import_modal) {
    return new Promise(function (resolve, _reject) {
      import_modal.find('.ui.dropdown').dropdown()
      import_modal.off("fields_added.nested_form_fields.nested_import_binge_assets")
      import_modal.on("fields_added.nested_form_fields.nested_import_binge_assets", function(event, param) {
        switch (param.object_class) {
          case "binge_asset":
            assets.set_asset_data(event.target, param.additional_data)
            break
          default:
            console.log("Fields were successfully added, callback not handled.")
        }
      })
      search.asset_autocompletion(".ui.search.binge-assets")
      
      import_modal.find('.confirm.import.button').on('click', function() {
        import_modal.find('#binge_marker_import_progress_indicator').addClass('active')
        var form_data = new FormData()
        $.each(import_modal.find("input[type='file']")[0].files, function(_i, value) {
          form_data.append(`files[]`, value)
        })

        $.each(import_modal.find("#binge-asset-list fieldset[style!='display: none;'] .binge_asset.segment").not('.disabled').find('.asset.list.item').toArray().map(x => $(x).data('asset-id')), function(_i, value) {
          form_data.append(`asset_ids[]`, value)
        })

        let data = {
          identifier_list: import_modal.find('#identifier_list').val(),
          due_date: import_modal.find('#due_date').val(),
          identifier_type: import_modal.find('#identifier_type').dropdown('get value'),
          // asset_ids: 
        }
        for ( var key in data ) {
          form_data.append(key, data[key]);
        }

        let progress_message = import_modal.find('.import_progress.message')

        progress_message.find('.close.icon').on('click', function() {
          import_modal.modal('hide')
        })

        $.post({
          url: '/qc_requests/ingest_binge_marker_assets',
          data: form_data,
          processData: false,
          contentType: false,
        }).done(function(data, _textStatus, xhr) {
          console.log(`successfull import ${data}`)
          console.log(xhr.status)    
          progress_message.find('.import_progress.icon').removeClass('notched circle loading')
          if (data.failed?.length == 0) {
            progress_message.addClass("positive")
            progress_message.find('.import_progress.icon').addClass('green check circle outline')
            progress_message.find('.header').text('Success!')
            progress_message.find('.message_text').text(`Created ${data.succeeded?.length} binge marker elements`)
            setTimeout(function(){ 
              import_modal.modal('hide') 
            },1000)
          } else {
            progress_message.addClass("warning")
            progress_message.find('.import_progress.icon').addClass('orange exclamation triangle')
            progress_message.find('.header').text('Warning!')
            progress_message.find('.message_text').text(`Created ${data.succeeded?.length} binge marker elements, but ${data.failed?.length} elements already existed or could not be imported.`)
            progress_message.find('#copy_to_clipboard').show().on('click', function() {
              let tmp_textarea = $("<textarea/>").appendTo("body")
              tmp_textarea.val(JSON.stringify(data, null, 2)).trigger('select').each(function () {
                document.execCommand('copy')
              }).remove()
              $('body').toast({ message: `Copied Text to Clipboard!` })
            })
          }

        }).fail(function(xhr, _textStatus, _errorThrown) {
          progress_message.addClass("negative")
          progress_message.find('.import_progress.icon').removeClass('notched circle loading')
          progress_message.find('.import_progress.icon').addClass('red exclamation circle')
          progress_message.find('.header').text('An Error Occurred!')

          console.log(xhr.status)
          if (xhr.status == 400) {
            progress_message.find('.message_text').text(`${xhr.responseJSON?.error}`)  
          } else if (xhr.status == 422) {
            progress_message.find('#copy_to_clipboard').show().on('click', function() {
              let tmp_textarea = $("<textarea/>").appendTo("body")
              tmp_textarea.val(JSON.stringify(xhr.responseJSON, null, 2)).trigger('select').each(function () {
                document.execCommand('copy')
              }).remove()
              $('body').toast({ message: `Copied Text to Clipboard!` })
            })
            progress_message.find('.message_text').text(`Unable to import ${xhr.responseJSON?.failed?.length} binge marker elements because they already exist or an unknown error occured.`)  
          } else {
            progress_message.find('.message_text').text(`Unknown Error.`)  
          }
        })

      })
      resolve(import_modal)
    }).catch(() => {})
  },

  init_report_a_problem_modal() {
    $('.qc_element.report_a_problem').on('click', function() {
      const qce_id = $(".qc_element_ID").val();
      $.ajax({
        url: "/qc_elements/" + qce_id + "/report_a_problem_modal",
        type: "GET",
      }).done(function(data) {
        console.log("reporting a problem");
        $("body").append(data);
        $('.qc_element.report.problem.modal').modal({
          observeChanges: true,
          autofocus: false,
          onShow() {
            return $(this).find('.dropdown').dropdown();
          }, 
          onDeny    : function(){
          },
          onApprove(_$element) {

          },
          onHidden() {
            $('.qc_element.report.problem.modal').remove();
          }
        }).modal("show");

      }).fail(() => console.log("report_a_problem_error"));
    });
  },

  init_index_body() {
    $('#qc_elements .addition-description.quicklook').on('click', function() {
      assets.quicklook_asset($(this).data('asset-id'))
    })
    $('#qc_elements .with-tooltip').popup({
      inline: true,
      exclusive: true,
      delay: {
        show: 500,
        hide: 0
      }
    })
  },

  overwrite_index_body() {
    if($("#qc_elements.binge_marker_overview").length > 0) {
      $(".button.start-select").data("path", "/binge_marker_overview")
    }
  },
  
  init_ocr_timeline() {
    const ocr_area = $('.qc_element.ocr_timeline')

    $.ajax({
      url: `/qc_elements/${$('#qc_element_ID').val()}/ocr_timeline`
    }).done(function(data) {
      ocr_area.html(data)
    }).fail(function(_data) {
      ocr_area.html('failed to retrieve ocr data')
    })

  },

  init_popups() {
    $('.step.with-tooltip').popup({
      delay: {
        show: 800,
        hide: 300
      }
    });
  },

  init_modal_dropdowns() {
    const current_result = $('.qc-result-verifier.dropdown').val()
    if (current_result == "modified") {
      $('.qc-result-verifier.dropdown').dropdown({
        placeholder: false,
        values:[
          {
            "name": "Modified",
            "value": "modified",
            "selected": true,
            "icon": "orange user edit"
          }
        ]
      })
    } else {
      $('.qc-result-verifier.dropdown').dropdown({
        placeholder: false,
        values:[
          {
            "name": "Correct",
            "value": "correct",
            "selected": current_result == "correct",
            "icon": "green user check"
          },
          {
            "name": "Incorrect",
            "value": "incorrect",
            "selected": current_result == "incorrect",
            "icon": "red user times"
          },
          {
            "name": "Modified",
            "value": "modified",
            "selected": current_result == "modified",
            "icon": "orange user edit"
          }
        ],
      })
    }
  },
  

  init_buttons() {
    $('.load.from.asset.button').on('click', function () {
      const qce_id = $(".qc_element_ID").val();
      $.ajax({
        url: "/qc_elements/" + qce_id + "/asset_details_from_asset",
        type: "GET",
        dataType: "json",
        error:  function (xhr, status, error) {
          console.log(xhr, status, error);
        },
        success: function (result, _status, _xhr) {
          $('.asset.title.insert.text').val(result.a_TitleInsertText);
          $('.asset.title.insert.language.dropdown').dropdown('set selected', result.a_lc_TitleInsertLanguageID);
          $('.asset.start.credits.language.dropdown').dropdown('set selected', result.a_lc_StartCreditsLanguageID);
          $('.asset.end.credits.language.dropdown').dropdown('set selected', result.a_lc_EndCreditsLanguageID);
          $('.asset.subtitle.language.dropdown').dropdown('set selected', result.a_lc_SubtitleLanguageID);
          $('.asset.forced.subtitle.language.dropdown').dropdown('set selected', result.a_lc_PartialForcedSubtitleLanguageID);
          $('.asset.text.insert.language.dropdown').dropdown('set selected', result.a_lc_TextInLiveActionLanguageID);
          $('.asset.text.live.action.language.dropdown').dropdown('set selected', result.a_lc_TextInsertLanguageID);
        }
      })
    })
  },

  fill_qc_chart() {
    $.get("/qc_elements/operator_ranking").done(function(data) {
      Highcharts.chart('qc-operator-ranking-chart', { 
        "title": {
          "text": ""
        },
        "subtitle": {
          "text": ""
        },
        "chart": {
          "type": "column",
          "inverted": false,
        },
        "plotOptions": {
          "series": {
            "stacking": "normal",
            "dataLabels": {
              "enabled": false
            }
          }
        },
        "series": [{
          name: 'Score',
          colorByPoint: true,
          data: data,
        }],
        "yAxis": {
          "title": {
            "text": "Score"
          },
          "labels": {},
          "reversed": false
        },
        "xAxis": {
          "title": {},
          "labels": {},
          "opposite": false,
          "reversed": false,
          "categories": data.map(x => x["name"])
        }, 
        "legend": {
          "enabled":false
        }
      })
    })
  },

  qc_elements_search(params, element) {
    $.ajax({
      url: `/qc_elements?${params}`,
      dataType: "script",
      data: {
        type: "tiny",
        element: element
      }
    }).done(function(_data) {
      $('#qc_overview .addition-description.quicklook').on('click', function() {
        assets.quicklook_asset($(this).data('asset-id'));
      });
    });
  },

  fill_qc_elements() {
    const qc_elements_view = [
      [`search_assigned[]=${$("#upw_ID").val()}&search_verification[]=incorrect&search_status[]=verified&type=compact`, "#qc_overview .qc-elements-incorrect"],
      [`search_assigned[]=${$("#upw_ID").val()}&search_status[]=not%20started&type=compact&items_per_page=10`, "#qc_overview .qc-elements-todo"],
      [`search_assigned[]=${$("#upw_ID").val()}&search_status[]=finished&search_status[]=verified&search_status[]=verifying&type=compact`, "#qc_overview .qc-elements-all-finished"],
      [`search_default_assigned[]=${$("#upw_ID").val()}&search_status[]=in%20progress&search_status[]=not%20started&search_status[]=on%20hold&type=compact`, "#qc_overview .qc-elements-pool-exclusive"],
      [`items_per_page=10&type=compact`, "#qc_overview .qc-elements-pool"]
    ]
    
    $.each(qc_elements_view, function( _index, value ) {
      qc_elements.qc_elements_search(value[0], value[1])
    })
  },

  unassign_selected(qc_element_ids) {
    if (confirm("Do you really want to completely unassign these qc elements? All entered data and issues will be deleted.")) {
      $.ajax({
        url: `/qc_elements/unassign_selected`,
        type: "POST",
        dataType: "json",
        data: {
          qc_element_ids: qc_element_ids
        },
      }).done(function() {
        $('body').toast({
          title: "Unassign Success!",
          class: 'success',
          displayTime: 3000,
          message: "QC Elements successfuly unassigned!"
        })
        $(".start-search .primary.button").trigger('click')
      }).fail(function() {
        $('body').toast({
          title: "Could not unassign!",
          class: 'error',
          displayTime: 0,
          message: "Unassign of qc elements failed"
        })
      })
    }
  },

  finish_all_modal(qc_element_ids) {
    $.ajax({
      url: `/qc_elements/finish_all_modal`,
      data: {
        qc_element_ids: qc_element_ids
      },
    }).done(function(data) {
      $(data).appendTo('body')
      let modal = $('#qc_element_finish_all_modal')
      modal.modal({
        observeChanges: true,
        autofocus: false,
        closable: false,
        onShow() {
          const finish = $(modal).find(".finish.button")
          const ids = $(modal).find(".ui.form").data("qc-element-ids")

          finish.on("click", async function() {
            finish.addClass("loading")

            let toast = $('body').toast({
              displayTime: 0,
              title: 'Binge Marker Publisher',
              message: `Initializing...`,
              showImage: `${$('.magenta-image[src^="/rails/assets/magentatv"]').attr('src')}`,
              showProgress: 'bottom',
              pauseOnHover: false,
              class: "initalizer",
            })
            
            let toast_message = toast.find('.content .message')
            let progress_bar = $('<div class="ui attached active progress pink bottom compact sliding indeterminate" data-percent="99"><div class="bar up progressing " style="animation-duration: 0.5s; transition-duration: 300ms; display: block; width: 99%;"></div></div>').appendTo(toast.parent('.toast-box'))

            try {
              const csrfToken = document.querySelector("[name='csrf-token']").content
              const results = await Promise.all(ids.map(async (id, _1) => {
                let response = await fetch(`/qc_elements/${id}/finish?publish=true`, {
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "X-CSRF-Token": csrfToken,
                  },
                  method: "PATCH",
                  dataType: 'json',
                  body: JSON.stringify({
                    qc_element: { qce_Status: "finished" }
                  })
                })
                if (!response.ok) throw new Error(response)
                let data = await response.json()
                await qc_elements.dmm_export(data, toast, toast_message, progress_bar, false)
              }))
              finish.removeClass("loading")
              modal.modal("hide")
              window.location.reload()
            } catch(error) {
              console.log(error)
              toast.removeClass('neutral').addClass('red')
              progress_bar.removeClass('sliding blue').addClass('red')
              setTimeout(() => toast.toast('close'), 5000)
              finish.removeClass('green loading').addClass('red').text("Publish failed!")
            }
          })
        },
        onHidden() {
          modal.remove()
        }
      })
      modal.modal('show')
    }).fail(function(_xhr, _textStatus, errorThrown) {
      if (errorThrown != 'abort') { console.log(errorThrown) }
    })
  },

  set_status_modal(qc_element_ids) {
    $.ajax({
      url: `/qc_elements/set_status_modal`,
      data: {
        qc_element_ids: qc_element_ids
      },
    }).done(function(data) {
      $(data).appendTo('body')
      let set_status_modal = $('#qc_element_set_status_modal')
      set_status_modal.modal({
        observeChanges: true,
        autofocus: false,
        closable: false,
        onShow() {
          qc_elements.init_set_status_modal(set_status_modal)
        },
        onHidden() {
          set_status_modal.remove()
        }
      })
      set_status_modal.modal('show')
    }).fail(function(_xhr, _textStatus, errorThrown) {
      if (errorThrown != 'abort') { console.log(errorThrown) }
    })
  },

  init_set_status_modal(set_status_modal) {
    let change_status_dropdown = set_status_modal.find('.ui.change_status.dropdown').not('.remote').dropdown()
    let assign_to_dropdown = set_status_modal.find('.ui.assign_to.dropdown').dropdown({
      minCharacters: 1,
      delay: {search: 300},
      apiSettings: {
        cache: false,
        url: '/user_profile_webs.json?q={/query}&search_input={/query}'
      },
      templates: {
        menu: dropdownTemplates.search_menu
      }
    })
    calendar.datepicker(".due_date.field", { position: 'top left', lastResort: 'top left' } )

    let apply_button = set_status_modal.find('.apply.button')
    apply_button.on('click', function(_e) {

      var data = {}
      let qc_element_ids = set_status_modal.find('.ui.form').data('qcElementIds')
      let state = change_status_dropdown.dropdown('get value')
      let assign_to = assign_to_dropdown.dropdown('get value')
      let due_date = set_status_modal.find('#due_date').val()
  
      if (qc_element_ids?.length > 0) { data.qc_element_ids = qc_element_ids }
      if (state !== '') { data.status = state }
      if (assign_to !== '') { data.assign_to = assign_to }
      if (due_date !== '') { data.due_date = due_date }
      $.ajax({
        url: `/qc_elements/change_status`,
        type: "POST",
        dataType: "json",
        data: data,
        beforeSend() {
          apply_button.addClass('loading')
        }
      }).done(function(_data, _textStatus, _xhr) {
        set_status_modal.modal('hide')
        $('body').toast({
          title: "Status Change Success!",
          class: 'success',
          displayTime: 3000,
          message: "QC Elements successfuly updated!"
        })
        $(".start-search .primary.button").trigger('click')
      }).fail(function(_jqXHR, _textStatus, errorThrown) {
        let status_modal_error_message = set_status_modal.find('#status_modal_error_message')
        status_modal_error_message.append(`<p>An unknown error occured!</p>`)
        status_modal_error_message.append(`<p>Error: ${errorThrown}</p>`)
        status_modal_error_message.removeClass('hidden')
        status_modal_error_message.addClass('visible')
      }).always(() => apply_button.removeClass('loading'))
    })
  },

  show_history_modal(qce_id) {
    $.ajax({
      url: `/qc_elements/${qce_id}/show_history_modal`,
    }).done(function(data, _textStatus, _xhr) {
        $('body').append(data)
        
        $('.ui.show.history.modal').modal({
          autofocus: false,
          duration: 200,
          onShow() {
          },
          onHidden() {
            $('body').find(".show.history.modal").remove()
          }
        }).modal('show')

    }).fail(function(_jqXHR, _textStatus, errorThrown) {
      console.log(errorThrown)
    })
  },

  context_menu() {
    $(".set.status.ec_element").on('click', function() {
      var qce_id = $(this).data('qc-element-id')
      qc_elements.set_status_modal(qce_id)
    })

    $(".show.history.ec_element").on('click', function() {
      var qce_id = $(this).data('qc-element-id')
      qc_elements.show_history_modal(qce_id)
    })
  },

  index_menu() {
    $(".add.all.to.binge_marker").off('click')
    $(".add.all.to.binge_marker").on('click', function() {
      qc_elements.load_binge_marker_import_modal(index_select.selected_ids())
    })
  },

  pdf_report() {
    $("body").append('<div class="ui pdf-report-wait mini modal"><div class="header">PDF Reports</div><div class="content"><div class="ui active centered inline text loader">Preparing Files<p></p></div></div><div class="actions"><div class="ui ok button">Cancel</div></div></div>')

    $(".ui.pdf-report-wait.modal").modal({
      autofocus: false,
      duration: 200,
      onShow() {
        let urlEncodedDataPairs = ""
        index_select.selected_ids().forEach((item) => {
          urlEncodedDataPairs += encodeURIComponent("qc_element_ids[]")+'='+encodeURIComponent(item)+'&'
        })
        urlEncodedDataPairs = urlEncodedDataPairs.slice(0, -1)

        var xhr = new XMLHttpRequest();
        xhr.open("GET", "/qc_elements/pdf_reports?" + urlEncodedDataPairs, true);
        xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
        xhr.onreadystatechange = function() {
            if (xhr.readyState == 4 && xhr.status == 200) {
              var filename = "";
              var disposition = xhr.getResponseHeader('Content-Disposition');
              if (disposition && disposition.indexOf('attachment') !== -1) {
                  var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                  var matches = filenameRegex.exec(disposition);
                  if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
              }

              $(".ui.pdf-report-wait.modal .content").html('<h4 class="ui center aligned icon header"><i class="file archive icon"></i></i>PDF Reports ready</h4>')
              $(".ui.pdf-report-wait.modal .actions").html('<div class="ui blue right labeled icon ok download button">Download<i class="download icon"></i></div>')

              const blob = new Blob([xhr.response], { type : "octet/stream" });
              const reader = new FileReader();
              reader.onload = e => {
                const anchor = document.createElement('a');
                anchor.style.display = 'none'
                anchor.href = e.target.result
                anchor.download = filename
                $(anchor).addClass("download_button")

                $(".ui.pdf-report-wait.modal .download.button").click(function() {
                  anchor.click()
                  $(".ui.pdf-report-wait.modal").modal("hide")
                })
              };
              reader.readAsDataURL(blob);
            }
        }
        xhr.responseType = "arraybuffer";
        xhr.send();
      },
      onHidden() {
        $(".download_button").remove()
        $(".ui.pdf-report-wait.modal").remove()
      }
    }).modal('show')
  }
}

window.qc_elements = qc_elements;
window.binge_marker_overview = qc_elements;


