document.addEventListener("turbo:load", function() {
  if ($("#workflow_jobs").length > 0) {
    workflow_jobs.init()
  }

  if ($("#workflow_jobs.edit").length > 0) {
    $(".ui.dropdown").dropdown()
  }
});
    
var workflow_jobs = {
  init() {
    workflow_jobs.init_index()
    $('.status_event.accordion').accordion()
  },
    
  init_index() {
    if ($("#workflow_jobs.index").length > 0) {
      
      $('.workflow_job.overview .not.started.item').on('click', function() {
        $('#search_execution_date_start').val("")
        $('#search_execution_date_end').val("")
        $("#search_status").dropdown('set exactly', 'not_started')
        $(".start-search .primary.button").trigger('click')
      });

      $('.workflow_job.overview .waiting.item').on('click', function() {
        $('#search_execution_date_start').val("");
        $('#search_execution_date_end').val("");
        $("#search_status").dropdown('set exactly', 'waiting');
        $(".start-search .primary.button").trigger('click')
      });
  
      $('.workflow_job.overview .in.progress.item').on('click', function() {
        $('#search_execution_date_start').val("");
        $('#search_execution_date_end').val("");
        $("#search_status").dropdown('set exactly', 'in_progress');
        $(".start-search .primary.button").trigger('click')
      });

      calendar.datepicker(".execution_date_start", { position: 'top left', lastResort: 'top left' } )
      calendar.datepicker(".execution_date_end", { position: 'top right', lastResort: 'top right' } )

      workflow_jobs.init_index_body();
    }
  },

  init_index_body() {
    popup.init()

    $(".check-pool").on('click', () =>
      $.ajax({
        url: "/workflow_jobs/check_external_workflow_pool",
        type: "GET",
        dataType: "json",
        error(_jqXHR, _textStatus, errorThrown) {
          return console.log(errorThrown);
        }
      })
    )

    $('#workflow_jobs .quicklook.cover').each((_i, cover) =>
      $.ajax({
        url: `/assets/${$(cover).data('asset-id')}/cover`
      }).done(function(result) {
        $(cover).find('.ui.placeholder').replaceWith(`<img src="${result}" class="ui cover image">`)
        $(cover).find('.warning.sign').show()
      })
    )

    workflow_jobs.context_menu()
  },

  init_status_modal_button() {
    $('.set.workflow.status.item').off('click')
    $('.set.workflow.status.item').on('click', function() {
      if (this.dataset?.wjId?.length > 0) {
        workflow_jobs.init_status_modal(this.dataset?.wjId)
      } else {
        workflow_jobs.init_status_modal()
      }
    })
  },

  init_status_modal(selected_ids = index_select.selected_ids()) {
    selected_ids = Array.isArray(selected_ids) ? selected_ids : [selected_ids]
    $.ajax({
      url: "/workflow_jobs/status_modal"
    }).done(function(data) {
      $("body").append(data)
      let set_status_modal = $('.status-workflow.modal')
      set_status_modal.modal({
        observeChanges: true,
        autofocus: false,
        onShow() {
          set_status_modal.find('#status').dropdown({
            onChange(_value, _text, _$choice) {
              set_status_modal.find('.disabled.send-status').removeClass('disabled')
              set_status_modal.find(".jobs-search-count").text(selected_ids.length)
              set_status_modal.find('.message').show()
            }
          })
          workflow_jobs.init_send_status(set_status_modal, selected_ids)
        }, 
        onHidden() {
          set_status_modal.remove()
        }
      }).modal("show")

    }).fail(() => console.log("error"))
  },

  init_priority_modal_button() {
    $('.set.workflow.priority.item').off('click.context_menu')
    $('.set.workflow.priority.item').on('click.context_menu', function() {
      if (this.dataset?.wjId?.length > 0) {
        workflow_jobs.init_priority_modal(this.dataset?.wjId)
      } else {
        workflow_jobs.init_priority_modal()
      }
    })
  },

  init_priority_modal(selected_ids = index_select.selected_ids()) {
    selected_ids = Array.isArray(selected_ids) ? selected_ids : [selected_ids]
    $.ajax({
      url: "/workflow_jobs/priority_modal",
    }).done(function(data) {
      $("body").append(data)
      let set_priority_modal = $('.workflow-priority.modal')
      set_priority_modal.modal({
        observeChanges: true,
        autofocus: false,
        onShow() {
          workflow_jobs.init_send_priority(set_priority_modal, selected_ids)
        }, 
        onHidden() {
          set_priority_modal.remove()
        }
      })
      set_priority_modal.find('.predefined_priority.dropdown').dropdown({
        onChange(_value, _text, _$choice) {
          set_priority_modal.find('.disabled.set-priority').removeClass('disabled')
          set_priority_modal.find(".jobs-search-count").text(selected_ids.length)
          set_priority_modal.find('.message').show()
        }
      })
      
      set_priority_modal.modal("show")
    }).fail((_jqXHR, _textStatus, errorThrown) => console.log(errorThrown))
  },

  init_send_status(modal, selected_ids) {
    return $('.ui.green.send-status').on('click', function() {
      const button = $(this);
      button.addClass("loading")
        $.ajax({
          url: "/workflow_jobs/update_status",
          type: "POST",
          dataType: "json",
          data: {
            model_ids: selected_ids,
            status: modal.find('#status').val()
          }
        }).done(function(_data) {
          modal.modal('hide')
          $('.start-search .primary.button').trigger('click')
        }).fail(
          () => console.log("error")
        ).always(
          () => button.removeClass('loading')
        )
    })
  },

  init_send_priority(modal, selected_ids) {
    $('.ui.green.set-priority').on('click', function() {
      const button = $(this)
      button.addClass("loading")
      $.ajax({
        url: "/workflow_jobs/update_priority",
        type: "POST",
        dataType: "json",
        data: {
          model_ids: selected_ids,
          priority: modal.find('.predefined_priority.dropdown').dropdown('get value')
        }
      }).done(function(_data) {
        modal.modal('hide')
        $('.start-search .primary.button').trigger('click')
      }).fail(
        () => console.log("error")
      ).always(
        () => button.removeClass('loading')
      )
    })
  },

  count_visible_workflows() {
    return $('fieldset .workflow-job-template').length
  },

  count_not_for_production_assets() {
    return $('.asset-list .not_for_production.label').length
  },

  switch_start_workflow_buttons(element, button) {
    $(button).addClass('active').siblings().removeClass('active teal green')
    if ($(button).hasClass('wait')) {
      $(button).addClass('teal')
      workflow_jobs.fill_start_workflow_dropdowns(element)
      $(element).find(".bottom.attached .start-workflow.dropdown").parent().show()

      // only select retrieve
      const retrieve_workflow = $(element).prevAll('fieldset.nested_workflow_workflow_jobs').filter(function(){ return $(this).find('.workflow-job-template').val() == 47 })
      if (typeof(retrieve_workflow) !== 'undefined') {
        $(element).find(".bottom.attached .start-workflow.dropdown").dropdown('set selected', retrieve_workflow.find('.workflow-job-uuid').val())
      }

    } else {
      $(button).addClass('green')
      $(element).find(".bottom.attached .start-workflow.dropdown").parent().hide()
      $(element).find(".bottom.attached .start-workflow.dropdown").dropdown('clear')
      $(element).find(".bottom.attached .start-workflow.dropdown").trigger('change')
    }
  },

  retrieve_exemptions() {
    return [23, 44, 47, 52, 113, 134, 141]
  },

  find_templates_to_exclude() {
    let template_ids = []
    $('fieldset .workflow-job-template').each(function(_i) {
      template_ids.push($(this).val())
    })
    return template_ids
  },

  find_templates_to_include(excluded_template_ids) {
    let templates = []
    $('fieldset.nested_workflow_workflow_jobs').each(function(_i) {
      const uuid = $(this).find('.workflow-job-uuid').val()
      const id = $(this).find('.workflow-job-template').val()
      if (excluded_template_ids.includes(id)) {
        return true
      }
      const name = $(this).find('.workflow-name').text()
      templates.push({ value: uuid, text: name, name: name })
    })
    
    return templates
  },

  find_templates_filling_awaited() {
    return [47, 90, 125]
  },

  fill_start_workflow_dropdowns(element) {
    $('.nested_workflow_workflow_jobs .start-workflow.dropdown').each(function() {
      var exclusion_list = [$(this).parents('fieldset.nested_workflow_workflow_jobs').find('.workflow-job-template').val()]
      
      if (typeof(element) !== 'undefined') {
        exclusion_list.push($(element).find('.workflow-job-template').val())
      }

      const templates_to_include = workflow_jobs.find_templates_to_include(exclusion_list)
      var currently_selected = Array.from($(this).find('select').addBack('select').val())

      let uuid_dropdown = $(this).dropdown({ values: templates_to_include })
      currently_selected = currently_selected.filter(x => templates_to_include.map(x => x.value).includes(x))
      
      let select = uuid_dropdown.find('select')[0]
      if (select) {
        for (let _o in select.options) { select.options.remove(0) }
        for (let {text, value} of templates_to_include) {
          select.add( new Option(text, value, false, currently_selected.includes(value)) )
        }
      }
      uuid_dropdown.dropdown('refresh')
      
      if (currently_selected.length == 0) {
        uuid_dropdown.dropdown('set value', null)
      } else {
        uuid_dropdown.dropdown('set exactly', currently_selected)
      }
      uuid_dropdown.trigger('change')
    }) 
  },

  remove_workflow_from_start_workflow_dropdowns(_value) {
    $('.nested_workflow_workflow_jobs .start-workflow.dropdown').each(function() {
      $(this).dropdown('remove selected', workflow_jobs.find_templates_to_include([$(this).parents('fieldset.nested_workflow_workflow_jobs').find('.workflow-job-template').val()]))
      $(this).trigger('change')
    })
  },

  context_menu() {
    workflow_jobs.init_status_modal_button()
    workflow_jobs.init_priority_modal_button()

    $('.cancel.datamover.job.item').on('click', e => {
      if (e.target?.dataset?.url?.length > 15)
      $.ajax({
        url: `${e.target.dataset.url}`,
        type: 'PATCH',
        dataType: 'json',
      }).done(() => {
        $(`[id^=data_mover][id$=${e.target?.dataset?.id}]`).find('.label:contains(in progress)').text('canceled')
        $('body').toast({
          title: 'Canceled Datamover Job!',
          class: 'success',
        })
      }).fail((_jqXHR, _textStatus, errorthrown) => {
        $('body').toast({
          title: 'Could not cancel Datamover Job!',
          class: 'error',
          message: `${errorthrown}`
        })
      })
    })

    $('.rety.datamover.job.item').on('click', e => {
      if (e.target?.dataset?.url?.length > 15)
      $.ajax({
        url: `${e.target.dataset.url}`,
        type: 'PATCH',
        dataType: 'json',
      }).done(() => {
        $(`[id^=data_mover][id$=${e.target?.dataset?.id}]`).find('.label:contains(in progress)').text('restarting')
        $('body').toast({
          title: 'Restarted Datamover Job!',
          class: 'success',
        })
      }).fail((_jqXHR, _textStatus, errorthrown) => {
        $('body').toast({
          title: 'Could not restart Datamover Job!',
          class: 'error',
          message: `${errorthrown}`
        })
      })
    })

  }
};

window.workflow_jobs = workflow_jobs;
