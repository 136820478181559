import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'dropzone', 'inputFiles', 'submitButton', 'fileMessage'
  ]

  dropzoneTargetConnected(element) {

    let controller = this
    //console.log("dropzone")
    //console.log(element)

    let dropzone = $(element)

    dropzone.off('drag dragstart dragend dragover dragenter dragleave drop')
    dropzone.on('drag dragstart dragend dragover dragenter dragleave drop', function(e) {
      e.preventDefault();
      e.stopPropagation();
    }).on('dragenter', function(e) {
      //console.log("enter")
      dropzone.addClass('dropactive')
    }).on('dragleave', function(e) {
      //console.log("leave")
    }).on('drop', function(e) {
      //console.log("drop")

      const files = e.originalEvent.dataTransfer.files;

      for(var i = 0, len = files.length; i < len; i++) {
        let ext = files[i].name.split('.').pop()
        if (ext == 'csv' ) {

          $(controller.dropzoneTarget).hide()

          controller.inputFilesTarget.files = files;

          $(controller.fileMessageTarget).show()
          $(controller.fileMessageTarget).find(".segment b").html(files[0].name)

          $(controller.submitButtonTarget).removeClass("disabled")
          break
        }
      }
      
    });
  }
}
