document.addEventListener("turbo:load", () => delivery_notes.init());

var delivery_notes = {
  index() {
    delivery_notes.init()
  },

  init() {

    if ($("#delivery_notes").length > 0) {

      $('.with-tooltip').popup({ inline: true })
      
      $('.ui.pointing.secondary.menu .item').tab()
      
      calendar.datepicker("#delivery_notes")
      delivery_notes.init_nested_fields()
      delivery_notes.init_dropdowns()
      
      search.delivery_note_project_autocomplete(".ui.search.queued_project")
    }
  },

  build_address(contacts_dropdown, contact_persons_dropdown,) {
    let c_id = contacts_dropdown.dropdown('get value')
    let cp_id = contact_persons_dropdown.dropdown('get value')
    if (c_id) {
      $.getJSON({
        url: `/contacts/${c_id}/address?contact_person_id=${cp_id}`,
      }).done(function(data, _textStatus, _xhr) {
        $('#delivery_note_dn_Address').val(data.address)
      }).fail(function(_xhr, _textStatus, _errorThrown) { 
        $('#delivery_note_dn_Address').val("")
      })
    }
  },

  init_dropdowns() {
    $('.courier.dropdown').dropdown({fullTextSearch: true})

    let contact_group_dropdown = $('.contact_group.dropdown').dropdown({fullTextSearch: true})

    let contacts_dropdown = $('.company.search.dropdown').dropdown({ 
      preserveHTML: true,
      fullTextSearch: false,
      cache: false,
      apiSettings: {
        url: `/contacts?q={/query}&search_input={/query}`,
        dataType: "json",
        cache: false,
        data: {
          search_groups: contact_group_dropdown.dropdown('get value') || undefined
        },
        beforeSend: function(settings) {
          settings.data.search_groups = contact_group_dropdown.dropdown('get value') || undefined
          return settings
        }
      },
    })

    let queued_client_dropdown = $('.queued.client.projects.search.dropdown').dropdown({ 
      fullTextSearch: false,
      cache: false,
      apiSettings: {
        url: `/projects/queued_for_delivery_note`,
        dataType: "json",
        cache: false,
        data: {
          type: "client"
        }
      },
      templates: {
        menu: dropdownTemplates.queued_for_delivery_note
      }
    })

    let queued_platform_dropdown = $('.platform.projects.search.dropdown').dropdown({ 
      fullTextSearch: false,
      cache: false,
      apiSettings: {
        url: `/projects/queued_for_delivery_note`,
        dataType: "json",
        cache: false,
        data: {
          type: "platform"
        }
      },
      templates: {
        menu: dropdownTemplates.queued_for_delivery_note
      }
    })

    let contact_persons_dropdown = $('.contact_person.search.dropdown').dropdown({ 
      fullTextSearch: false,
      cache: false,
      apiSettings: {
        url: `/contact_persons/contact_persons_for_contact?q={/query}&search_input={/query}`,
        dataType: "json",
        cache: false,
        data: {
          contact: contacts_dropdown.dropdown('get value') || undefined
        },
        beforeSend: function(settings) {
          settings.data.contact = contacts_dropdown.dropdown('get value') || undefined
          return settings
        }
      },
    })

    contacts_dropdown.dropdown('setting', 'onChange', function(_value, _text, _$selectedItem) {
      contact_persons_dropdown.dropdown('clear')
      contact_persons_dropdown.dropdown('queryRemote', '', function() {
        const regex = /Digital.*Team/gm
        const digital_distribution_team_id = $(contact_persons_dropdown.find('.item').toArray().find(o => $(o).data('text')?.match(regex) )).data('value')
        if (typeof(digital_distribution_team_id) !== "undefined") {
          contact_persons_dropdown.dropdown('set exactly', digital_distribution_team_id)
        }
      })
    })

    contact_persons_dropdown.dropdown('setting', 'onChange', function(_value, _text, _$selectedItem) {
      delivery_notes.build_address(contacts_dropdown, contact_persons_dropdown)
      $(".ui.search.queued_project").search("search remote", $(".ui.search.queued_project").search("get value"), () => {
        $('#queued_project_list').children('fieldset').remove()
        window.search.add_all_queued_projects()
      })
    })

    queued_client_dropdown.dropdown('setting', 'onChange', function(value, _text, _$selectedItem) {  
      queued_platform_dropdown.dropdown('clear')
      contact_group_dropdown.dropdown('set exactly', contact_group_dropdown.find('.item[data-text="Client"]').data('value'))
      contacts_dropdown.dropdown('queryRemote', `"${value}"`, function() {
        contacts_dropdown.dropdown('set exactly', value)
      })
    })

    queued_platform_dropdown.dropdown('setting', 'onChange', function(value, _text, _$selectedItem) {
      queued_client_dropdown.dropdown('clear')
      contact_group_dropdown.dropdown('set exactly', contact_group_dropdown.find('.item[data-text="Platform"]').data('value'))
      contacts_dropdown.dropdown('queryRemote', `"${value}"`, function() {
        contacts_dropdown.dropdown('set exactly', value)
      })
    })

    $('.delivery_note_creator.search.dropdown').dropdown({ 
      minCharacters: 1,
      delay: {search: 300},
      apiSettings: {
        cache: false,
        url: '/user_profile_webs.json?q={/query}&search_input={/query}',
        data: {
          include_email: true
        }
      },
      templates: {
        menu: dropdownTemplates.search_menu
      }
    })

    let notification_template_dropdown = $('.notification_template.dropdown').dropdown({
      allowReselection: true,
      placeholder: "Select a Predefined Template",
      clearable: true,
      apiSettings: {
        url: `/notification_templates?q={/query}&search_input={/query}`,
        dataType: "json",
        cache: false,
        data: {
          client: contacts_dropdown.dropdown('get value') || undefined
        },
        beforeSend: function(settings) {
          settings.data.client = contacts_dropdown.dropdown('get value') || undefined
          return settings
        }
      },
      onChange(value, _text, _$selectedItem) {
        $('#delivery_note_dn_Text').val(value.replace(/<br>/g, '\n'))
      }
    })
    notification_template_dropdown.dropdown('queryRemote', '', function() {})
  },

  set_queued_project_data(target, data) {
    if (data) {
      const { project } = data
      $.ajax({
        url: `/delivery_note_projects/queued_project_card`,
        data: {
          project_id: project.p_ID,
          type: "compact"
        }
      }).done(function(result) {
        let card = $(target)
        card.find(".queued-project-id-display").data('project-id', project.p_ID)
        card.find('input.queued-project-id').val(project.p_ID)
        $(result).appendTo(card)
      }).fail(function() {
        console.log('error loading remote card')
      })
    }
  },

  init_nested_fields() {
    $("#delivery_notes").off("fields_added.nested_form_fields.delivery_note_delivery_note_projects");
    $("#delivery_notes").on("fields_added.nested_form_fields.delivery_note_delivery_note_projects", function(event, param) {
      switch (param.object_class) {
        case 'project':
          delivery_notes.set_queued_project_data(event.target, param.additional_data)
          break
        default:
          return console.log("Fields were successfully added, callback not handled.")
      }
    })
  },
  
}
    
window.delivery_notes = delivery_notes
