document.addEventListener("turbo:load", function() {
  if ($("#downtime_schedulers").length > 0) {
    downtime_schedulers.init();
  }
});

var downtime_schedulers = {
  init() {
    if ($("#downtime_schedulers").length > 0) {
      $("#downtime_schedulers .dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true})

      calendar.datetimepicker($("#downtime_schedulers"))
    }
  },
};

window.downtime_schedulers = downtime_schedulers;
