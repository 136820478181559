document.addEventListener("turbo:load", function() {
  if ($("#data_mover_tasks").length > 0) {
    data_mover_tasks.init()
  }
});

var data_mover_tasks = {
  init() {
    if ($("#data_mover_tasks").length > 0) {
      $("#data_mover_tasks.ui.form .dropdown").dropdown()
      
      $('.data_mover_tasks.overview .not.started.item').on('click', function() {
        $("#search_status").dropdown('set exactly', '1')
        $(".start-search .primary.button").trigger('click')
      });
  
      $('.data_mover_tasks.overview .in.progress.item').on('click', function() {
        $("#search_status").dropdown('set exactly', '2')
        $(".start-search .primary.button").trigger('click')
      });

      calendar.datepicker(".search_created_date_start", { position: 'top left', lastResort: 'top left' } )
      calendar.datepicker(".search_created_date_end", { position: 'top right', lastResort: 'top right' } )

      calendar.datepicker(".search_start_date_start", { position: 'top left', lastResort: 'top left' } )
      calendar.datepicker(".search_start_date_end", { position: 'top right', lastResort: 'top right' } )

      calendar.datepicker(".search_finished_date_start", { position: 'top left', lastResort: 'top left' } )
      calendar.datepicker(".search_finished_date_end", { position: 'top right', lastResort: 'top right' } )
    }
  },

  init_index_body() {
    
  }
};

window.data_mover_tasks = data_mover_tasks
