document.addEventListener("turbo:load", function() {
  
  general.common.asset_tags = function() {
    if ($("#asset_tags").length > 0) {
      asset_tags.init()
    }
    asset_tags.index_menu()
  }
  general.common.asset_tags()
  
})

const asset_tags = {
  init() {
    if ($('#asset_tags .type.dropdown').dropdown("get value") != "shared") {
      $('#asset_tags .contact.field').hide()
    } else {
      $('#asset_tags .contact.field').show()
    }

    $("#asset_tags .ui.dropdown").not('.remote').not('.custom').dropdown();
    
    $("#asset_tags .ui.dropdown.type.custom").dropdown({
      onChange(value, _text, _$selectedItem) {
        if (value == "shared") {
          $('#asset_tags .contact.field').show()
        } else {
          $('#asset_tags .contact.field').hide()
          $('#asset_tags .contact.dropdown').dropdown("clear")
        }
      }
    });

    $('#asset_tags .remote.dropdown.contact').dropdown({
      apiSettings: {
        cache: false,
        url: '/contacts.json?q={/query}&search_input={/query}'
      },
      templates: {
        menu: dropdownTemplates.search_menu
      },
      onChange(value, _text, _$selectedItem) {
        return $('#asset_tags .remote.dropdown').val(value);
      }
    }).dropdown('set selected', $('#asset_tags .remote.dropdown').val());
  },

  index_menu() {
    $(".add.all.to.tags").off('click.tags')
    $(".add.all.to.tags").on('click.tags', function() {
      asset_tags.open_quickmenu()
    })
  },

  context_menu() {
    $(".item.asset.tag").off('click.tags')
    $(".item.asset.tag").on('click.tags', function() {
      asset_tags.open_quickmenu($(this).attr("data-target-model"), $(this).attr("data-target-id"))
    })
  },

  exlude_asset_tags() {
    let asset_tag_ids = []

    $(".nested_asset_asset_asset_tags").each(function (_i) {
      asset_tag_ids.push($(this).find("input").val())
    })

    return asset_tag_ids;
  },

  open_quickmenu(target_model = "", target_id = "") {
    asset_tags.load_index(target_model, target_id);
  },

  asset_tags(div, asset_id) {
    $.ajax({
      url: "/assets/" + asset_id + "/asset_tags",
      type: "GET",
      success(data, _textStatus, _xhr) {
        if (data !== null) {
          $(`#${div}`).html(data);
        } else {
          $(`#${div}`).html('<div class="box"><div class="ui basic teal label">Not found</div></div>');
        }
      }
    });
  },

  load_index(target_model, target_id) {

    $.ajax({
      url: "/asset_tags/quickmenu?target_model=" + target_model + "&target_id=" + target_id,
      type: "GET",
      success(data, _textStatus, _xhr) {
        $("body").append(data);

        $('.asset.tags.modal').modal({
          autofocus: false,
          closable: false,
          onShow() {
            asset_tags.init_asset_tags_dropdown()
            
            $(".asset.tags.modal .clear.tags input").checkbox({
              onChange() {
                let text = $(".asset.tags.modal .clear.tags .ui.text")
                if (text.hasClass("red")) {
                  text.removeClass("red")
                } else {
                  text.addClass("red")
                }
              }
            })

            $(".asset.tags.modal .apply.tags").on('click', function() {
    
              $(".asset.tags.modal .apply.tags").addClass("loading")
              let selected_ids
              if ($(this).attr("data-target-id") == "" ) {
                selected_ids = index_select.selected_ids()
              } else {
                selected_ids = [$(this).attr("data-target-id")]
              }
    
              $.ajax({
                url: "/asset_tags/add",
                type: "POST",
                data: {
                  asset_tag_ids: $(".asset.tags.modal .asset.tags.dropdown").dropdown("get value"),
                  asset_ids: selected_ids,
                  clear: $(".asset.tags.modal .clear.tags input").prop('checked')
                },
                success(_data, _textStatus, _xhr) {
                  assets.load_tags_labels($('#assets .remote.tag'))
                  $(".asset.tags.modal").modal("hide")
                },
                error(_jqXHR, _textStatus, errorThrown) {
                  console.log(errorThrown);
                  $(".asset.tags.modal .apply.tags").removeClass("loading")
                }
              })
            })
          },
          onHidden() {
            $('.asset.tags.modal').remove()
          }
        }).modal("show")
      },

      error(_jqXHR, _textStatus, errorThrown) {
        console.log(errorThrown);
      }
    })
  },

  init_asset_tags_dropdown() {
    const asset_tag_dropdown = $('.dropdown.custom.tags')
    asset_tag_dropdown.dropdown({
      apiSettings: {
        cache: false,
        clearable: true,
        action: 'get asset_tags',
        data: {
          tags: $("#asset_a_tag_ID").val()
        },
      },
      templates: {
        menu: dropdownTemplates.asset_tag_search
      },
      onChange(value, text, selectedItem) {
        asset_tags.check_modal_button()
      },
      onRemove(value, text, selectedItem) {
        asset_tags.check_modal_button()
      }
    }).dropdown('queryRemote', '', function() { 
      asset_tag_dropdown.dropdown('restore defaults')
      
      const selected_tags = $(".filter.settings").data("settings") && $(".filter.settings").data("settings")['search_asset_tags']
      if (typeof(selected_tags) != 'undefined') {
        asset_tag_dropdown.dropdown('set exactly', selected_tags)
      }
    })
  },

  check_modal_button() {
    let count = $(".asset.tags.modal #asset_tags")?.val()?.length
    if(count > 0) {
      $(".asset.tags.modal .apply.tags.button").removeClass("disabled")
    } else {
      $(".asset.tags.modal .apply.tags.button").addClass("disabled")
    }
  },

  search_template(response) {
    let html = '';
    $.each(response.asset_tags, function(_index, option) {
      html += '<div class="result" style="padding: 0;"><div class="content">';
      html += `<div id="asset-tag-${option.id}" data-controller="asset-tag" data-asset-tag-tag-id-value="${option.id}"></div></div>`
      html += '</div></div>';
    });
    return html;
  }
}

window.asset_tags = asset_tags
