// import Sortable from 'sortablejs';

document.addEventListener("turbo:load", function() {
  if ($("#distribution_channel_types").length > 0) {
    $("#distribution_channel_types .dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true})

    let callbackCardClick = function() {
      const callbackId = $(this).data('callbackId')
      $('.callback.modal').data('callbackId', callbackId)
      for (const element of $(`.nested_form_fields[data-callback-id="${callbackId}"`).find(':input')) {
        let name = element.name.replace(/\[\d\]/, `[X]`)
        let target = $(`[name='${name}']`)
        if ($(element).prop('tagName') === 'TEXTAREA') {
          $(target).val($(element).val())
        } else if ($(element).attr('type') === 'text') {
          $(target).val($(element).val())
        } else if ($(element).attr('type') === 'checkbox') {
          $(target).prop('checked', $(element).prop('checked'))
        }
      }
      $('.callback.modal').data('type', 'Edit')
      $('.callback.modal').modal('show')
    }

    // const nestedSortables = document.getElementsByClassName('list-group')
    // for (var i = 0; i < nestedSortables.length; i++) {
    //   new Sortable(nestedSortables[i], {
    //     group: 'nested',
    //     animation: 150,
    //     fallbackOnBody: true,
    //     invertSwap: true,
    //     onChoose: function (event) {
    //       console.log(event.item)
    //       $(event.item).css('background-color', 'white');
    //       $(event.item).find('.spacer.column').hide()
    //       $(event.item).find('.level.column').hide()
    //     }
    //   });
    // }

    $('.api-callback-card').on('click', callbackCardClick)

    $("#distribution_channel_type_dct_dc_ID").on('change', () => $("#distribution_channel_type_dct_Description").val(""))

    $('#distribution_channel_types').off('fields_added.nested_form_fields')
    $('#distribution_channel_types').on('fields_added.nested_form_fields', (event, param) => {
      // let callbackId = `999${param.added_index}`
      // $(event.target).find('.nested_form_fields').attr("data-callback-id", callbackId)
      // $('.callback.modal').data('callbackId', callbackId)
      // $('.callback.modal').data('type', 'Create')
      // $('.callback.modal').modal('show')
    })

    $('.callback.modal').modal({
      onApprove: () => {
        const callbackId = $('.callback.modal').data('callbackId')
        const form = $(`.nested_form_fields[data-callback-id="${callbackId}"`)
        const modal = $('.callback.modal')

        form.find('.callback-title').val(modal.find('.callback-title').val())
        form.find('.callback-url').val(modal.find('.callback-url').val())
        form.find('.callback-description').val(modal.find('.callback-description').val())
        form.find('.callback-default').prop('checked', modal.find('.callback-default').prop('checked'))
        form.find('.callback-active').prop('checked', modal.find('.callback-active').prop('checked'))
        
        if (!$(`.api-callback-card[data-callback-id="${callbackId}"`).length) {
          $('.api-callback-list').append(
          `<div class="ui segment list-group-item">
            <div class="ui two column grid">
              <div class="api-callback-card row" data-callback-id="${callbackId}" style="cursor: pointer;">
                <div class="one wide column right aligned right floated">
                  <i class="check circle outline large icon callback-active" style="margin-top: 12px; margin-left: 12px;"></i>
                </div>
                <div class="fifteen wide column left aligned right floated callback-url">
                  <div class="callback-title"><b></b></div><br>
                  <div class="callback-description"></div>
                </div>
              </div>
            </div>
          </div>`
          )
          $('.api-callback-card').off('click')
          $('.api-callback-card').on('click', callbackCardClick)
        }

        const card = $(`.api-callback-card[data-callback-id="${callbackId}"`)
        card.find('.callback-title b').text(`${modal.find('.callback-title').val()}`)
        card.find('.callback-description').text(modal.find('.callback-description').val())
        card.find('.callback-url').data('tooltip', modal.find('.callback-url').val())
        if (modal.find('.callback-active').prop('checked')) {
          card.find('.callback-active').removeClass('grey').addClass('green')
        } else {
          card.find('.callback-active').removeClass('green').addClass('grey')
        }
      },
      onDeny: () => {
      },
      onShow: () => {
        let type = $('.callback.modal').data('type')
        $('.callback.modal .header').text(`${type} Callback`)
        if (type === 'Edit') {
          $('.callback.modal .delete.button').show()
        }
      },
      onHide: () => {
        $('.callback.modal').data('callbackId', null)
        for (const element of $('.callback.modal').find(':input')) {
          $(element).val('')
          $(element).prop('checked', 'checked')
          $('.callback.modal').data('type', null)
          $('.callback.modal .delete.button').hide()
        }
      }
    })
    $('.callback.modal .delete.button').on('click', () => {
      const callbackId = $('.callback.modal').data('callbackId')
      const card = $(`.api-callback-card[data-callback-id="${callbackId}"`)
      $(`.nested_form_fields[data-callback-id="${callbackId}"]`).find('.remove_nested_fields_link').trigger('click')
      card.remove()
    })
  }
})
