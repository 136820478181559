import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["input", "userInfo"]

  connect() {
    //console.log("user connect")
  }

  checkChangePasswordInput() {
    if (this.inputTargets.every((t) => t.value.length > 0)) {
      $('.modal .approve.button').removeClass('disabled')
    } else {
      $('.modal .approve.button').addClass('disabled')
    }
  }

  showChangePasswordModal() {
    $('.change_password.modal').modal({
      onApprove() {
        $('#change_password_form').trigger('submit')
      }
    }).modal('show')
  }

  showDeviceKeysModal() {
    const device_keys_modal = document.getElementById("manage_keys_modal")
    device_keys_modal.reload()
    
    const modal = $(".manage_keys.modal")
    const remove_keys_modal = $(".remove.device.key.modal")
    
    modal.modal({
      onShow() {
        modal.find(".corner.label").on("click", function() {
          const model_id = $(this).data("model-id")
          const user_id = $("#users").data('model-id')

          remove_keys_modal.modal({
            allowMultiple: false,
            onApprove() {
              $.ajax({
                url: `/users/${user_id}/device_key`,
                type: 'DELETE',
                dataType: 'json',
                data: {
                  model_id: model_id
                },
                success(_data, _textStatus) {
                  
                }
              })
            },
            onHidden() {
              remove_keys_modal.remove()
              device_keys_modal.reload()
            },
            onDeny: function() {
              modal.modal("show")
            },
          }).modal("show")
          
        })
      },
      onHidden() {
        modal.remove()
        device_keys_modal.reload()
      },
    }).modal("show")
  }

  showManageDevicesModal() {
    const manage_devices_modal = document.getElementById("manage_devices_modal")
    const modal = $('.manage_devices.modal')
    const otp_modal = $(".remove.otp.device.modal")

    modal.modal({
      onShow() {
        $(".add-second-factor.button").removeClass("disabled")
        $(".add-second-factor.button").on("click", function() {
          $(this).addClass("disabled")
          users.add_second_factor()
        })

        modal.find(".corner.label").on("click", function() {
          const model_id = $(this).data("model-id")
          const user_id = $("#users").data('model-id')

          if (modal.find(".corner.label").length == 1) {
            users.remove_second_factor()
          } else {
            otp_modal.modal({
              allowMultiple: false,
              onApprove() {
                $.ajax({
                  url: `/users/${user_id}/second_factor`,
                  type: 'DELETE',
                  dataType: 'json',
                  data: {
                    model_id: model_id
                  },
                  success(_data, _textStatus) {
                    users.api_keys()
                  }
                })
              },
              onHidden() {
                otp_modal.remove()
                users.api_keys()
                
                manage_devices_modal.reload()
              },
              onDeny: function() {
                modal.modal("show")
              },
            }).modal("show")
          }
        })
      },
      onHidden() {
        modal.remove()
        manage_devices_modal.reload()
      },
    }).modal('show')
  }

  show_passwordless_auth_modal() {
    document.getElementById("manage_keys_modal").reload()
    $('.passwordless_auth.modal').modal({
      onApprove() {
        $('.passwordless_auth.modal .approve.button').addClass("loading")
        $('#webauthn-submit').trigger('click')
        return false
      },
      onHidden() {
        $('.passwordless_auth.modal .approve.button').removeClass("loading")
      }
    }).modal('show')
  }

  userInfoTargetConnected() {
    general.hover_link("user_profile_webs")
    users.init_checkboxes()
    users.init_dropdowns()

    $(".user_profile_webs .dropdown").dropdown({
      minCharacters: 1,
      delay: {search: 300},
      apiSettings: {
        cache: false,
        url: '/user_profile_webs.json?q={/query}&search_fullname={/query}'
      },
      templates: {
        menu: dropdownTemplates.upw
      },
      onChange(value, _text, _$selectedItem) {
        return $('#user_profile_web_id').val(value);
      }
    }).dropdown('set selected', $('#user_profile_web_id').val());
  }

  async resetFailedLogins(element) {
    var delay = (function() {
      var timer
      timer = 0
      return function(callback, ms) {
        clearTimeout(timer)
        timer = setTimeout(callback, ms)
      }
    })()

    const src = $(element.srcElement)

    const user_id = src.data("user-id")

    const response = await post(`/users/${user_id}/reset_failed_logins`)

    if (response.ok) {
      const result = await response.json

      src.hide()
      src.parent().find(".lock.open.icon").show()

      delay((function() {
        src.parent().attr("xyz", "duration-6 short-100% wide-25%")
        src.parent().addClass("xyz-out")

        delay((function() {
          src.parent().remove()
        }), 800)
      }), 800)

      //console.log(result)
      return result
    } else {
      src.addClass("horizontal-shaking")

      delay((function() {
        src.removeClass("horizontal-shaking")
      }), 500)
    }

  }
}
