document.addEventListener("turbo:load", function() {
  if ($("#time_trackers").length > 0) {
    time_tracker.form()
  }
  
  if ($(".main.menu .time.tracker").length > 0) {
    time_tracker.time_tracker_popup()
  }
});
      
var time_tracker = {

  form() {
    $('#time_trackers .ui.dropdown').dropdown({placeholder: false});
    calendar.datetimepicker($('#time_trackers'))
  },

  check_start_button(popup) {
    if (($("#time_tracker_description").val() !== "") && ($("#project_id").val() !== "") && ($("#time_track_category_id").val() !== "")) {
      return $(popup).find(".time.tracker.start").removeClass("disabled");
    } else {
      return $(popup).find(".time.tracker.start").addClass("disabled");
    }
  },

  init_popup(popup) {
    time_tracker.init_clock()

    $(".time.tracker.change").on("change", function() {
      time_tracker.check_start_button(popup)
    });

    $(".time.tracker.change").on("keyup", function() {
      time_tracker.check_start_button(popup)
    });

    $(popup).find(".time.tracker.start").on("click", function() {
      $(popup).find(".time.tracker.start").addClass("loading");
      return $.ajax({
        url: '/time_trackers/popup',
        type: 'POST',
        dataType: 'json',
        data: {
          description: $("#time_tracker_description").val(),
          project_id: $("#project_id").val(),
          time_track_category_id: $("#time_track_category_id").val()
        },

        complete() {},

        success(data, textStatus) {
          return time_tracker.tracker_started(popup);
        },

        error(jqXHR, textStatus, errorThrown) {
          console.log(errorThrown);
          return $(popup).find(".time.tracker.start").removeClass("loading");
        }
      });
    });

    $(popup).find(".ui.label.with-popup").popup({
      on: "hover",
      hoverable: true
    });

    $(popup).find(".ui.dropdown.time.tracker.project").dropdown({
      showOnFocus: false,
      onChange(value, text, $selectedItem) {
        $("#time_tracker_time_track_project_id").val(value);
      }
    });

    $(popup).find(".ui.dropdown.time.tracker.category").dropdown({
      showOnFocus: false,
      onChange(value, text, $selectedItem) {
        $("#time_tracker_time_track_category_id").val(value);
      }
    });

    time_tracker.tracker_stop(popup);
  },

  show_timer(popup) {
    return $.ajax({
      url: '/time_trackers/popup',
      type: "GET",
      dataType: 'HTML'
    }).done(function(result) {
      popup.html(result);
      time_tracker.init_popup(popup);

    }).fail(function() {
      popup.html('error');
    });
  },

  tracker_started(popup) {
    return $.ajax({
      url: '/time_trackers/popup',
      type: 'GET',
      dataType: 'HTML',
      complete() {},

      success(data, textStatus) {
        popup.html(data);
        return time_tracker.tracker_stop(popup);
      },

      error(jqXHR, textStatus, errorThrown) {
        console.log(errorThrown);
        return $(popup).find(".time.tracker.start").removeClass("loading");
      }
    });
  },

  tracker_stop(popup) {
    return $(popup).find(".time.tracker.stop").click(function() {
      $(popup).find(".time.tracker.stop").addClass("loading");
      return $.ajax({
        url: '/time_trackers/popup',
        type: 'POST',
        dataType: 'json',
        data: {
          stop: true
        },

        complete() {},

        success(data, textStatus) {
          return time_tracker.show_timer(popup);
        },

        error(jqXHR, textStatus, errorThrown) {
          console.log(errorThrown);
          return $(popup).find(".time.tracker.stop").removeClass("loading");
        }
      });
    });
  },

  time_tracker_popup() {
    $(".main.menu .time.tracker").popup({
      delay: {
        show: 200
      },
      position: 'bottom center',
      html: '<div style="height: 400px; width: 650px;"><div class="ui active inverted dimmer"><div class="ui text loader">Loading</div></div><p></p></div>',
      on: 'click',
      exclusive: true,
      inline: true,
      offset: -8,
      onShow(el) {
        const popup = this;
        time_tracker.show_timer(popup);
      }
    });
  },

  get_time_remaining(starttime) {
    var timeend = new Date();
    var t = Date.parse(new Date()) - Date.parse(starttime);
    timeend.setTime(t);
    
    var seconds = timeend.getSeconds();
    var minutes = timeend.getMinutes();
    var hours = timeend.getHours() - 1;
    return {
      'hours': hours,
      'minutes': minutes,
      'seconds': seconds
    };
  },

  initialize_clock(id, endtime) {
    var clock = document.getElementById(id);
    var hoursSpan = clock.querySelector('.hours');
    var minutesSpan = clock.querySelector('.minutes');
    var secondsSpan = clock.querySelector('.seconds');

    function updateClock() {
      var t = time_tracker.get_time_remaining(endtime);
      hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
      minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
      secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);
    }

    updateClock();
    var timeinterval = setInterval(updateClock, 1000);
  },

  init_clock() {
    if ($("#clock").length > 0) {
      function r(el, deg) {
        el.setAttribute('transform', 'rotate('+ deg +' 50 50)')
      }
      var d = new Date()
      r(sec, 6*d.getSeconds())  
      r(min, 6*d.getMinutes())
      r(hour, 30*(d.getHours()%12) + d.getMinutes()/2)
    }
    
    setInterval(function() {
      if ($("#clock").length > 0) {
        function r(el, deg) {
          el.setAttribute('transform', 'rotate('+ deg +' 50 50)')
        }
        var d = new Date()
        r(sec, 6*d.getSeconds())  
        r(min, 6*d.getMinutes())
        r(hour, 30*(d.getHours()%12) + d.getMinutes()/2)
      }
    }, 1000)

    if ($("#timer").length > 0) {
      var deadline = new Date(Date.parse($("#timer").data("time-tracker")) + 15 * 24 * 60 * 60 * 1000);
      time_tracker.initialize_clock('timer', deadline);
    }
  }
};

window.time_tracker = time_tracker;
