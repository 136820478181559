import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static outlets = [
    'nested-fields'
  ]

  static values = {
    settings: { type: Object, default: { 
      searchDelay: 200,
    }},
    url: String,
    parameterName: String,
    queryParams: { type: Object, default: {} },
    remoteFiltering: { type: Boolean, default: false },
    clientFiltering: { type: Boolean, default: false },
  }

  initialize() {
    this.initSearch()
  }

  initSearch() {
    const c = this
    let settings = {
      apiSettings: {
        url: c.urlValue,
        method: 'get',
        cache: false,
        beforeSend: c.beforeSend.bind(c),
        onResponse: c.onResponse.bind(c),
      },
      onSelect(result, response) {
        const search = this
        c.dispatch('select', { detail: { result, response, search }})
        $(c.element).search("set value", "")
        $(c.element).search("hide results")
        return false
      },
      ...c.settingsValue,
      ...c.convertTemplateSettings(),
    }
    
    $(c.element).search(settings)
  }

  beforeSend(settings) {
    const url = new URL(this.urlValue, window.location.origin)
    const params = new URLSearchParams(url.search)
    params.set('q', settings.urlData.query)
    if (this.hasParameterNameValue) {
      params.set(this.parameterNameValue, settings.urlData.query)
    }

    if (this.remoteFilteringValue && this.hasNestedFieldsOutlet) {
      const existing_fields =  this.nestedFieldsOutlet.existing_field_data
      existing_fields.forEach(ef => {
        for (const [key, value] of Object.entries(ef)) {
          params.append(`existing_fields[][${key}]`, value)
        }
      })
    }

    for (const [k, v] of Object.entries(this.queryParamsValue)) {
      if (Array.isArray(v)) {
        const key = k.endsWith('[]') ? k : `${k}[]`
        v.forEach(v => params.append(key, v))
      } else {
        params.set(k, v)
      }
    }

    url.search = params.toString()
    settings.url = url.href
    return settings
  }

  onResponse(response, _element, _xhr) {
    let results = response.results
    if (this.clientFilteringValue && response.success && results.length > 0 && this.hasNestedFieldsOutlet) {
      // filter duplicates if linked to nested fields
      const added_field_identifiers =  this.nestedFieldsOutlet.getFieldIdentifiers()

      let filtered_results = results.filter(r => !added_field_identifiers.includes(r.field_identifier))
      response.results = filtered_results
    }
    return response
  }

  setQueryParam(k, v) {
    this.queryParamsValue = { ...this.queryParamsValue, [k]: v }
  }

  unsetQueryParam(k) {
    // eslint-disable-next-line no-unused-vars
    const { [k]: _, ...rest } = this.queryParamsValue
    this.queryParamsValue = rest
  }

  convertTemplateSettings() {
    const c = this
    let templates = c.settingsValue.templates
    if (templates) {
      for (const [key, template_name] of Object.entries(templates)) {
        let template_function = template_name.split('.').reduce((acc, current) => acc && acc[current], window)
        if (typeof template_function === "function") {      
          templates[key] = template_function
        }
      }
    } else {
      templates = {}
    }
    return { templates: templates }
  }

}
