document.addEventListener("turbo:load", function() {
  document.querySelector('#transfers.index') && transfers.index()
  document.querySelector('#transfers.edit') && transfers.edit()
  document.querySelector('#transfers.show') && transfers.show()
})

var transfer_type = null

var transfers = {
  index() {
    transfers.initDownloadButtons()
    $('.progress').progress()
   
    if ($("#transfer-chart").length > 0) {
      transfers.fill_transfer_chart()
    }
    calendar.datepicker(".search_created_date_start", { position: 'top left', lastResort: 'top left' } )
    calendar.datepicker(".search_created_date_end", { position: 'top right', lastResort: 'top right' } )

    //window.asperaWeb.addEventListener('transfer', transfers.transferEvent)
  },

  edit() {
    transfers.init_form($('form#transfer_form'))
  },

  show() {
    Array.from($("#transfers.show").find('.remote.asset.card')).map((asset_card) => general.fetch_view(asset_card))
  },

  init_index_body() {
    transfers.initDownloadButtons()
    $('#transfers .with-tooltip').popup({ inline: false })
  },

  quicklook(model_id) {
    return $.ajax({
      url: `/transfers/${model_id}/quicklook`,
      data:{
        modal: true
      },
      success(data, _textStatus, _xhr) {

        $('body').append(data);
        
        $('.ui.quicklook.modal').modal({
          duration: 200,
          onShow() {
            transfers.show()
          },
          onHidden() {
            $('body').find(".quicklook.modal").remove()
          }
        }).modal('show');
      },

      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown);
      }
    });
  },

  init_form(target) {

    target.find('.item[data-tab]').tab()
    target.find('.dropdown').not('.remote,.custom').dropdown()
    target.on('turbo:frame-load', function(e) {
      assets.load_cover($(e.target).find('.cover.quicklook'))
    })
    transfer_type = target.find('#transfer_tr_trt_ID').val()
    const transfer_location_dropdown = target.find('#transfer_tr_ata_ID').dropdown({
      fullTextSearch: true,
      preserverHTML: false,
      apiSettings: {
        cache: false,
        url: '/transfers/asset_transfer_accounts',
        data: {
          transfer_type: $("#transfer_tr_trt_ID").val()
        },
        beforeSend(settings) {
          settings.data.transfer_type = $("#transfer_tr_trt_ID").val()
          return settings
        }
      },
    })
    
    let recipient_options = target[0].querySelector('fieldset.recipient.options')
    let expiry_date = target[0].querySelector('input.expiry_date')
    calendar.datetimepicker(".expiry_date.field", { popupOptions: { position: 'top left', lastResort: 'top left' } })

    target.find('#transfer_tr_trt_ID').dropdown({
      fullTextSearch: true,
      preserverHTML: false,
      fireOnInit: true,
      allowReselection: true,
      onChange(value, textvalue, _choice) {
        if (value != transfer_type) {
          transfer_location_dropdown.dropdown('clear')
        }
        transfer_type = value
        if (textvalue.match(/faspex|quicklink/i)) {
          // show recipient options
          recipient_options.style.display = 'contents'
          recipient_options.disabled = false
          // hide transfer location
          transfer_location_dropdown.parent().hide()
          transfer_location_dropdown.addClass('disabled')
          transfer_location_dropdown.find('select')[0].disabled = true
          // show expiry date
          expiry_date.closest('.field').style.display = 'block'
          expiry_date.disabled = false
        } else {
          // hide recipient options
          recipient_options.style.display = 'none'
          recipient_options.disabled = true
          // hide expiry date
          expiry_date.closest('.field').style.display = 'none'
          expiry_date.disabled = true
          // show transfer location
          transfer_location_dropdown.parent().show()
          transfer_location_dropdown.removeClass('disabled')
          transfer_location_dropdown.find('select')[0].disabled = false
        }
      }
    })

    target.find(".ui.search.transfer.asset").search({
      minCharacters: 3,
      apiSettings: {
        url: '/assets/quicklink?q={/query}'
      },
      onSelect(result, _response) {
        target.find('.search.transfer.asset .add_nested_fields_link').trigger('click', [{ asset: { a_ID: result.a_id } }])

        target.find(".ui.search.transfer.asset").search("set value", "")
        target.find(".ui.search.transfer.asset").search("hide results")
        return false
      }
    })

    let selectedOptions = [];
    for (const option of target.find('#transfer_user_profile_contact_ids')[0].options) {
      selectedOptions.push(option.value);
    }

    target.find('#transfer_user_profile_contact_ids').dropdown({
      fullTextSearch: true,
      preserverHTML: false,
      apiSettings: {
        cache: false,
        url: '/transfers/user_profile_contacts?q={/query}',
      },
    }).dropdown('set exactly', selectedOptions)

    if ($("#transfer-status").length > 0) {
      $.ajax({
        url: "/transfers/" + $(".sub.header").text() + "/status",
        success(data, _textStatus, _xhr) {
          $("#transfer-status").html(data)
        }
      })
    }

    const transfer_profile_dropdown = target.find('#transfer_tr_trp_ID')
    transfer_profile_dropdown.dropdown({
      apiSettings: {
        cache: false,
        url: '/transfers/transfer_profiles',
        beforeSend(settings) {
          settings.data.asset_ids = Array.from(document.querySelectorAll('fieldset.nested_transfer_transfer_assets:not([style*="display: none"]) input')).map((item) => item.value )
          return settings
        }
      },
    })

    transfers.nested_fields(target)
  },

  set_assets_count() {
    $(".transfer_asset_count").text($("#transfer-asset-list .transfer.asset:visible").length)
  },

  refresh_profile_dropdown() {
    const transfer_profile_dropdown = $('#transfer_tr_trp_ID')
    transfer_profile_dropdown.dropdown('clear')
    transfer_profile_dropdown.dropdown('queryRemote', '', function() {
      const options = transfer_profile_dropdown.dropdown().find('.item').toArray().map((obj) => $(obj).data('value'))
      transfer_profile_dropdown.dropdown('set selected', options[0])
    })
  },

  nested_fields(target) {
    target.off("fields_added.nested_form_fields.nested_transfer_transfer_assets")
    target.on("fields_added.nested_form_fields.nested_transfer_transfer_assets", function(event, param) {
      switch (param.object_class) {
        case "transfer_asset":
          transfers.set_transfer_asset_data(event.target, param.additional_data)
          transfers.set_assets_count()
          transfers.refresh_profile_dropdown()
      }
    }) 
    target.off("fields_removed.nested_form_fields.nested_transfer_transfer_assets")
    target.on("fields_removed.nested_form_fields.nested_transfer_transfer_assets", function(_event, param) {
      switch (param.object_class) {
        case "transfer_asset":
         transfers.set_assets_count()
         transfers.refresh_profile_dropdown()
      }
    }) 
  },

  set_transfer_asset_data(target, additional_data) {
    let a_id = additional_data.asset.a_ID
    $(target).find('.asset-id').val(a_id)
    let turbo_frame = $(target).find('turbo-frame')[0]
    turbo_frame.id += `_${a_id}`
    turbo_frame.src = `/assets/${a_id}/index_card?include_link=false&modal=true&turbo_frame_id=card_transfer_asset_${a_id}&type=compact`
  },

  fill_transfer_chart() {
    return $.ajax({
      url: "/charts/transfers_per_day",
      type: "GET",
      dataType: "json",
      success(data, _textStatus, _xhr) {
        return charts.transfer_chart('transfer-chart', data)
      }
    });
  },

  transferEvent(event, obj) {
    switch (event) {
      case 'transfer':
        return (() => {
          const result = [];
          for (let entry of Array.from(obj.transfers)) {
            const button = $(`[data-request-id='${entry.aspera_connect_settings.request_id}']`);
            const progress = button.parent().parent().prev().find('.progress');
            $(progress).progress('set percent', entry.percentage * 100);
            if (entry.percentage === 1) {
              result.push($(progress).find('.label').text('finished'));
            } else {
              result.push($(progress).find('.label').text(entry.calculated_rate_kbps + ' kb/s'));
            }
          }
          return result;
        })();
    }
  },

  downloadFile(data, button) {
    const connectSettings = {'allow_dialogs': false};
    const response = asperaWeb.startTransfer(JSON.parse(data), connectSettings);
    $(button).attr('data-request-id', response.request_id)
  },

  initDownloadButtons() {
    $('.connect.button').on("ajax:beforeSend", function(_e, _data, _status, _xhr) {
      $(this).addClass('loading');
    });
    $('.connect.button').on("ajax:complete", function(_event,_xhr,_options) {
      $(this).removeClass('loading');
    });
    $('.connect.button').on("ajax:success", function(event) {
      // eslint-disable-next-line no-unused-vars
      const [_data, _status, xhr] = event.detail;
      transfers.downloadFile(xhr.responseText, $(this));
      const progress = $(this).parent().parent().prev().find('.progress');
      $(progress).progress({ percent: 0 }).show();
    });
    $('.connect.button').on("ajax:error", function(_e, _data, _status, _xhr) {
      $(this).find('i').removeClass('cloud download').addClass('red warning sign');
    });
  }
};

window.transfers = transfers;
