document.addEventListener("turbo:load", function() {
  document.querySelector('#metadata_titles.index') && metadata_titles.index()
  //document.querySelector('#metadata_titles.edit') && metadata_titles.edit()
  document.querySelector('#metadata_titles.show') && metadata_titles.show()
});

var metadata_titles = {

  index() {
    assets.set_language_search('.search.bar')

    calendar.datepicker(".modified_date_start", { position: 'top left', lastResort: 'top left' } )
    calendar.datepicker(".modified_date_end", { position: 'top right', lastResort: 'top right' } )

    calendar.datepicker(".creation_date_start", { position: 'top left', lastResort: 'top left' } )
    calendar.datepicker(".creation_date_end", { position: 'top right', lastResort: 'top right' } )
  },

  show() {
    $('#metadata_titles.show .menu .item').tab()
  },

  edit() {
    metadata_titles.init()
    metadata_titles.init_dropdowns()
    metadata_titles.export_metadata_modal()
    metadata.approval_steps()

    $('.special.card .image').dimmer({on: 'hover'});

    $('.ui.mtr-tc-ID.dropdown').each((_index, element) =>
      $(element).dropdown({
        onChange(_value, _text, _choice) {
          const grid = $(element).parent().parent().parent();
          $(grid).find(".metadata-title-rating-r-System").val("");
          $(grid).find(".metadata-title-rating-r-Value").val("");
          $(grid).find(".metadata-title-rating-r-Description").val("");
          $(grid).find(".mtr-r-ID").dropdown('clear');
        }
    })
    );
    $('#metadata_title_mt_t_ID').dropdown({
      apiSettings: {
        url: '/titles/dropdown_search?q={/query}'
      },
      templates: {
        menu: dropdownTemplates.search_menu
      }
    }).dropdown('set selected', $('#avail_av_t_ID').val());

    metadata_titles.init_calendar();
    metadata_titles.check_metadata_title_ratings();
    metadata_titles.check_metadata_title_comment();

    let sidebar_name = "metadata_title_casts"
    general.element_sidebar(sidebar_name)
    metadata.update_sidebar(sidebar_name)

    metadata.casts(".element-sidebar-container." + sidebar_name, sidebar_name)

    $("#metadata_titles").off("fields_added.nested_form_fields")
    $("#metadata_titles").on("fields_added.nested_form_fields", function(event, param) {
      switch (param.object_class) {
        case "metadata_title_cast": 
          general.add_sidebar_item($(event.target).find(".nested-fields"), sidebar_name)

          metadata_titles.init_dropdowns()
          $("#metadata_titles #title_cast_crew_form .default-options.dropdown").not('.remote').dropdown({ placeholder: false, fullTextSearch: true });

          metadata.update_sidebar(sidebar_name)
          
          $(event.target).find(".dropdown.member-type").dropdown("set value", "Cast")
          $(event.target).find(".billing-top").checkbox("set checked")
          
          metadata.casts($(event.target), sidebar_name)
          return
        case "cast_code":
          metadata_titles.init_dropdowns()
          return
        case "metadata_title_rating":
          metadata_titles.init_language_fields()
          return
        case "metadata_title_territory":
          metadata_titles.init_language_fields()
          return
        case "metadata_title_language":
          metadata_titles.init_dropdowns()
          $("#metadata_titles #title_language_form .default-options.dropdown").not('.remote').dropdown({ placeholder: false, fullTextSearch: true });
          return
        case "metadata_title_language_url":
          return
        case "metadata_title_comment":
          $("#metadata_titles #title_comment_form .default-options.dropdown").not('.remote').dropdown({ placeholder: false, fullTextSearch: true });
          metadata_titles.check_metadata_title_comment();
          return
          
        default:
          return console.log(`Fields were successfully added, callback not handled for ${param.object_class}.`)
      }
    })

    $("#metadata_titles").off("fields_removed.nested_form_fields")
    $("#metadata_titles").on("fields_removed.nested_form_fields", function(event, param) {
      switch (param.object_class) {
        case "metadata_title_cast": 
          general.remove_sidebar_item($(event.target).find(".nested-fields").attr("data-model-id"), sidebar_name)
          return
        case "metadata_image":
        case "metadata_title_territory_image":
          const image_rows = $(event.target).parent('.image-rows')
          let images = image_rows.find('fieldset:visible')
          if (images.length <= 0) {
            image_rows.parent('.segment').find('.no-image.segment').show()
          }
          return
      default:
        return console.log(`Fields were successfully removed, callback not handled for ${param.object_class}.`)
      }
    })

    let title_genre = $('.ui.metadata-title-genres.dropdown')
    let typical_genre = $('.ui.metadata-title-typical-genre.dropdown')

    title_genre.dropdown({
      apiSettings: {
        cache: false,
        url: '/metadata_titles/genres?q={/query}'
      },
      templates: {
        menu: dropdownTemplates.search_menu
      },
      onChange(value, _text, _choice) {
        if (!value.includes(typical_genre.dropdown("get value"))) {
          typical_genre.dropdown("clear")
        }
      }
    }).dropdown('set selected', title_genre.val());

    typical_genre.dropdown({
      apiSettings: {
        cache: false,
        url: '/metadata_titles/genres?q={/query}&with_ids=true',
        data: {
          ids: title_genre.dropdown("get values")
        },
        beforeSend(settings) {
          settings.data.ids = title_genre.dropdown("get values")
          return settings
        }
      },
      templates: {
        menu: dropdownTemplates.search_menu
      },
    })
  },

  init() {
    metadata_titles.init_modal()
    if ($(".title-image-status").length > 0) {
      metadata_titles.init_image_status_filter()
    }
  },

  init_language_fields() {
    $("#metadata_titles #title_territories_form .default-options.dropdown").not('.remote').dropdown({ placeholder: false, fullTextSearch: true });
    metadata_titles.init_calendar();
    metadata_titles.check_metadata_title_ratings();
    metadata.territory_dropdown()
  },

  init_modal() {

    $(".title-asset-request.modal").modal({
      observeChanges: true,
      autofocus: false
    });

    $('.ui.modal.title-asset-request .dropdown').dropdown({
      placeholder: false,
      onChange(_value, _text, _choice) {
        metadata_titles.check_for_modal_value($('.ui.modal.title-asset-request'))
      }
    })
    
    calendar.datepicker(".title-asset-request.modal")

    $('#due_date').change(function() {
      metadata_titles.check_for_modal_value($('.ui.modal.title-asset-request'))
    })

    metadata_titles.init_send_image_asset_request_create()

    metadata_titles.check_for_modal_value($('.ui.modal.title-asset-request'))

    $(".button.metadata.title.create.asset.request").on('click', function() {
      $('.title-asset-request.modal').modal("show")
    });
  },

  init_image_status_filter() {

    $(".title-image-status .dropdown").dropdown({
      placeholder: false,
      fullTextSearch: true,
      onChange(_value, _text, _choice) {
        metadata_titles.fetch_title_image_status();
      }
    });

    $('.mandatory.checkbox input').change(function() {
      metadata_titles.fetch_title_image_status();
    })

    metadata_titles.fetch_title_image_status()
  },

  fetch_title_image_status() {
    if ($(".metadata_title_id").length > 0 ) {
      $.ajax({
        url: "/metadata_titles/" + $(".metadata_title_id").val() + "/image_statuses",
        type: "GET",
        data: {
          mandatory: $('.mandatory.checkbox input').prop("checked"),
          dct_ids: $('#title_image_status_distribution_channel_filter').dropdown("get value"),
          tc_ids: $('#title_image_status_territory_filter').dropdown("get value")
        }
      }).done(data => $('.title_image_status').html(data)).fail(() => console.log("error"));
    }
  },

  check_for_modal_value(element) {
    if ($(element).find('#distribution_channel').val() == "" || $(element).find('#fulfillment_partner').val() == "" || $(element).find('#territory').val() == "" || $(element).find('#language').val() == "" || $(element).find('#due_date').val() == "") {
      $(element).find('.asset_request.create').addClass("disabled");
    } else {
      $(element).find('.asset_request.create').removeClass("disabled");
    }
  },

  init_send_image_asset_request_create() {
    $('.title-asset-request.modal .asset_request.create').on('click', function() {
      $.ajax({
        url: "/delivery_orders/send_asset_request",
        type: "POST",
        data: {
          mt_ID: $(".metadata_title_id").val(),
          dct_ID: $(this).parent().parent().find('#delivery_order_content_package option[value="' + $(this).parent().parent().find('#delivery_order_content_package').val() + '"]').attr("data-distribution-channel-type"),
          docp_ID: $(this).parent().parent().find('#delivery_order_content_package').val() == '' ? null : $(this).parent().parent().find('#delivery_order_content_package').val(),
          arq_DueDate: $(this).parent().parent().find('#due_date').val(), 
          arq_FulfillmentPartnerID: $(this).parent().parent().find('#fulfillment_partner').val(),
          arq_tc_ID: $(this).parent().parent().find('#territory').val(), 
          arq_lc_ID: $(this).parent().parent().find('#language').val(),
          MandatoryImageOnly: $(this).parent().parent().find('.ui.mandatory.checkbox').checkbox('is checked'),
          IsHighPriority: $(this).parent().parent().find('.ui.priority.checkbox').checkbox('is checked'),
          ms_ID: $('#metadata_season').val()
        }
      }).done(_data => $('.metadata-asset-request.modal').modal('hide')).fail(() => console.log("error"));
    });
  },
  
  check_metadata_title_ratings() {
    $('.ui.mtr-tc-ID.dropdown').each((_index, element) =>
      $(element).dropdown({
        onChange(_value, _text, _choice) {
          const grid = $(element).parent().parent().parent();
          $(grid).find(".metadata-title-rating-r-System").val("");
          $(grid).find(".metadata-title-rating-r-Value").val("");
          $(grid).find(".metadata-title-rating-r-Description").val("");
          $(grid).find(".mtr-r-ID").dropdown('clear');
        }
      })
    )
  
    $.each($('#title_territories_form .ui.mtr-r-ID.dropdown'), function(_i, item) {
      $(item).dropdown({
        apiSettings: {
          cache: false,
          url: '/metadata_titles/ratings?q={/query}',
          data: {
            tc_ID: $(item).parent().parent().parent().parent().find(".ui.mtr-tc-ID.dropdown :selected").val()
          },
          beforeSend(settings) {
            settings.data.tc_ID = $(item).parent().parent().parent().parent().find(".ui.mtr-tc-ID.dropdown :selected").val();
            return settings;
          }
        },
        templates: {
          menu: dropdownTemplates.metadata_title_rating_search
        },
        onChange(_value, _text, choice) {
          $(item).parent().parent().parent().parent().find(".ui.metadata-title-rating-r-System").val(choice.data("system"));
          $(item).parent().parent().parent().parent().find(".ui.metadata-title-rating-r-Value").val(choice.data("ratingvalue"));
          return $(item).parent().parent().parent().parent().find(".ui.metadata-title-rating-r-Description").val(choice.data("description"));
        }
      }).dropdown('set selected', $(item).val())
    });
  },
  
  init_calendar() {
    calendar.datepicker("#metadata_titles")
  },
  
  check_metadata_title_comment() {
    $.each($('#title_comment_form .ui.metadata-title-comment-mail'), function(_i, item) {
      $(item).dropdown({
        apiSettings: {
          cache: false,
          url: '/metadata_titles/comments?q={/query}',
          data: {
            c_ID: $('.ui.mt-c-ID.dropdown :selected').val()
          },
          beforeSend(settings) {
            settings.data.c_ID = $('.ui.mt-c-ID.dropdown :selected').val();
            return settings;
          }
        },
        templates: {
          menu: dropdownTemplates.metadata_title_comment_search
        }
      }).dropdown('set selected', $(item).val())
    })
  },
  
  init_dropdowns(namespace = "#metadata_titles") {
    //$(".search-field.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
    $(namespace + " .default-options.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});

    metadata.contact_dropdown()
    metadata.language_dropdown()
    metadata.territory_dropdown()
    dropdown.init_action_menu()
  },

  export_metadata_modal() {
    if($(".export_metadata").length > 0){
      $(".export_metadata.button").off("click")
      $(".export_metadata.button").on('click', function () {
        $.ajax({
          url: "/metadata_titles/" + $(".metadata_title_id").val() + "/metadata_export_modal",
        }).done(function(data) {
          $("body").append(data);
          $('.export.metadata.title.modal').modal({
            autofocus: false,
            onShow() {
              $(".export.metadata.modal").find(".ui.dropdown").dropdown()
              $(".export.metadata.modal").find(".button.export.imagion.template").on('click', function() {
                $('.export.metadata.title.modal .content .message').remove()
                $.ajax({
                  url: "/metadata_titles/" + $(".metadata_title_id").val() + "/metadata_export",
                  type: "POST",
                  data: {
                    tc_id: $(".export.metadata.modal").find(".export.territory").dropdown("get value"),
                    lc_id: $(".export.metadata.modal").find(".export.language").dropdown("get value"),
                    images: $(".export.metadata.modal").find(".export.images").prop('checked'),
                    email: $(".export.metadata.modal").find(".export.email").val(),
                  }
                }).done(_data => $('.export.metadata.title.modal .content').prepend("<div class='ui visible info message'>Metadata export started.</div>")).fail(function(message) {
                  $('.export.metadata.title.modal .content').prepend("<div class='ui visible error message'>" + message.responseJSON.error +"</div>")
                  console.log("error")
                  console.log(message)
                } );
              })
            }, 
            onHidden() {
              $('.export.metadata.title.modal').remove();
            }
          }).modal("show");
  
        }).fail(() => console.log("error"));
      })
    }
  },

  init_index_body() {
    general.index_quicklook("metadata_titles")
  },

  init_clone_modal(modal_target) {
    //console.log("init_clone_modal")
    let modal = $(modal_target)
    modal.modal({
      observeChanges: true,
      autofocus: false,
      onShow() {
        metadata_titles.init_clone_form(modal)
      },
      onHidden() {
        modal.remove()
      },
      onApprove() {
        modal.find(".green.ok.button").addClass("loading disabled")
        Turbo.navigator.submitForm(document.querySelector('#metadata_titles_clone_modal form'))
        return false
      }
    }).modal("show")
  },

  init_clone_form(modal) {
    //console.log("init_clone_form")
    metadata_titles.init_dropdowns('#metadata_titles_clone_modal')
  }

}

window.metadata_titles = metadata_titles;
