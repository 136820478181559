import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static outlets = [
    "accordion",
    "nested-fields"
  ]

  static targets = [
    "searchDueDateStart",
    "searchDueDateEnd",
    "searchStatus"
  ]

  connect() {
  }

  toggleVideo() {
    this.accordionOutlet.toggle(0)
  }

  insertVideo() {
    if ($('#player-frame').length > 0) playerTools.insertVideo()
  }

  removeVideo() {
    if ($('#player-frame').length > 0) playerTools.removeVideo()
  }

  addQcElement({ detail: { result: { qce_ID }}}) {
    const c = this
    const qc_element_list = c.nestedFieldsOutlets?.find(o => o.element.dataset.nestedFieldsIdentifierValue == 'qc_elements')
    qc_element_list.remoteAdd({}, (template) => {
      const fieldset = template.querySelector('fieldset')
      fieldset.dataset.model = "QcElement"
      fieldset.dataset.model_id = qce_ID

      const turbo_frame = template.querySelector('turbo-frame')
      turbo_frame.id = `qc_element_${qce_ID}`
      turbo_frame.src = `/qc_requests/qc_element_card?qce_id=${qce_ID}`
      template.querySelector('.qc-element-id').value = qce_ID
      return template
    })
  }

  submitEnd(event) {
  }

  changeAsset({ detail: { result: asset }}) {
    const card_html = `
      <turbo-frame loading="lazy" id="card_asset_${asset.a_ID}" src="/assets/${asset.a_ID}/card?include_link=false&amp;turbo_frame_id=card_asset_${asset.a_ID}&amp;type=list" complete="">
        <div class="ui active small centered inline loader"></div>
      </turbo-frame>
    `
    $(this.element).find(".qc-request-asset.details").html(card_html)
    $(this.element).find(".asset-id-input").val(asset.a_ID)
  }

  searchOverdue() {
    $(this.searchStatusTargets).dropdown('set exactly',["finished", "not started", "verifying", "on hold", "in progress"])
    $(this.searchDueDateStartTargets).val('').trigger('change')
    $(this.searchDueDateEndTargets).val(DateHelper.yesterday().toLocaleDateString('de-DE')).trigger('change')
    this.search()
  }

  searchOneWeekDue() {
    $(this.searchStatusTargets).dropdown('set exactly',["finished", "not started", "verifying", "on hold", "in progress"])
    $(this.searchDueDateStartTargets).val(DateHelper.today(true)).trigger('change')
    $(this.searchDueDateEndTargets).val(DateHelper.one_week_from_now(true)).trigger('change')
    this.search()
  }

  searchTwoWeeksDue() {
    $(this.searchStatusTargets).dropdown('set exactly',["finished", "not started", "verifying", "on hold", "in progress"])
    $(this.searchDueDateStartTargets).val(DateHelper.today(true)).trigger('change')
    $(this.searchDueDateEndTargets).val(DateHelper.two_weeks_from_now(true)).trigger('change')
    this.search()
  }

  searchOpen() {
    $(this.searchStatusTargets).dropdown('set exactly',['not started'])
    this.search()
  }

  search() {
    this.element.querySelector(".start-search button").click()
  }
}
