import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['accordion']
  static values = {
    params: { type: Object, default: {} },
  }

  initialize() {
    this.initializeAccordion()
  }

  initializeAccordion() {
    $(this.element).accordion({
      onOpening: this.onOpening.bind(this),
      onClosing: this.onClosing.bind(this),
      onOpen: this.onOpen.bind(this),
      onClose: this.onClose.bind(this),
      ...this.paramsValue
    })
  }

  onOpening() {
    const event = new CustomEvent('accordion:opening', {
      detail: { element: this.element },
      bubbles: true
    })
    this.element.dispatchEvent(event)
  }

  onClosing() {
    const event = new CustomEvent('accordion:closing', {
      detail: { element: this.element },
      bubbles: true
    })
    this.element.dispatchEvent(event)
  }

  onOpen() {
    const event = new CustomEvent('accordion:open', {
      detail: { element: this.element },
      bubbles: true
    })
    this.element.dispatchEvent(event)
  }

  onClose() {
    const event = new CustomEvent('accordion:close', {
      detail: { element: this.element },
      bubbles: true
    })
    this.element.dispatchEvent(event)
  }

  open(index = 0) {
    $(this.element).accordion('open', index)
  }

  close(index = 0) {
    $(this.element).accordion('close', index)
  }

  toggle(index = 0) {
    $(this.element).accordion('toggle', index)
  }

  refresh() {
    $(this.element).accordion('refresh')
  }
}
