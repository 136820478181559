document.addEventListener("turbo:load", function() {
  if ($("#media_portals").length > 0) {
    return media_portals.init()
  }
})

var media_portals = {
  init() {
    $('#media_portals .ui.dropdown').dropdown({ placeholder: false })
    $('#media_portals .ui.pointing.secondary.menu .item').tab()
    
    media_portals.index_menu()
  },

  index_menu() {
    $(".assign.all.to.mediaportal").off('click.mediaportal')
    $(".assign.all.to.mediaportal").on('click.mediaportal', function() {
      media_portals.open_quickmenu()
    })
  },

  open_quickmenu(target_model = "", target_id = "") {
    media_portals.load_index(target_model, target_id)
  },

  load_index(target_model, target_id) {
    $.ajax({
      url: "/media_portals/quickmenu?target_model=" + target_model + "&target_id=" + target_id,
      type: "GET",
      success(data, _textStatus, _xhr) {
        $("body").append(data)

        $('.asset.assign.mediaportal.modal').modal({
          autofocus: false,
          closable: false,
          onShow() {
          },
          onHidden() {
            $('.asset.assign.mediaportal.modal').remove()
          }
        }).modal("show")

        $('.asset.assign.mediaportal.modal #assign_media_portal.dropdown').dropdown({
        //allowAdditions: true
        })

        $(".asset.assign.mediaportal.modal #assign_media_portal.dropdown").trigger('focus')

        $(".asset.assign.mediaportal.modal .assign.mediaportal.button").on('click', function() {

          $(".asset.assign.mediaportal.modal .assign.mediaportal.button").addClass("loading")
          let selected_ids
          if ($(this).attr("data-target-id") == "" ) {
            selected_ids = index_select.selected_ids()
          } else {
            selected_ids = [$(this).attr("data-target-id")]
          }

          $.ajax({
            url: "/media_portals/assign_assets",
            type: "POST",
            data: {
              media_portal_ids: $(".asset.assign.mediaportal.modal #assign_media_portal").dropdown("get value"),
              asset_ids: selected_ids,
            },
            success(_data, _textStatus, _xhr) {
              $(".asset.assign.mediaportal.modal").modal("hide")
            },
            error(_jqXHR, _textStatus, errorThrown) {
              console.log(errorThrown)
              $(".asset.assign.mediaportal.modal .assign.mediaportal.button").removeClass("loading")
            }
          })
        })
      },

      error(_jqXHR, _textStatus, errorThrown) {
        console.log(errorThrown)
      }
    })
  }
}

window.media_portals = media_portals
