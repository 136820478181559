document.addEventListener("turbo:load", function() {

  if ($("#workflow_job_templates").length > 0) {
   workflow_job_templates.init()
  }
});

var workflow_job_templates = {
  index() {
    workflow_job_templates.init()
  },

  edit() {
    workflow_job_templates.init()
    workflow_job_templates.toggle_qc_tab()
  },

  init() {
    $('#workflow_job_templates .ui.dropdown').dropdown()

    $('.workflow_job_template.overview .isactive.item').on("click", () => $("#search_active_active").prop("checked", true).trigger("click"))
    $('.workflow_job_template.overview .inactive.item').on("click", () => $("#search_active_inactive").prop("checked", true).trigger("click"))
    $('#workflow_job_template_wjt_Category').on("change", () => workflow_job_templates.toggle_qc_tab())
  },

  toggle_qc_tab() {
    if ($('#workflow_job_template_wjt_Category').dropdown('get value') == "Quality Control") {
      $('.item[data-tab=qctab]').show()
    } else {
      $('.item[data-tab=qctab]').hide()
    }
  }
}

window.workflow_job_templates = workflow_job_templates
