import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"
import { install, uninstall } from '@github/hotkey'

export default class extends Controller {
  static targets = [
    'form', 'parameter', 'infoText', 'filterCount', 'reset', 'searchCount', 'filterCount', 'resetControl'
  ]
  static values = {
    url: String,
    method: { type: String, default: 'get' },
    turboFrame: String,
    response_kind: { type: String, default: 'turbo-stream' },
  }

  disconnect() {
    for (const el of document.querySelectorAll('[data-hotkey]')) {
      uninstall(el)
    }
    this.formTarget.addEventListener("turbo:submit-start", this.ignoreEmptyElements())
    this.dispatch("disconnect")
  }

  ignoreEmptyElements() {
    for (const field of this.getInputs()) {
      if (field.value.length < 1) field.disabled = true
    }
  }

  connect() {
    //.dropdown()
    //$(this.infoTextTarget).accordion()
    for (const el of document.querySelectorAll('[data-hotkey]')) {
      install(el)
    }
    //this.updateSearchCount()
    this.dispatch("connect")
  }


  perform() {
    this.dispatch('perform')
    const emptyElements = this.getFormElements(false)
    emptyElements.forEach(e => e.setAttribute('disabled', true))
    this.updateSearchCount()
    clearTimeout(this.timeout)
    this.timeout = setTimeout(async () => {
      if (this.formTarget.nodeName == 'FORM') {
        this.formTarget.requestSubmit()
      } else {
        let params = this.getParameters()
        let request = new FetchRequest(this.methodValue, this.urlValue, {
          query: params,
          responseKind: this.responseKindValue
        })
        Turbo.navigator.delegate.adapter.showProgressBar()
        await request.perform()
        Turbo.navigator.delegate.adapter.progressBar.hide()
      }
      this.dispatch('performed')
      emptyElements.forEach(e => e.removeAttribute('disabled'))
    }, 200)
  }

  getInputs() {
    const inputSelector = 'input:not([type="button"],[type="submit"],[type="reset"]), textarea, select'
    let nodes = (this.formTarget.nodeName == 'FORM') ? this.formTarget.elements : this.parameterTargets
    return [].filter.call(nodes, node => node.matches(inputSelector) && node.matches(':not(:disabled)'))
  }

  getParameters() {
    let formData = new FormData()
    let nodes = this.getInputs()

    let standardParameter = [].filter.call(nodes, node => node.matches(':not([multiple])'))
    standardParameter.forEach(n => formData.append(n.dataset.parameterName || n.id, n.value))
    
    let multiSelectParameter = [].filter.call(nodes, node => node.matches('select[multiple]'))
    multiSelectParameter.forEach(mn => {
      let optionNodes = mn.querySelectorAll('option:checked')
      optionNodes.forEach(n => formData.append(mn.dataset.parameterName || mn.id, n.value))
    })
    
    if (this.hasTurboFrameValue) {
      formData.append('turbo_frame', this.turboFrameValue)
    }

    return formData
  }


  toggleInfoText() {
    $(this.infoTextTarget).accordion("toggle", 0)
  }

  updateSearchCount() {
    if (!this.hasResetTarget) return
    const searchParamsCount = this.getFormElements(true).length

    if (searchParamsCount > 0) {
      this.resetTarget.classList.remove('hide')
      this.filterCountTarget.textContent = searchParamsCount
    } else {
      this.resetTarget.classList.add('hide')
      this.filterCountTarget.textContent = 0
    }
  }

  getFormElements(active) {
    if (this.formTarget.nodeName == 'FORM') {
      const elements = Array.from(this.formTarget.elements).filter(e => e.tagName !== 'BUTTON')
      if (active) {
        return elements.filter(e => e.value.length > 0)
      } else {
        return elements.filter(e => e.value.length < 1)
      }
    } else {
      return []
    }
  }

  reset() {
    $(this.formTarget).form('reset')
    this.dispatch("reset") //
    $(this.formTarget).form('clear')
    this.updateSearchCount()
  }

  clear() {
    $(this.formTarget).form('clear')
    this.dispatch("cleared") // search:clear-> search#updateSearchCount
  }

  updateResultCount() { // update search result count
  }

  updateFilterCount() { // update search filter count
    const searchParamsCount = this.getFormElements(true).length
    if (searchParamsCount > 0) {
      if (this.hasResetControlTarget) this.resetControlTarget.classList.remove('hide')
      if (this.hasFilterCountTarget) this.filterCountTarget.textContent = searchParamsCount
    } else {
      if (this.hasResetControlTarget) this.resetControlTarget.classList.add('hide')
      if (this.hasFilterCountTarget) this.filterCountTarget.textContent = 0
    }
  }

  // Hotkeys, maybe move these into hotkey controller
  installHotkeys() {
    console.log('installing hotkeys')
    for (const el of this.formTarget.querySelectorAll('[data-hotkey]')) {
      install(el)
    }
  }

  uninstallHotkeys() {
    for (const el of this.formTarget.querySelectorAll('[data-hotkey]')) {
      uninstall(el)
    }
  }
}
