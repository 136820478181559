import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['dropdown']
  static values = {
    initializer: String,
    settings: { type: Object, default: { fullTextSearch: true } },
  }

  connect() {
  }

  initialize() {
    const c = this
    let settings = {
      onChange(value, text, selectedItem) {
        const selectedOptions = this.selectedOptions
        const dropdown = this
        c.dispatch('change', { detail: { value, text, selectedItem, selectedOptions, dropdown }})
      },
      onRemove(removedValue, removedText, $removedChoice) {
        const selectedOptions = this.selectedOptions
        const dropdown = this
        c.dispatch('remove', { detail: { removedValue, removedText, $removedChoice, selectedOptions, dropdown }})
      },
      ...this.settingsValue
    }
    if (this.hasInitializerValue) {
      let initializer_function = c.initializerValue?.split('.')?.reduce( (acc, current) => acc && acc[current], window)
      if (typeof initializer_function === "function") {
        initializer_function(c.element, settings)
      } else {
        console.log(`error dropdown wrong initializer function: ${this.initializerValue} `)
      }
    } else {
      $(c.element).dropdown(settings)
    }
  }

  dropdownTargetConnected(element) {
    $(element).dropdown()
  }
}
