document.addEventListener("turbo:load", function() {
  if ($("#notification_templates.index").length > 0) {
    notification_templates.index()
  }

  if ($("#notification-templates-show").length > 0) {
    notification_templates.show()
  }

  if ($("#notification-templates-edit").length > 0) {
    notification_templates.edit()
  }
})

var notification_templates = {
  index() {
    general.init_common()

    notification_templates.init_index_body()
    notification_templates.init_dropdowns()
  },

  edit() {
    general.init_common()
    notification_templates.init_dropdowns()
  },

  show() {

  },

  init_dropdowns() {
    $('#notification_templates .dropdown').dropdown({ placeholder: false, fullTextSearch: true })
  },

  init_index_body() {
    // general.quicklook_icon('#notification_templates .quicklook')
  },

  context_menu() {
  }
}

window.notification_templates = notification_templates
