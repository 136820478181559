import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
  }

  applyTimecodeFormat({ target, params: { frame_rate } }) {
    let str = target.value
    if (typeof str === 'undefined') return
    if (str === '') { str == '00000000' }
    if (str.includes(':')) { str = str.split(/;|:/).map(x => x.padStart(2, '0')).join('') }
    target.value = `${str.padStart(8, '0').match(/.{1,2}/g).join(':')}`
    this.dispatch('formatted:timecode', { target, detail: { value: target.value } })
  }

  cleanFrameRate({ target }) {
    assets.clean_framerate($(target))
    this.dispatch('cleaned-frame-rate', { target })
  }

  // cleanRuntime({ target }) {
  //   Timecode.clean_runtime($(target))
  //   $(target).trigger('change')
  // }

  applyCurrencyFormat({ target, params: { locale, currency } }) {
    let value = target.value.replaceAll(',', '.')
    locale ||= 'de-DE'
    currency ||= 'EUR'
    // currencyDisplay: none/
    let formatted_value = new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(value.gsub(',', '.'))
    if (formatted_value !== 'NaN') target.value = formatted_value
    this.dispatch('formatted:currency')
  }

  applyNumberFormat({ target, params: { locale, minimumFractionDigits } }) {
    let value = target.value.replaceAll(',', '.')
    locale ||= 'de-DE'
    let formatted_value = new Intl.NumberFormat('de-DE', { minimumFractionDigits }).format(value)
    if (formatted_value !== 'NaN') target.value = formatted_value
    this.dispatch('formatted:number')
  }
}
