import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {

  static targets = [
    'clientDropdown',
    'storagePolicyDropdown',
    'ghostInput',
    'additionalDescriptionLabel',
    'internalNotesLabel',
    'additionalDescription',
    'titleDropdown',
    'titleSeasonDropdown',
    'seasonSelection',
    'originalTitle',
    'originalTitleSeason',
    'titleLabel',
    'titleFields',
    'contentTypeField',
  ]

  static values = {
    paramsId: String,
  }

  static outlets = [
    "remote-dropdown",
    "workflow--main",
  ]

  connect() {
    $(this.ghostInputTargets).ghostInput()
  }

  async updateStoragePolicy({ detail: { value, _text, _selectedItem }}) {
    let c = this
    if (value.length > 0) {
      try {
        const response = await get('/assets/storage_policy', {
          query: {
            a_c_ID: [value].flat()[0],
            a_IsExplicitContent: false
          },
          responseKind: 'json'
        })
    
        if (response.ok) {
          let data = await response.json 
          if (data.client && c.hasStoragePolicyDropdownTarget) {
            $(c.storagePolicyDropdownTarget).dropdown('set exactly', data.client)
          }
        }
      } catch (error) {
        console.error('storage policy request failed', error)
      }
    }
  }

  updateGhostText({ currentTarget: { value }, params: { ghostSelector } }) {
    let element = $(this.ghostInputTargets).filter(ghostSelector)
    element.data('ghost-text', value)
    element.trigger('change')
  }

  appendAdditionalDescription() {
    this.additionalDescriptionLabelTarget.textContent = "Append To"
    if (this.hasAdditionalDescriptionTarget) {
      this.additionalDescriptionTarget.disabled = false
    }
  }

  replaceAdditionalDescription() {
    this.additionalDescriptionLabelTarget.textContent = "Replace"
    if (this.hasAdditionalDescriptionTarget) {
      this.additionalDescriptionTarget.disabled = true
    }
  }

  appendInternalNotes() {
    this.internalNotesLabelTarget.textContent = "Append To"
  }

  replaceInternalNotes() {
    this.internalNotesLabelTarget.textContent = "Replace"
  }

  titleChanged({ detail: { value }}) {
    const c = this
    const title_season_dropdown = $(this.titleSeasonDropdownTargets)

    if (value === "") {
      c.title_season_dropdown_outlet.urlValue = `/titles/${this.original_title_id}/title_seasons.json`
      title_season_dropdown.dropdown("clear")
      if ($(this.originalTitleSeasonTargets).is(":visible")) { 
        c.showTitleSeasonSelection()
      } else {
        c.hideTitleSeasonSelection()
      }
      return
    } else {
      c.title_season_dropdown_outlet.urlValue = `/titles/${value}/title_seasons.json`
    }

    title_season_dropdown.dropdown("queryRemote", "value", function() {
      const options = title_season_dropdown.parent().find('.item').toArray().filter((o) => $(o).data('value') == '' ? false : true)
      if (options.length == 1) {
        const value = $(options[0]).data("value")
        const text = $(options[0]).data("text")
        title_season_dropdown.dropdown('set exactly', value)
        if (text === 0 || text === "") c.hideTitleSeasonSelection()
      } else if (options.length > 1) {
        const current_ts_id = title_season_dropdown.dropdown("get value")
        if (!current_ts_id) title_season_dropdown.dropdown('set exactly', $(options[0]).data('value'))
        c.showTitleSeasonSelection()
      }
    })
  }

  async title_season_changed({ detail: { value }}) {
    await this.check_title_season()
  }

  get title_dropdown_outlet() {
    return this.remoteDropdownOutlets.find(x => x.element == this.titleDropdownTarget)
  }

  get title_season_dropdown_outlet() {
    return this.remoteDropdownOutlets.find(o => o.element == this.titleSeasonDropdownTarget)
  }

  get original_title_id() {
    if (this.hasOriginalTitleTarget) return this.originalTitleTarget.value
  }

  async checkWhetherTitleChangeIsRequired(client_id, title_id) {
    if (!title_id || title_id === "") return true
    const response = await get(`/contacts/${client_id}/titles/${title_id}/exists`)
    if (response.statusCode == 200) {
      return false
    } else {
      return true
    }
  }

  showTitleSeasonSelection() {
    $(this.seasonSelectionTargets).show()
  }

  hideTitleSeasonSelection() {
    $(this.seasonSelectionTargets).hide()
  }

  clearTitleDropdowns() {
    $(this.titleDropdownTarget).dropdown("clear")
    $(this.titleSeasonDropdownTarget).dropdown("clear")
  }

  async clientChanged({ detail: { value } }) {
    if (!this.hasTitleDropdownTarget) return
    this.title_dropdown_outlet.setQueryParam("contact_id", value)    
    this.title_change_required = await this.checkWhetherTitleChangeIsRequired(this.clientDropdownTarget.value, this.original_title_id)
    this.clearTitleDropdowns()
    this.check_title_season()
  }

  displayMandatoryTitleWarning() {
    $(this.titleFieldsTargets).addClass("error")
    $(this.titleLabelTargets).show()
    if (this.hasWorkflowMainOutlet) {
      this.workflowMainOutlet.displayMessage("Contact/Title Change", "Changing the title might require changing additional values like content type, season number, episode number etc.<br>Please ensure data integrity of the resulting assets!", "ui icon closable warning message", "exclamation triangle icon", "title_change")
    }
    forms.disable("mandatory_title")
  }

  removeMandatoryTitleWarning() {
    $(this.titleFieldsTargets).removeClass("error")
    $(this.titleLabelTargets).hide()
    forms.enable("mandatory_title")
  }

  async check_title_season() {
    if (this.title_change_required) {

      const client_id = this.clientDropdownTarget.value
      const title_id = this.titleDropdownTarget.value
      const title_season_id = this.titleSeasonDropdownTarget.value

      if (title_id == "" || title_season_id == "") {
        this.displayMandatoryTitleWarning()
        return
      }

      const response = await get(`/contacts/${client_id}/titles/${title_id}/exists?title_season_id=${title_season_id}`)
      if (response.statusCode == 200) {
        this.removeMandatoryTitleWarning()
      } else {
        this.displayMandatoryTitleWarning()
      }   

    } else {
      this.removeMandatoryTitleWarning()
    }
  }
}

$.fn.ghostInput = function(options) {
  return this.each(function(_i, el){
    var o = $.extend({
      ghostText: "",
      ghostPlaceholder: "",
      ghostTextClass: "ghost-text",
      ghostHiddenClass: ""
    }, options)
    var element = $(el)
    if(element.ghostInputValidate) { return true }
    element.ghostInputValidate = true;
    var r = element.attr("id") || "";
    o.ghostPlaceholder = element.attr("placeholder") || o.ghostPlaceholder
    element.attr("placeholder") ? element.removeAttr("placeholder") : ""
    o.ghostText = element.attr("data-ghost-text") || o.ghostText
    o.ghosttextspan = $("<span />").text(o.ghostPlaceholder)
    o.ghostHiddenLabel = $("<span />").css({"visibility":"hidden"}).addClass(o.ghostHiddenClass)
    o.ghostBox = $("<span />").attr("for", r).addClass(o.ghostTextClass).append(o.ghostHiddenLabel).append(o.ghosttextspan)

    element.parent().prepend(o.ghostBox)
    o.ghostBox.click(function() {
      element.focus()
    })
    element.off(".ghost_input")
    element.on("keyup.ghost_input keydown.ghost_input keypress.ghost_input change.ghost_input",function() {
      setTimeout(function() {
        var t = "" == $.trim(element.val()) ? o.ghostPlaceholder: $(element).data("ghost-text")//o.ghostText
        o.ghostHiddenLabel.text(element.val())
        o.ghosttextspan.text(t)
      }, 0)
    })
    return true
  })
}