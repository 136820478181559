import { Controller } from "@hotwired/stimulus"
import { get, post, patch } from '@rails/request.js'

export default class extends Controller {

  static outlets = [
    "accordion",
    "nested-fields"
  ]

  static values = {
    assetId: String
  }

  static targets = [
    "videoAccordion",
    "bingeMarkerButton",
    "step",
    "proxyVideoAsset",
    "ocrTimeline",
    "searchStatus",
    "searchDueDateStart",
    "searchDueDateEnd"
  ]

  connect() {
  }

  toggleVideo() {
    this.accordionOutlet.toggle(0)
  }

  insertVideo() {
    if ($('#player-frame').length > 0) playerTools.insertVideo()
  }

  removeVideo() {
    if ($('#player-frame').length > 0) playerTools.removeVideo()
  }

  ocrTimelineTargetConnected(element) {
    qc_elements.init_ocr_timeline()
  }
  
  proxyVideoAssetTargetConnected(element) {
    if ($(element).data('binge-marker')) { playerTools.insertVideo() }
  }

  get current_timecode() {
    let tc = null
    if (typeof player !== 'undefined') {
      let framerate = player.timecode_data?.framerate
      let timecode_format = player.timecode_data?.timecode_format
      let offsetString = player.timecode_data?.offset
      let offset = Timecode.from_string(offsetString, framerate, timecode_format)
      var tc_a = Timecode.from_runtime_seconds(player.currentTime(), framerate, timecode_format)
      if (typeof(offset.toString()) === 'string') { tc_a = tc_a.add(offset) }

      tc = tc_a.toString()
    }
    return tc
  }

  async updateDurationEstimate({ detail: { value }}) {
    const a_id = this.assetIdValue
    try {
      const response = await get(`/assets/${a_id}/qc_duration`, {
        responseKind: 'json',
        query: {
          qc_element_spec_id: value
        }
      })
      if (response.ok) {
        const data = await response.json
        if (data && data.duration) {
          $(this.element).find('#qc_element_qce_ExpectedQcDuration').val(data.duration)
        }
      } else {
        throw new Error(response.statusText)
      }
    } catch(e) {
    }
  }

  enterBingeMarker(event) {
    if (document.activeElement == document.body) {
      this.bingeMarkerButtonTargets[0]?.click()
      event.preventDefault()
    }
  }

  get last_step() {
    return this.stepTargets.reduce((max, cur) =>
      Number(cur.dataset.step) > Number(max.dataset.step) ? cur : max
    )
  }

  confirmBingeMarkerStep({ target }) {
    if (!this.hasStepTarget) return
    const last_step = this.last_step//Math.max(...this.stepTargets.map(step => $(step).data('step')))
    const step = $(target).data('step')
    if (target == last_step) {
      $(target).trigger('change')
      $('#qc_elements .finish.button').trigger('click') // make a target
    } else {
      const next_step = this.stepTargets.find(s => s.dataset.step == `${step + 1}` )
      next_step.focus()
      next_step.click()
    }
  }

  loadAssetDetails() {
    // TOOD: targets
    const qce_id = $(".qc_element_ID").val()
    $.ajax({
      url: "/qc_elements/" + qce_id + "/asset_details_from_asset",
      type: "GET",
      dataType: "json",
      error:  function (xhr, status, error) {
        console.log(xhr, status, error)
      },
      success: function (result, _status, _xhr) {
        $('.asset.title.insert.text').val(result.a_TitleInsertText);
        $('.asset.title.insert.language.dropdown').dropdown('set selected', result.a_lc_TitleInsertLanguageID)
        $('.asset.start.credits.language.dropdown').dropdown('set selected', result.a_lc_StartCreditsLanguageID)
        $('.asset.end.credits.language.dropdown').dropdown('set selected', result.a_lc_EndCreditsLanguageID)
        $('.asset.subtitle.language.dropdown').dropdown('set selected', result.a_lc_SubtitleLanguageID)
        $('.asset.forced.subtitle.language.dropdown').dropdown('set selected', result.a_lc_PartialForcedSubtitleLanguageID)
        $('.asset.text.insert.language.dropdown').dropdown('set selected', result.a_lc_TextInLiveActionLanguageID)
        $('.asset.text.live.action.language.dropdown').dropdown('set selected', result.a_lc_TextInsertLanguageID)
      }
    })
  }

  checkEventInput(event) {
    const target = event.target
    $(target).parent().removeClass('warning')
    $('#qc_elements :input[type="submit"]').prop('disabled', false)

    const current_step = $(target).data('step')
    const current_tc_value = parseInt($(target).val().split(":").join(""))

    if (current_step > 0) {
      const previous_step = $(`#qc_elements input.event[data-step="${current_step-1}"]`).val()
      const previous_step_tc_value = previous_step.split(":").join("")
      if (previous_step_tc_value > current_tc_value) {
        $(target).parent().addClass('warning')
        $('#qc_elements :input[type="submit"]').prop('disabled', true) // TODO: make targets

        $.toast({
          title: `Time Paradox`,
          class: 'warning',
          message: `${$(target).val()} is before ${previous_step}`,
          displayTime: 5000,
        })
        target?.focus()
        target?.click()
        event.preventDefault()
      }

    }
  }

  addQcEvent({ detail: { result: { qcev_ID }}}) {
    const c = this
    const qc_event_list = c.nestedFieldsOutlets?.find(o => o.element.dataset.nestedFieldsIdentifierValue == 'qc_events')
    qc_event_list.remoteAdd({}, (template) => {
      const fieldset = template.querySelector('fieldset')
      fieldset.dataset.model = "QcEvent"
      fieldset.dataset.model_id = qcev_ID
      fieldset.id = `fieldset_qc_event_${qcev_ID}`

      const turbo_frame = template.querySelector('turbo-frame')
      turbo_frame.id = `qc_event_${qcev_ID}`
      turbo_frame.src = `/qc_elements/qc_event_card?qcev_id=${qcev_ID}&verifying=false`

      template.querySelector('.qc-event-id').value = qcev_ID
      return template
    })
  }

  async updateQcEventVerifierResult({ detail: { value: result, text, selectedItem, selectedOptions, dropdown }}) {
    let qcev_id = dropdown.closest("fieldset").dataset?.modelId
    if (qcev_id) {
      const response = await post(`/update_qc_event_result/${qcev_id}`, {
        body: {
          qcev_ResultVerifier: result,
        }
      })
      if (response.ok) {
        $.toast({
          title: `QcEvent`,
          class: 'success',
          message: `Updated Result!`,
        })
      } else {
        $.toast({
          title: `QcEvent`,
          class: 'error',
          message: `Failed to update Result!`,
        })
      }
    }
  }

  searchOverdue() {
    $(this.searchStatusTargets).dropdown('set exactly',["finished", "not started", "verifying", "on hold", "in progress"])
    $(this.searchDueDateStartTargets).val('').trigger('change')
    $(this.searchDueDateEndTargets).val(DateHelper.yesterday().toLocaleDateString('de-DE')).trigger('change')
    this.search()
  }

  searchOneWeekDue() {
    $(this.searchStatusTargets).dropdown('set exactly',["finished", "not started", "verifying", "on hold", "in progress"])
    $(this.searchDueDateStartTargets).val(DateHelper.today(true)).trigger('change')
    $(this.searchDueDateEndTargets).val(DateHelper.one_week_from_now(true)).trigger('change')
    this.search()
  }

  searchTwoWeeksDue() {
    $(this.searchStatusTargets).dropdown('set exactly',["finished", "not started", "verifying", "on hold", "in progress"])
    $(this.searchDueDateStartTargets).val(DateHelper.today(true)).trigger('change')
    $(this.searchDueDateEndTargets).val(DateHelper.two_weeks_from_now(true)).trigger('change')
    this.search()
  }

  searchNotStarted() {
    $(this.searchStatusTargets).dropdown('set exactly',['not started'])
    this.search()
  }

  search() {
    this.element.querySelector(".start-search button").click()
  }

  async submit(event) {
    const c = this
    const { target: form, submitter } = event
    if (submitter.dataset?.publish === "true") {
      event.preventDefault()
      const { progress_bar } = c.createBingeMarkerToast()
      progress_bar.progress({ percent: 99 })
      try {
        let response = await patch(submitter.formAction, { body: new FormData(form) })
        if (response.ok) {
          const data = await response.json
          c.dmm_export(data)
        } else {
          throw new Error("Unable to Finish!")
        }
      } catch(error) {
        console.log(error)
        c.showBingeMarkerError(error)
        $(this.element).find('input[type="submit"][disabled]').removeAttr('disabled')
      }
    }
    c.element.querySelector('#qc_elements .error input.event')?.focus()
  }

  get binge_marker_toast() {
    return this._binge_marker_toast
  }

  createBingeMarkerToast() {
    let existing_toast = this._binge_marker_toast?.toast
    existing_toast?.toast("close")
    existing_toast?.toast("destroy")
    this._binge_marker_toast = null
    let toast = $.toast({
      displayTime: 0,
      title: 'Binge Marker Publisher',
      message: `Initializing...`,
      showImage: `${$('.magenta-image[src^="/rails/assets/magentatv"]').attr('src')}`,
      showProgress: 'bottom',
      pauseOnHover: false,
      class: "initalizer",
    })
    
    let toast_header = toast.find('.content .header')
    let toast_message = toast.find('.content .message')
    let progress_bar = $('<div class="ui attached active progress pink bottom compact sliding indeterminate" data-percent="99"><div class="bar up progressing " style="animation-duration: 0.5s; transition-duration: 300ms; display: block; width: 99%;"></div></div>').appendTo(toast.parent('.toast-box'))
    this._binge_marker_toast = { toast, toast_header, toast_message, progress_bar }
    return this._binge_marker_toast
  }

  showBingeMarkerError(message = "An unknown error occurred!") {
    const { toast, toast_message, progress_bar } = this.binge_marker_toast
    toast_message?.html(message)
    toast?.removeClass('neutral')?.addClass('red')
    progress_bar?.removeClass('sliding blue')?.addClass('red')
    setTimeout(() => toast?.toast('close'), 5000)
  }

  showBingeMarkerSuccess(message = "Success") {
    const { toast, toast_header, toast_message, progress_bar } = this.binge_marker_toast
    progress_bar?.removeClass('sliding blue pink')?.addClass('pink')
    toast.removeClass('neutral').addClass('pink')
    toast_header.html(message)
    toast_message.remove()
    toast.toast('show')
  }

  async dmm_export(data, redirect = true) {
    const c = this
    try {
      c.binge_marker_toast?.toast_message?.html('Do not close this window.<br>Publishing in progress')
      const response = await post(`/assets/${data.asset_id}/events/content_marker/to_dmm.json`, { body: { destination: 2 }})
      if (response.ok) {
        c.showBingeMarkerSuccess('Successfully Published')
        c.redirect(data.redirection_url)
      } else {
        c.redirect(data.redirection_url, 5000)
        throw new Error("Unable to Publish!")
      }
    } catch(error) {
      c.showBingeMarkerError(error)
    }
  }

  redirect(redirection_url, timeout = 1500) {
    if (!redirection_url) return
    const { toast } = this.binge_marker_toast
    setTimeout(() => {
      toast.toast('close')
      window.location.replace(redirection_url)
    }, timeout)
  }
}
