import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static outlets = [
    'qc-element'
  ]

  static targets = [
    'modal',
    'submitButton',
    'categoryDropdown',
    'errorcodeDropdown',
    'errorDescription',
    "tcIn",
    "tcOut"
  ]

  static values = {
  }

  connect() {
  }

  tcInTargetConnected(element) {
    const start_tc = this.current_timecode
    if (start_tc) {
      element.value = start_tc
    } else {
      element.value = "00:00:00:00"
    }
  }

  disableSubmitButton() {
    this.submitButtonTarget.classList.add('disabled')
  }

  enableSubmitButton() {
    this.submitButtonTarget.classList.remove('disabled')
  }

  updateErrorcodeDropdownCategory({ detail: { value: category } }) {
    if (this.hasErrorcodeDropdownTarget) {
      this.errorcodeDropdownTarget.dataset.remoteDropdownQueryParamsValue = JSON.stringify({ qcec_Category: category  })
    }
  }

  errorcodeChanged({ detail: { value, selectedItem } }) {
    if (!this.hasErrorDescriptionTarget) { return }
    if (selectedItem) {
      this.errorDescriptionTarget.querySelector("p").textContent = selectedItem.data("description")
      $(this.errorDescriptionTarget).show(300)
    } else {
      $(this.errorDescriptionTarget).hide()
    }
  }

  get current_timecode() {
    if (this.hasQcElementOutlet) {
      return this.qcElementOutlet.current_timecode
    }
  }

  async submit(event) {
    const c = this
    c.disableSubmitButton()
    const form = this.modalTarget
    const formData = new FormData(form)

    try {
      const response = await post(form.action, {
        body: formData,
        responseKind: "json"
      })
      
      if (response.ok) {
        const qc_event = await response.json
        if (c.hasQcElementOutlet) { 
          c.qcElementOutlet.addQcEvent({ detail: { result: qc_event }})
        }
        c.close()
      } else {
        console.error("Event submit request failed", response)
      } 
    } catch(error) {
      console.error(error)
    } finally {
      c.enableSubmitButton()
      c.dispatch("submit-end", { target: event.target })
    }
  }

  submitEnd(event) {
  }

  close() {
    $(this.element).modal('hide')
  }
  
}
