document.addEventListener("turbo:load", function() {
  if ($("#dfl_status").length > 0) {
    dfl_statuses.init();
  }

  if ($("#hls_test").length > 0) {
    $('.ui.dropdown').dropdown()
    $('#hls_source_select').on('change', function() {
      const player = videojs('hls-video', {
        html5: {
          hls: {
            overrideNative: true
          }
        }
      })
      player.src([{type: "application/x-mpegURL", src: this.value}])
    })
  }
})

var dfl_statuses = {
  init() {
    dfl_statuses.init_index()
    dfl_statuses.init_player()
  },

  init_index() {
    $('#dfl_status .dropdown').dropdown({ placeholder: false });
    calendar.datepicker(".creation_date_start", { position: 'bottom left', lastResort: 'bottom left' } )
    calendar.datepicker(".creation_date_end", { position: 'bottom right', lastResort: 'bottom right' } )
    dfl_statuses.init_status_modal()
  },

  init_status_modal() {
    $('.set.dfl.status.item').on('click', function() {
      $.ajax({
        url: "/dfl_statuses/status_modal",
        type: "GET",
      }).done(function(data) {
        $("body").append(data);
        $('.status-dfl.modal').modal({
          observeChanges: true,
          autofocus: false,
          onShow() {
            $('#status').dropdown({
              onChange(value, _text, _$choice) {
                if (value === "4") {
                  $('.restart.workflow.checkbox').checkbox('check')
                  $('.restart.workflow.checkbox').show()
                } else {
                  $('.restart.workflow.checkbox').checkbox('uncheck')
                  $('.restart.workflow.checkbox').hide()
                }
                $('.disabled.send-status').removeClass('disabled');
                $(".jobs-search-count").text($("#search-count").text());
                $('.modal.status-dfl .message').show();
                dfl_statuses.init_send_status();
              }
            });
          }, 
          onHidden() {
            $('.status-dfl.modal').remove();
          }
        }).modal("show");

      }).fail(() => console.log("error"));
    });
  },

  init_send_status() {
    $('.ui.green.send-status').on('click', function(event) {
      const button = $(this);
      button.addClass("loading");
      const search_params = search.collect_search("only_ids")
      search_params["only_ids"] = true
      $.ajax({
        url: "/dfl_statuses",
        type: "GET",
        dataType: "json",
        data: search_params
      }).done(function(data) {
        $.ajax({
          url: "/dfl_statuses/status",
          type: "POST",
          dataType: "json",
          data: {
            model_ids: data,
            status: $('#status').val(),
            message: $('#message').val(),
            restart_workflows: $('.restart.workflow.checkbox').checkbox('is checked')
          }
        }).done(function(_data) {
          button.removeClass("loading"); 
          $('.status-dfl.modal').modal('hide')
          window.location.reload()
        }).fail(() => console.log("error"));
      });
      event.preventDefault()
    });
  },

  init_player() {
    videojs('hls-video', {
      html5: {
        hls: {
          overrideNative: true
        }
      }
    })
  }
}

window.dfl_statuses = dfl_statuses;
