document.addEventListener("turbo:load", function() {
  if ($("#workflow_job_queues").length > 0) {
    return workflow_job_queues.init();
  }
});

var workflow_job_queues = {
  init() {
    $('#workflow_job_queues .with-tooltip').popup({ inline: true })

    workflow_job_queues.check_sdk_status()

    $('.queue_overview.item').on('click', function() {
      $('#workflow_job_queues .ui.dimmer').addClass('active')
      $('.queue.item').removeClass("active")
      $(this).removeClass("grey").addClass("active blue")
      return workflow_job_queues.get_queue_overview()
    })

    $('.show.queue.item').on('click', function() {
      $('#workflow_job_queues .ui.dimmer').addClass('active')
      $('.queue_overview.item').removeClass("active blue").addClass('grey')
      $('.queue.item').removeClass("active")
      $(this).addClass("active")
      return workflow_job_queues.get_queue($(this))
    })

    if ($('.queue.item.active').length == 0) {
      $('.queue_overview.item').trigger("click")
    } else {
      $('.queue.item.active').trigger("click")
    }
    
    $('.global.queue.setting.button').on('click',() => workflow_job_queues.show_global_queue_modal())

    $('.queue.item .setting.icon').hide()

    $('.queue.item').off('mouseenter', 'mouseleave')
    $('.queue.item').on('mouseenter', function() {
      $(this).find('.setting.icon').show()
    }).on('mouseleave', function() {
      $(this).find('.setting.icon').hide()
    })

    $('.queue.item').find('.setting.icon').on('click',function() {
      workflow_job_queues.show_single_queue_modal($(this).parent())
    })
    
    $('.create.queue.item').on('click',() => workflow_job_queues.show_create_queue_modal())
  },

  get_queue_overview() {
    return $.ajax({
      url: '/workflow_job_queues/queue_overview',
      type: 'GET'
    }).done((data, _textStatus, _xhr) => {
      $('#workflow_job_queues').html(data)
      $('#workflow_job_queues .ui.dimmer').removeClass('active')

      window.history.pushState(null, `${$("title").text()}`, `/workflow_job_queues`);
    }).fail((_jqXHR, _textStatus, errorThrown) => {
      console.log(errorThrown)
    })
  },

  get_queue(queue) {
    const queue_name = queue.data('queue-name');
    const queue_uuid = queue.data('queue-uuid');
    return $.ajax({
      url: `/workflow_job_queues/${queue_uuid}/queue`,
      type: "GET",
      success(data, _textStatus, _xhr) {
        $('#workflow_job_queues').html(data)
        $('#workflow_job_queues .ui.dimmer').removeClass('active');

        window.history.pushState(null, `${$("title").text()} - ${queue_name}`, `/workflow_job_queues?tab=${queue_uuid}`);
      },
      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown);
      }
    });
  },
  
  show_create_queue_modal() {
    return $.ajax({
      url: "/workflow_job_queues/new/queue_settings",
      type: "GET",
      success(data, _textStatus, _xhr) {
        $("body").append(data);
        return $('.ui.single.queue-settings.modal').modal({
          onApprove() {
            $.ajax({
              url: "/workflow_job_queues",
              type: 'POST',
              dataType: 'json',
              data: workflow_job_queues.queue_data_fields(),
              success(_data, _textStatus) {
                return window.location.reload();
              },
              error(_jqXHR, _textStatus, errorThrown) {
                return console.log(errorThrown);
              }
            });
          },
          onHidden() {
            $('.ui.single.queue-settings.modal').remove();
          },
          onShow() {
            $(this).find('.dropdown').dropdown();
          }
        }).modal('show');
      },
        
      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown);
      }
    });
  },
  
  show_single_queue_modal(queue) {
    const queue_data = queue.data();
    return $.ajax({
      url: `/workflow_job_queues/${queue_data.queueUuid}/queue_settings`,
      type: "GET",
      success(data, _textStatus, _xhr) {
        $("body").append(data);
        $('.ui.single.queue-settings.modal').modal({
          onApprove() {
            $.ajax({
              url: `/workflow_job_queues/${queue_data.queueUuid}`,
              type: 'PATCH',
              dataType: 'json',
              data: workflow_job_queues.queue_data_fields(),
              success(_data, _textStatus) {
                return window.location.reload();
              },
              error(_jqXHR, _textStatus, errorThrown) {
                return console.log(errorThrown);
              }
            });
          },
          onHidden() {
            $('.ui.single.queue-settings.modal').remove();
          },
          onShow() {
            $(this).find('.queue.name.header').html($(queue).data('labelName') + " Queue Settings");
            $(this).find('.dropdown').dropdown();
            $(this).find('.checkbox').checkbox();
          }
        }).modal('show');
        return $('.ui.single.queue-settings.modal').find('.delete.button').on('click', () =>
          $.ajax({
            url: `/workflow_job_queues/${queue_data.queueUuid}`,
            type: 'DELETE',
            success(_data, _textStatus) {
              return window.location.reload();
            },
            error(_jqXHR, _textStatus, errorThrown) {
              return console.log(errorThrown);
            }
          })
        );
      },
        
      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown);
      }
    });
  },

  show_global_queue_modal() {
    $.ajax({
      url: "/workflow_job_queues/global_queue_settings",
      type: "GET",
      success(data, _textStatus, _xhr) {
        $("body").append(data);
        $('.ui.global.queue-settings.modal').modal({
          onApprove() {
            $.ajax({
              url: "/workflow_job_queues/global",
              type: 'PATCH',
              dataType: 'json',
              data: {
                active: $('#queue_setting_queue_active').prop('checked'),
                interval_time: $('#queue_setting_interval_time').val(),
                interval_jobs: $('#queue_setting_interval_jobs').val(),
                vantage_sdk_production_url: $('#vantage_sdk_production_url').val(),
                vantage_sdk_lab_url: $('#vantage_sdk_lab_url').val()
              }
            }).done(function() {
              window.location.reload()
            })
          },
          onHidden() {
            $('.ui.global.queue-settings.modal').remove();
          }
        }).modal('show');
      },
      error(_jqXHR, _textStatus, errorThrown) {
        console.log(errorThrown);
      }
    });
  },
          

  queue_data_fields() {
    const data = {
      max_inprogress: $('#queue_setting_max').val(),
      high: $('#queue_setting_high').val(),
      mid: $('#queue_setting_mid').val(),
      low: $('#queue_setting_low').val(),
      queue_name: $('#queue_setting_name').val(),
      active: $('#queue_setting_active').prop('checked'),
      filter: {
        workflow_type: $('#queue_filter_workflow_type').val(),
        client: {
          exclude: $('#queue_filter_client_exclude').val(),
          include: $('#queue_filter_client_include').val()
        },
        workflow_job_template: {
          exclude: $('#queue_filter_workflow_job_template_exclude').val(),
          include: $('#queue_filter_workflow_job_template_include').val()
        }
      }
    };
    return data;
  },

  check_sdk_status() {
    $.ajax({
      url: '/api/vantage/sdk_status',
      type: 'GET',
      dataType: "json"
    }).done(function(data) {
      if (data.Online === true) {
        $('.status.icon').removeClass('offline').addClass('online')
      } else {
        $('.status.icon').removeClass('online').addClass('offline')
      }
    }).fail(function() {
      $('.status.icon').removeClass('online').addClass('offline')
    })
  }
};

window.workflow_job_queues = workflow_job_queues;
