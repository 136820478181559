document.addEventListener("turbo:load", () => charts.init());

var charts = {
  init() {
    try { return charts.highcharts_init(); }
    catch (e) { return console.log(e); }
  },

  highcharts_init() {
    return Highcharts.setOptions({
      colors: ['#a30fd6', '#3ea6f5', '#ffde2f', '#00ffa7', '#FF1493', '#FE9A76', '#a8a8a8', '#ff6fff', '#00cb67', '#000000', '#c40000', '#1ad81a', '#e23c7a'],
      legend: {
        borderRadius: 0,
        borderWidth: 0,
        itemStyle: {
          'color': '#8c8c8c',
          'cursor': 'pointer',
          'fontSize': '12px',
          'fontWeight': 'normal'
        },
        itemHoverStyle: {
          'color': '#5f5f5f',
          'cursor': 'pointer',
          'fontSize': '12px',
          'fontWeight': 'normal'
        },
        itemMarginTop: 1,
        itemMarginBottom: 1,
        enabled: false
      },
      global: {
        useUTC: false
      },
      accessibility: {
        enabled: false
      },
      title: { 
        text: null,
        style: {
          'color': '#5f5f5f',
          'fontSize': '10px'
        }
      },
      subtitle: {
        align: 'center',
        style: {
          'color': '#8c8c8c',
          'fontSize': '10px'
        }
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        borderRadius: 0,
        backgroundColor: null,
        textColor: '#00FFFF'
      },
      plotOptions: {
        areaspline: {
          marker: {
            enabled: false,
            radius: 2
          },
          lineWidth: 1,
          states: { hover: {lineWidth: 1}
        },
          threshold: null
        },
        area: {
          marker: {
            enabled: false
          }
        },
        line: {

        }
      },
      credits: {
        enabled: false
      }
    })
  },

  dashboard_project_chart(div, data) {
    return Highcharts.chart(div, {
      series: data,
      title: 'Projects',
      chart: {
        type: 'areaspline',
        zoomType: 'x'
      },
      xAxis: {
        type: 'datetime'
      },
      yAxis: {
        allowDecimals: false,
        title: { text: 'Projects'
      },
        min: 0
      }
    }
    );
  },

  asset_user_history_chart(div, data) {
    for (let i = 0; i < data.length; i++) {
      const entry = data[i];
      entry["color"] = charts.gradient_color(i);
    }
    return Highcharts.chart(div, {
      series: data,
      title: 'Asset User History',
      chart: {
        type: 'areaspline',
        zoomType: 'x'
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          month: '%e. %b',
          year: '%b'
        }
      },
      yAxis: {
        allowDecimals: false,
        title: { text: 'Assets'
      },
        min: 0
      }
    }
    );
  },

  workflow_job_chart(div, data) {
    for (let i = 0; i < data.length; i++) {
      const entry = data[i];
      entry["color"] = charts.gradient_color(i);
    }
    return Highcharts.chart(div, {
      series: data,
      title: 'Workflow Job',
      chart: {
        type: 'areaspline',
        zoomType: 'x'
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          month: '%e. %b',
          year: '%b'
        }
      },
      yAxis: {
        allowDecimals: false,
        title: { text: 'Workflows executed'
      },
        min: 0
      }
    }
    );
  },

  dfl_job_chart(div, data) {
    for (let i = 0; i < data.length; i++) {
      const entry = data[i];
      entry["color"] = charts.gradient_color(i);
    }
    return Highcharts.chart(div, {
      series: data,
      title: 'DFL Job',
      chart: {
        type: 'areaspline',
        zoomType: 'x'
      },
      legend: { enabled: false
    },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          month: '%e. %b',
          year: '%b'
        }
      },
      yAxis: {
        allowDecimals: false,
        title: { text: 'DFL jobs created'
      },
        min: 0
      }
    }
    );
  },
      
  transfer_chart(div, data) {
    return Highcharts.chart(div, {
      series: data,
      title: 'Transfer History',
      chart: {
        type: 'column'
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          month: '%e. %b',
          year: '%b'
        }
      },
      yAxis: {
        allowDecimals: false,
        title: { text: 'Transfers per day'
      }
      }
    }
    );
  },

  asset_storage_usage_chart(div, data) {
    Highcharts.chart(div, {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        margin: [0, 0, 0, 0],
        spacingTop: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        spacingRight: 0
      },
      credits: {
        text: ' ',
        href: 'http://www.imagion.de'
      },
      navigation: { buttonOptions: {
        align: 'left',
        verticalAlign: 'top',
        x: 0,
        y: -4
      }
    },
      exporting: { enabled: false
    },
      title: { text: ''
    },
      subtitle: { text: ''
    },
      tooltip: {
        formatter() {
            return `<b>${this.point.name}</b>: ${charts.format_bytes(this.point.y)}`;
          }
      },
      plotOptions: { pie: {
        size: '100%',
        dataLabels: {
          enabled: true,
          distance: +7,
          formatter() {
            return `<b>${this.point.name}</b>: ${charts.format_bytes(this.point.y)}`;
          },
          style: {
            fontWeight: 'normal',
            color: '#333',
            textShadow: '0px 1px 2px white'
          }
        },
        startAngle: -90,
        endAngle: 90,
        center: [
          '50%',
          '75%'
        ]
      }
    },
      series: [ {
        type: 'pie',
        innerSize: '45%',
        data: data['chart_data']
      } ]
    });
  },

  asset_requests_chart(div, _data) {
    Highcharts.chart(div, {
      "chart": {
        "type": "line",
      },
      "title": {
        "text": null
      },
      "xAxis": {
        "tickInterval": 2,
        "categories": [
          "2021-01-19",
          "2021-01-22",
          "2021-01-26",
          "2021-01-28",
          "2021-01-29",
          "2021-02-02",
          "2021-02-09",
          "2021-02-10"
        ],
        "labels": {
          "staggerLines": 2,
          "style": {
            "color": "#8c8c8c"
          }
        }
      },
      "yAxis": {
        "min": 0,
        "title": {
          "text": null,
          "style": {
            "color": "#8c8c8c"
          }
        },
        "labels": {
          "style": {
            "color": "#8c8c8c"
          }
        }
      },
      "plotOptions": {
        "column": {
          "pointPadding": 0.2,
          "borderWidth": 0
        }
      },
      "series": [
        {
          "name": "Assets",
          "data": [
            11,
            20,
            16,
            11,
            22,
            7,
            6,
            6
          ],
          "_colorIndex": 1,
          "_symbolIndex": 0
        }
      ],
      "legend": {
        "enabled": false
      }
    });
  },

  asset_request_delivery_numbers(div, data) {
    return Highcharts.chart(div, {
      chart: {
        type: 'column',
        zoomType: 'x'
      },
      title: {
        text: 'Delivery Numbers',
        align: 'center',
        style: {
          fontSize: '17px',
          fontWeight: 600,
        }
      },
      subtitle: {
        text: document.ontouchstart === undefined ?
            'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
        align: 'center',
        style: {
          fontSize: '14px' 
        }
      },
      tooltip: {
        formatter: function() {
          return `
            <b>${this.series.name} ${this.y}</b>
            <br>
            ${Highcharts.dateFormat('%e %B %Y', new Date(this.x))}
          `
        },
      },
      xAxis: {
        type: 'datetime',
        minRange: 14 * 24 * 3600000,
        // dateTimeLabelFormats: {
        //   day: "%e. %b",
        //   month: "%b '%y",
        //   year: "%Y"
        // },
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
      },
      yAxis: {
        title: {
          text: ''
        },
        labels: {
          enabled: false
        }
      },
      plotOptions: {
        series: {
            grouping: false,
            borderWidth: 0
        }
      },
      series: data,
    }
    );
  },

  asset_needed_chart(div, data) {
    if (data.data.length == 0) {
      data.categories = ["No assets needed in the next 30 days"]
    }
    Highcharts.chart(div, {
      chart: {
        type: "line",
        height: "200px",
      },
      xAxis: {
        min: data.data.length == 0 ? 0 : undefined,
        max: data.data.length == 0 ? 0 : undefined,
        categories: data.categories,
        labels: {
          staggerLines: 2
        }
      },
      yAxis: {
        min: 0,
        max: data.data.length == 0 ? 5 : undefined,
        title: null
      },
      series: [
        {
          name: "Assets",
          data: data.data,
          _colorIndex: 1
        }
      ]
    })
  },

  asset_per_day_chart(div, data) {
    Highcharts.chart(div, {
      chart: {
        type: "solidgauge"
      },
      pane: {
        center: ["50%", "75%"],
        size: "100%",
        startAngle: -90,
        endAngle: 90,
        background: {
          innerRadius: "60%",
          outerRadius: "100%",
          shape: "arc"
        }
      },
      yAxis: {
        stops: [[0.1, "#55BF3B"], [0.25, "#F28627"], [0.5, "#F51414"]],
        lineWidth: 0,
        minorTickInterval: null,
        title: {
          y: 0
        },
        labels: {
          y: 16
        },
        min: 0,
        max: 100,
        tickInterval: 100
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true
          }
        }
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: "Assets needed",
          data: data.data,
          dataLabels: {
            format: '<div style="text-align:center; margin-top: -22px">' +
            '<span style="font-size:17px">Ø {y}</span><br>' +
            // '<span style="font-size:12px;opacity:0.4">Ø Assets</span>' +
            '</div>'
          }
        }
      ]
    })
  },

  redelivery_rate_chart(div, data) {
    Highcharts.chart(div, {
      chart: {
        type: "pie",
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true
          }
        }
      },
      series: [{
        colorByPoint: true,
        data: data.data
      }]
    })
  },

  storage_usage_history_chart(div, data) {
    return Highcharts.chart(div, {
      series: data,
      title: 'Storage Usage',
      chart: {
        type: 'area',
        zoomType: 'x'
      },
      tooltip: {
        shared: true,
        pointFormat: '{series.name}: <b>{point.human}</b><br>'
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          month: '%e. %b',
          year: '%b'
        }
      },
      yAxis: {
        title: {
          text: ''
        },
        labels: {
          enabled: false
        }
      },
      plotOptions: {
        area: {
          stacking: 'normal'
        },
        series: {
          lineWidth: 0
        }
      }
    }
    );
  },

  storage_usage_location_chart(div, data) {
    return Highcharts.chart(div, {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: false,
      tooltip: { pointFormat: '{series.name}: <b>{point.human}</b>'
    },
      plotOptions: { pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: { enabled: false
      },
        showInLegend: true
      }
    },
      series: [{
        name: 'Storage Space Occupation',
        colorByPoint: true,
        data
      }]
    });
  },

  storage_usage_asset_type_chart(div, data) {
    return Highcharts.chart(div, {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: false,
      tooltip: { pointFormat: '{series.name}: <b>{point.human}</b>'
    },
      plotOptions: { pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: { enabled: false
      },
        showInLegend: true
      }
    },
      series: [{
        name: 'Storage Space Occupation',
        colorByPoint: true,
        data
      }]
    });
  },

  notification_chart(div, data) {
    return Highcharts.chart(div, {
      chart: {
          type: 'areaspline',
          zoomType: 'x'
      },
      title: {
          text: 'Notification History'
      },
      xAxis: {
          type: 'datetime'
      },
      yAxis: {
          min: 0,
          softMax: 1,
          title: {
              text: 'Notifications'
          }
      },
      legend: {
          enabled: true
      },
      plotOptions: {
          areaspline: {
              // fillColor: {
              //     linearGradient: {
              //         x1: 0,
              //         y1: 0,
              //         x2: 0,
              //         y2: 1
              //     },
              //     stops: [
              //         [0, Highcharts.getOptions().colors[0]],
              //         [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
              //     ]
              // },
              marker: {
                  radius: 1
              },
              lineWidth: 1,
              states: {
                  hover: {
                      lineWidth: 1
                  }
              },
              threshold: null
          }
      },
  
      series: data
    });
  },

  api_call_metrics_chart(div, data) {
    Highcharts.chart(div, {
      chart: {
          type: 'area'
      },
      title: {
          text: 'API Call duration'
      },
      yAxis: {
          title: {
              text: 'Duration in seconds'
          },
          labels: {
              formatter: function () {
                  return this.value / 1000 + 's';
              }
          }
      },
      series: [{
        data: data
      }]
    })
  },
  
  vantage_metrics_chart(div) {
    Highcharts.chart(div, {
      chart: {
        legend: {
          enabled: true,    
        },
        type: 'spline',
        scrollablePlotArea: {
          scrollPositionX: 1
        },
        events: {
          load: function() {
            let chart = this
            async function subscribe_to_vantage_metrics() {
              let response = await fetch("/api/vantage/metrics")
              if (!jQuery.contains(document, div)) { return }           // div removed from page
              if (response.status == 502) {                             // connection timeout reconnect
                await subscribe_to_vantage_metrics()
              } else if (response.status != 200) {
                console.log(response.statusText)
                if (response.status == 404) { return }                  // 404 quit polling
                await new Promise(resolve => setTimeout(resolve, 5000)) // 5 sec reconnect
                await subscribe_to_vantage_metrics()
              } else {
                let data = await response.json()
                let cpu_series = chart.series[0]
                let transcode_series = chart.series[1]
                let analysis_series = chart.series[2]
                // let edit_series = chart.series[3]
                let multiscreen_series = chart.series[4]
                let iptv_vod_series = chart.series[5]

                transcode_series.setData(data.transcode, true, false, true)
                analysis_series.setData(data.analysis, true, false, true)
                // edit_series.setData(data.edit, true, false, true)
                multiscreen_series.setData(data.multiscreen, true, false, true)
                iptv_vod_series.setData(data.iptv_vod, true, false, true)
                cpu_series.setData(data.cpu, true, false, true)

                let label = $('.vantage-status.label .detail.status')

                label.removeClass('online offline').addClass(data.status)
                
                await new Promise(resolve => setTimeout(resolve, 5000)) // 5 sec polling
                await subscribe_to_vantage_metrics()
              }
            }
            subscribe_to_vantage_metrics()
          }
        }
      },
      xAxis: {
        type: 'integer',
        labels: {
          enabled: false
        },
        min: 0,
        max: 30,
      },
      yAxis: {
        title: {
          text: 'Load (%)'
        },
        min: 0,
        softMax: 100,
        minorGridLineWidth: 0,
        gridLineWidth: 1,
        alternateGridColor: null,
      },
      tooltip: {
        valueSuffix: ' %',
        distance: 30,
        padding: 5,
        useHTML: true,
        formatter: function() {
          return [''].concat(
            this.points ?
              this.points.map(function (point) {
                return `<span style="color: ${point.color}">•</span> ${point.series.name}: ${point.y} %`
              }) : []
          )
        },
        split: true,
      },
      plotOptions: {
        spline: {
          lineWidth: 2,
          states: {
            hover: {
              lineWidth: 2
            }
          },
          marker: {
            enabled: false
          },
        }
      },
      series: [
        {
          name: 'CPU',
          data: [],
          type: 'areaspline',
          color: '#AAAAAA55',
          showInLegend: true,
        }, {
          name: 'TRANSCODE',
          data: [],
          showInLegend: true,
        }, {
          name: 'ANALYSIS',
          data: [],
          showInLegend: true,
        }, {
          name: 'EDIT',
          data: [],
          showInLegend: true,
        }, {
          name: 'MULTISCREEN',
          data: [],
          showInLegend: true,
        }, {
          name: 'IPTV VOD',
          data: [],
          showInLegend: true,
        }
      ],
    })
  },
  
  format_bytes(a, b) {
    if (0 === a) {
      return '0 Bytes';
    }
    const c = 1024;
    const d = b || 2;
    const e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const f = Math.floor(Math.log(a) / Math.log(c));
    return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f];
  },

  gradient_color(index) {
    return {
      linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1
      },
      stops: [
          [0, Highcharts.Color(Highcharts.getOptions().colors[index]).get('rgba')],
          [1, Highcharts.Color(Highcharts.getOptions().colors[index]).setOpacity(0.3).get('rgba')]
      ]
    };
  }
};

window.charts = charts;
