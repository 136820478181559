document.addEventListener("turbo:load", function() {
  if ($("#api_call_metrics").length > 0) {
    calendar.datepicker(".begin_date_start", { position: 'bottom left', lastResort: 'bottom left' } )
    calendar.datepicker(".begin_date_end", { position: 'bottom right', lastResort: 'bottom right' } )
    calendar.datepicker(".end_date_start", { position: 'bottom left', lastResort: 'bottom left' } )
    calendar.datepicker(".end_date_end", { position: 'bottom right', lastResort: 'bottom right' } )
    
    $.ajax({
      url: "/charts/api_call_metrics",
      type: "GET",
      dataType: "json"
    }).done(function(data) {
      charts.api_call_metrics_chart('api-call-chart', data)
    })
  }
});
