import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    "errorMessage",
    "datamoverSource",
    "datamoverDestination",
    "transferProfileCheckbox",
    "transferProfile"
  ]

  static values = {
    paramsId: String,
    isMultiAssetWorkflow: Boolean
  }

  connect() {
  }

  validate() {
    const validator = { is_valid: true, messages: [] }
    const error_message = $(this.errorMessageTarget)
    error_message.removeClass("visible").addClass('hidden').html("")
    $(':input[type=submit]').prop('disabled', false)

    this.validate_locations(validator)

    if (!validator.is_valid) {
      for (let message of Array.from(validator.messages)) {
        error_message.append(`<p>${message}</p>`)
      }
      error_message.removeClass('hidden').addClass("visible")
      $(':input[type=submit]').prop('disabled', true)
    }
  }

  validate_locations(validator) {
    const source = $(this.datamoverSourceTarget)
    const destination = $(this.datamoverDestinationTarget)

    if (!source.val().trim() && !this.isMultiAssetWorkflowValue) {
      validator.is_valid = false
      validator.messages.push("Source path is empty")
    }
    if (!destination.val().trim()) {
      validator.is_valid = false
      validator.messages.push("Destination path is empty")
    }
  }

  updateTransferProfile() {
    if (this.hasTransferProfileTarget) {
      const transfer_profile = this.transferProfileCheckboxTargets.find(t => t.checked).value || "3"
      $(this.transferProfileTarget).val(transfer_profile).trigger('change')
    }
  }
}