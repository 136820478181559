document.addEventListener("turbo:load", function() {
  document.querySelector('#users.index') && users.index()
  document.querySelector('#users.edit') && users.edit()
});

var users = {
  index() {
    calendar.datepicker(".search.bar")
    users.activity()
  },

  edit() {
    users.action_bar()
    users.init_search_filter()
    users.init_dropdowns()

    users.api_keys()
  },

  init_checkboxes() {
    users.tfa_checkbox()

    $('.state.fields .checkbox input[type=checkbox]').checkbox({
      onChange: function() {
        users.check_welcome_mail()
      }
    })
  },

  action_bar() {
    users.permissions()
    users.auth_policies()
    users.set_password()
  },

  check_welcome_mail() {
    if ($('#user_active').parent().checkbox('is checked') && $('#user_approved').parent().checkbox('is checked') && $('#user_confirmed').parent().checkbox('is checked')) {
      $('.send.email.info.message').show()
      $('.send.email.info.message .checkbox').checkbox()
    } else {
      $('.send.email.info.message').hide()
    }
  },

  activity() {
    $(".activity-check.button").on("click", function() {
      $(".activity-check.button").addClass("loading")
      $.ajax({
        url: `/users/activity_modal`,
        complete() {
          $(".activity-check.button").removeClass("loading")
        },
        success(data, _textStatus, _xhr) {
          $("body").append(data)
          const modal = $('.activity.modal')
  
          modal.modal({
            onShow() {
              
            },
            onHidden() {
              modal.remove()
            }
          }).modal("show")
        },
        error(_jqXHR, _textStatus, errorThrown) {
          return console.log(errorThrown)
        }
      })
    })
  },

  tfa_checkbox() {
    const tfa_checkbox = $(".tfa.checkbox")
    const tfa_loader = tfa_checkbox.find("label div")
    const manage_devices_modal = document.getElementById("manage_devices_modal")

    tfa_checkbox.checkbox({
      onChecked: function() {
        //console.log("checked")
        tfa_loader.addClass("inline loader")
        users.add_second_factor()
        $(".manage.devices.button").show()
        manage_devices_modal.reload()
      },
      onUnchecked: function() {
        //console.log("unchecked")
        tfa_loader.addClass("inline loader")
        users.remove_second_factor()
        $(".manage.devices.button").hide()
        manage_devices_modal.reload()
      },
    })

    // if (tfa_checkbox.checkbox("is checked")) {
    //   $(".manage.devices.button").show()
    // } else {
    //   $(".manage.devices.button").hide()
    // }
  },

  add_second_factor() {
    const tfa_loader = $(".tfa.checkbox label div")
    $.ajax({
      url: `/users/${$(".tfa.checkbox").data("user-id")}/second_factor_modal`,
      complete() {
        tfa_loader.removeClass("inline loader")
      },
      success(data, _textStatus, _xhr) {
        $("body").append(data)
        const modal = $('.second.factor.modal')
        const model_id = $(modal).data("model-id")
        const user_id = $("#users").data('model-id')
        const manage_devices_modal = document.getElementById("manage_devices_modal")

        modal.modal({
          closable: false,
          onShow() {
            const activate = $(modal).find(".activate.button")
            activate.on("click", function() {
              activate.addClass("loading")
              $.ajax({
                url: `/users/${user_id}/enable_second_factor`,
                type: "POST",
                data: {
                  model_id: model_id,
                  otp: $("#otp-code-field").val()
                },
                success(data, _textStatus, _xhr) {
                  $(modal).find(".error-message").hide()
                  activate.removeClass("loading")
                  activate.hide()
                  $(modal).find(".backup-codes").show()
                  $(modal).find(".qc-step").remove()
                  $(modal).find(".cancel.button").remove()
                  $(modal).find(".approve.button").show()

                  document.getElementById("info_frame").reload()
                },
                error(jqXHR, _textStatus, errorThrown) {
                  activate.removeClass("loading")
                  $(modal).find(".error-message").show()

                  console.log(jqXHR.status)
                  //let error = jqXHR.status
                  return console.log(`Error: ${errorThrown}`)
                }
              })
            })
          },
          onHidden() {
            modal.remove()
            users.api_keys()
            manage_devices_modal.reload()
          },
          onApprove(_element) {
            users.api_keys()
          },
          onDeny(_element) {
            $.ajax({
              url: `/users/${user_id}/second_factor`,
              type: 'DELETE',
              dataType: 'json',
              data: {
                model_id: model_id
              }
            }) 
          }
        }).modal("show");
      },
      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown)
      }
    })
  },

  remove_second_factor() {
    const tfa_loader = $(".tfa.checkbox label div")
    $.ajax({
      url: `/users/${$(".tfa.checkbox").data("user-id")}/remove_second_factors_modal`,
      complete() {
        tfa_loader.removeClass("inline loader")
      },
      success(data, _textStatus, _xhr) {
        $("body").append(data)
        const modal = $('.second.factor.modal')
        modal.modal({
          closable: false,
          onShow() {
            
          },
          onHidden() {
            modal.remove()
            users.api_keys()
          },
          onApprove(_element) {
            $.ajax({
              url: `/users/${$(".tfa.checkbox").data("user-id")}/remove_second_factors`,
              type: "POST",
            }).done(function() {
              document.getElementById("info_frame").reload()
              document.getElementById("manage_devices_modal").reload()
            })
            $(".tfa.checkbox").checkbox("set unchecked")
            //$(".manage.devices.button").hide()
            
          },
          onDeny(_element) {
            $(".tfa.checkbox").checkbox("set checked")
            $(".manage.devices.button").show()
          }
        }).modal("show");
      },
      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown)
      }
    })
  },

  settings(key, value) {
    return post(`/users/${$("#current_user_id").val()}/settings`, {
      body: {
        settings: JSON.stringify({ key: key, value: value }) 
      }
    })
  },

  index_menu() {
    $(".item.auth.policies").off('click')
    $(".item.auth.policies").on('click', function () {
      $.ajax("/users/policies")
      .done((data) => {
        $("body").append(data)
        let modal = $('.policies.modal')
        modal.modal({
          autofocus: false,
          onShow() {
            modal.find(".ui.dropdown").dropdown()

            const element_count = $(".select.index.elements").data("selected").length

            if (element_count > 1) {
              modal.find('.search-count').text(element_count)
            } else {
              modal.find('.warning.message').hide()
            }

            let button = modal.find('.ui.set-value.button')
            button.on("click", function () {
              button.addClass("loading");
              $.ajax({
                url: "/users/policies",
                type: "POST",
                dataType: "json",
                data: {
                  model_ids: index_select.selected_ids(),
                  users_params: general.collect_parameter('.set.policies.value.modal')
                }
              }).done(function (_data) {
                button.removeClass("loading");
                if ($(".select.index.elements").data("active")) {
                  index_select.end_selection()
                }
                modal.modal('hide')
                //$(".start-search .primary.button").trigger("click")
              }).fail(() => button.removeClass('loading').removeClass('loading green').addClass('red').text("Error"));
            });
            
          },
          onHidden() {
            modal.remove()
          },
          onApprove() {

          }
        }).modal("show")
      })
    })
  },

  permissions() {
    $(".permissions.button").off("click")
    $(".permissions.button").on("click", function() {
    $(".permissions.button").addClass("loading")
      $.ajax({
        url: `/users/${$(".permissions.button").data("user-id")}/permissions`,
        type: "GET",
        success(data, _textStatus, _xhr) {
          $(".permissions.button").removeClass("loading")
          $("body").append(data);
          $('.permissions.modal').modal({
            autofocus: false,
            onShow() {
              users.permissions_dropdown()

              $('th.selectable').on("dblclick", function() {
                users.select_all_for_role($(this).attr("data-value"));
              })

              $(".update.permissions.button").on("click", function() {
                $(".permissions.input").trigger("click")
                $(".permissions.modal").modal("hide")
              })
            },
            onHidden() {
              $(".permissions.modal").remove();
            }
          }).modal("show");
        },
        error(_jqXHR, _textStatus, errorThrown) {
          return console.log(errorThrown);
        }
      })
    })
  },

  permissions_dropdown() { 
    $('.permissions.modal .ui.dropdown').not(".pointing").dropdown({
      onChange(value, _text, _$choice) {
        let permissions = "";
        $(".permission.dropdown").each(function() {
          let authorizable_type = $(this).parent().attr("authorizable_type")
          if (authorizable_type == "") { authorizable_type = null } else { authorizable_type = '"' + authorizable_type + '"' }
          let roles = $(this).dropdown("get values")
          roles.forEach(function(currentValue, index, arr) {
            const role = '{"name":"' + currentValue +'","authorizable_type":' + authorizable_type +',"authorizable_id":null}'; 
            permissions = permissions + role + ",";
          })
        })

        permissions = "[" + permissions + "]";
        $("#user_permissions").val(permissions.replace(/,(?=[^,]*$)/, ''));
      }
    })

    $('.permissions.modal .ui.dropdown.pointing').dropdown({
      placeholder: false,
      action: "hide",
      onChange(value, _text, _$choice) {
        $('.permissions.modal .ui.dropdown.model').dropdown("remove selected", ["user", "manager", "admin"])
        $('.permissions.modal .ui.dropdown.model').dropdown("set selected", value)
      }
    })
  },

  auth_policies() {
    let button = $(".policies.button")
    button.off("click")
    button.on("click", function() {
      button.addClass("loading")
      $.ajax({
        url: `/users/${button.data("user-id")}/policies`,
        type: "GET",
        success(data, _textStatus, _xhr) {
          button.removeClass("loading")
          $("body").append(data);
          let modal = $('.policies.modal')
          modal.modal({
            autofocus: false,
            onShow() {
              modal.find(".ui.dropdown").dropdown()

              $(".update.policies.button").on("click", function() {
                $(".policies.input").trigger("click")
                $(".policies.modal").modal("hide")
              })
            },
            onHidden() {
              document.getElementById("info_frame").reload()
              modal.remove();
            }
          }).modal("show");
        },
        error(_jqXHR, _textStatus, errorThrown) {
          return console.log(errorThrown);
        }
      })
    })
  },

  set_password() {
    let button = $(".set.password.button")
    button.off("click")
    button.on("click", function() {
      button.addClass("loading")
      $.ajax({
        url: `/users/${button.data("user-id")}/set_password`,
        success(data, _textStatus, _xhr) {
          button.removeClass("loading")
          $("body").append(data);
          let modal = $('.set.password.modal')
          modal.modal({
            autofocus: false,
            onShow() {
              modal.find(".ui.dropdown").dropdown()

              $(".update.password.button").on("click", function() {
                $(".password.input").trigger("click")
                $(".password.modal").modal("hide")
              })
            },
            onHidden() {
              //document.getElementById("info_frame").reload()
              modal.remove();
            }
          }).modal("show");
        },
        error(_jqXHR, _textStatus, errorThrown) {
          return console.log(errorThrown);
        }
      })
    })
  },

  api_keys() {
    const user_id = $("#users").data('model-id')
    if (user_id == undefined || user_id == "") { return }
    return $.ajax({
      url: `/users/${user_id}/api_keys`,
      success(data, _textStatus) {
        $("#users .api.keys").html(data)
        users.api_keys_modal()

        $("#users .api.keys .corner.label").on("click", function() {
          const model_id = $(this).data("model-id")
          
          $(".revoke.api-key.modal").modal({
            allowMultiple: false,
            onApprove() {
              return $.ajax({
                url: `/users/${user_id}/api_key`,
                type: 'DELETE',
                dataType: 'json',
                data: {
                  model_id: model_id
                },
                success(_data, _textStatus) {
                  users.api_keys()
                }
              })
            }
          }).modal("show")
        })
      },
      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown);
      }
    });
  },

  api_keys_modal() {
    const user_id = $("#users").data('model-id')
    $("#users .gen-api-key.button").on("click", function() {
      $.ajax({
        url: `/users/${user_id}/api_keys_modal`,
        complete() {
          
        },
        success(data, _textStatus, _xhr) {
          $("body").append(data)
          const modal = $('.name.api.keys.modal')
          const key_modal = $('.gen.api.keys.modal')
          modal.modal({
            allowMultiple: false,
            closable: false,
            onShow() {
              document.querySelector('.name.api.keys.modal input').addEventListener('keyup', function() {
                if (modal.find("input").val().length > 2) {
                  modal.find(".blue.button").removeClass("disabled")
                } else {
                  modal.find(".blue.button").addClass("disabled")
                }
              })
            },
            onApprove(_element) {
              $.ajax({
                url: `/users/${user_id}/api_key`,
                type: "POST",
                data: {
                  description: modal.find("input").val()
                },
                success(data, _textStatus, _xhr) {
                  key_modal.find("pre").text(data.token)
                  users.api_keys()
                  key_modal.modal("show")
                }
              })
              return false
            },
            onDeny() {
              key_modal.remove()
            },
            onHidden() {
              modal.remove()
            }
          }).modal("show")

          key_modal.modal({
            closable: false,
            onHidden() {
              key_modal.remove()
            }
          })
        },
        error(_jqXHR, _textStatus, errorThrown) {
          return console.log(errorThrown)
        }
      })
    })
  },

  init_search_filter(path) {
    $('.not.active.item').on('click', function() {
      search.reset_search(path);
      $("#search_active").dropdown('set exactly', ['false']);
      search.perform_search(path);
    });
    $('.not.approved.item').on('click', function() {
      search.reset_search(path);
      $("#search_approved").dropdown('set exactly', ['false']);
      search.perform_search(path);
    });
    $('.not.confirmed.item').on('click', function() {
      search.reset_search(path);
      $("#search_confirmed").dropdown('set exactly', ['false']);
      search.perform_search(path);
    });
  },

  init_dropdowns() {
    $("#users.edit .dropdown").not('.remote').dropdown({ placeholder: false, fullTextSearch: true });
  },

  select_all_for_role(role) {
    $(`input:radio[value!='${role}']`).prop('checked', false);
    $(`input:radio[value='${role}']`).prop('checked', true);
    $(".permission.checkbox").change()
  }
};

window.users = users
