import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    general.fields_with_error()
    general.message_closable()
  }

  logoutUser() {
    Turbo.visit("/logout")
  }

  suBack() {
    Turbo.visit("/users/su_back")
  }
}
