import { Controller } from "@hotwired/stimulus"
import { imageSize } from 'image-size'
export default class extends Controller {
  static targets = ["form", "filePreview", "fileInput", "commonFields", "dropzone", "submit"]

  connect() {
  }

  async showPreview(event) {
    if (event.target.files.length > 0) {
      var _URL = window.URL || window.webkitURL
      const formData = new FormData()
      
      this.fileInputTarget.files = event.target.files

      this.filePreviewTarget.innerHTML = ""
      this.dropzoneTarget.style.display = "none"
  
      for (const [index, file] of [...event.target.files].entries()) {
        const template = document.getElementById("image-preview-template")
        const clone = template.content.cloneNode(true)
        let preview = clone.querySelector(".image-preview")
  
        this.updateIndex(preview, index)
        preview.querySelector(".header").textContent = file.name
        this.filePreviewTarget.appendChild(clone)

        const objectUrl = _URL.createObjectURL(file)
  
        await this.loadImage(file, objectUrl, preview)
        formData.append(`files[]`, file)
        _URL.revokeObjectURL(objectUrl)
      }
      this.submitTarget.classList.remove('disabled')
    }
  }

  async loadImage(file, objectUrl, preview) {
    const contoller = this
    return new Promise((resolve) => {
      const [filename, extension] = file.name.split(/\.(?=[^\.]+$)/)
      preview.querySelector('span.file-extension').textContent = extension
      preview.querySelector('.header').textContent = file.name
      preview.querySelector('input[type="hidden"].file-extension').value = extension
      preview.querySelector('input[type="hidden"].original-name').value = file.name

      if (file.name.includes(".psd")) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(file)
        reader.onload = function() {
          const buffer = new Uint8Array(reader.result)
          const dimensions = imageSize(buffer)
          //console.log(dimensions.width, dimensions.height)

          contoller.setDimensions(preview, dimensions.width, dimensions.height)
          resolve()
        }

        reader.onerror = function() {
          console.log(reader.error);
        }
      } else if (file.type.startsWith("image")) {
        const img = new Image()
        img.onload = function () {
          contoller.setUrl(preview, objectUrl)
          contoller.setDimensions(preview, this.width, this.height)
          resolve()
        }
        img.src = objectUrl
      } else {
        console.log(file)
      }
    })
  }

  setUrl(preview, objectUrl) {
    preview.querySelector('.image').href = objectUrl
    preview.querySelector('img').src = objectUrl
  }

  setDimensions(preview, width, height) {
    preview.querySelector('span.width').textContent = width
    preview.querySelector('span.height').textContent = height
    preview.querySelector('input[type="hidden"].width').value = width
    preview.querySelector('input[type="hidden"].height').value = height
  }

  updateIndex(element, index) {
    const inputs = element.querySelectorAll('input[type="hidden"]')
    inputs.forEach(input => {
      input.name = input.name.replace(/\[0\]/, `[${index}]`)
      input.id = input.id.replace(/_0_/, `_${index}_`)
    })
  }

  dropzoneTargetConnected(dropzone) {
    dropzone.onclick = () => {
      this.fileInputTarget.click()
    } 

    let counter = 0;

    ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(eventName => {
      dropzone.addEventListener(eventName, function(e) {
        e.preventDefault()
        e.stopPropagation()
      })
    })

    dropzone.addEventListener('dragenter', function() {
      counter++;
      dropzone.classList.add('active');
    })

    dropzone.addEventListener('dragleave', function() {
      counter--;
      if (counter === 0) {
        dropzone.classList.remove('active');
      }
    })

    dropzone.addEventListener('drop', (event) => {
      const accept = this.fileInputTarget.getAttribute('accept').split(',')
      const files = [...event.dataTransfer.files]
      const acceptedFiles = files.filter(file => accept.some(type => file.type.match(type.trim()) || file.name.match(type.trim()) ))

      if (acceptedFiles.length > 0) {
        const dataTransfer = new DataTransfer()
        acceptedFiles.forEach(file => dataTransfer.items.add(file))
        this.fileInputTarget.files = dataTransfer.files
        this.fileInputTarget.dispatchEvent(new Event('change'))
      }
    })
  }

}
