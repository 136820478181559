import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "fullNameInput", "emailInput"]

  connect() {
    if (document.querySelector('#user_profile_webs.edit')) {
      user_profile_webs.edit()
    }
  }

  checkChangePasswordInput() {
    if (this.inputTargets.every((t) => t.value.length > 0)) {
      $('.modal .approve.button').removeClass('disabled')
    } else {
      $('.modal .approve.button').addClass('disabled')
    }
  }

  showChangePasswordModal() {
    $('.change_password.modal').modal({
      onApprove() {
        $('#change_password_form').trigger('submit')
      }
    }).modal('show')
  }

  showCreateAccountModal() {
    //console.log("showCreateAccountdModal")
    let modal = $('.create_account.modal')
    modal.modal({
      onHidden() {
        $('.dimmer .create_account.modal').remove()
        document.getElementById('create_account_modal').reload()
      },
      onApprove() {
        $('#change_password_form').trigger('submit')
      }
    }).modal('show')
  }

 async checkForExistingUser() {
    if (this.fullNameInputTarget.value.length > 0) {
      try {
        const response = await get(`/user_profile_webs.json?search_exact_fullname=${this.fullNameInputTarget.value}`,{ responseKind: 'json' })
        if (response.ok) {
          let response_data = await response.json
          let label = $(this.fullNameInputTarget).closest(".field").find("label")
          let duplicate_label = label.find(".duplicate")
          if (response_data.length == 1) {
            if (label.find(".duplicate").length == 0) {
              let label_text = label.html() + `<a class="ui mini left pointing orange basic label duplicate" style="position: relative; top: -1px; margin-right: 4px; margin-left: 10px;" href="/user_profile_webs/${response_data[0].id}/edit" target="_blank">Duplicate Found</a>`
              label.html(label_text)
            }
          } else {
            if (label.find(".duplicate").length > 0) {
              duplicate_label.remove()
            }
          }
        } else {
          throw "Response error"
        }
      } catch(error) {
        console.error('Unable to get index: ', error)
      }

    }
  }
}
